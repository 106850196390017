import { Component, OnInit, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthenticationService, LoginResult } from '../../services/authentication.service';
import { Title } from '@angular/platform-browser';
import {CaricomCountries} from '../../../common/model/lookups.model';

@Component({
  selector: 'awwAuthLogin',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  constructor(private fb: FormBuilder, private router: Router, private auth: AuthenticationService, private title: Title) { }

  applicationName: string = environment.applicationName;

  caricomCountries: any[] = [];

  isProcessing = false;

  private processing: EventEmitter<boolean> = new EventEmitter();

  // Password hide/show
  hide = true;

  error: string = null;

  // Form
  loginForm: FormGroup = this.fb.group({
    domain: ['', Validators.required],
    username: ['', Validators.required],
    password: ['', Validators.required]
  });

  ngOnInit() {
    this.auth.logInEvent.subscribe((result: LoginResult) => {
      if (result.success === true) {
        this.error = null;

        this.title.setTitle(this.auth.getFullName() + ' (' + this.auth.getUsername() + ') - ' + this.title.getTitle());

        this.router.navigate(['home']);
      } else {
        this.error = result.error;
      }
    });

    this.processing.subscribe((result: boolean) => {
      this.isProcessing = result;
    });

    this.caricomCountries = CaricomCountries;

    this.loginForm.setValue({
      domain : 'EMMA',
      username : '',
      password: ''
    });
  }

  login() {
    this.auth.authenticate(this.loginForm.value.domain, this.loginForm.value.username, this.loginForm.value.password, this.processing);
  }
  // == Form
}
