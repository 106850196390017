import { NgModule } from '@angular/core';
import { CoreCommonModule } from 'src/app/core/common/core-common.module';
import { MyProfileComponent } from './my-profile/my-profile.component';
import { ChangePasswordComponent } from './change-password/change-password.component';

@NgModule({
  imports: [
    CoreCommonModule
  ],
  exports: [

  ],
  declarations: [
    MyProfileComponent,
    ChangePasswordComponent
  ],
  entryComponents: [
    MyProfileComponent,
    ChangePasswordComponent
  ]
})
export class ProfileModule { }
