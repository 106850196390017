import {NgModule} from '@angular/core';
import {CoreCommonModule} from 'src/app/core/common/core-common.module';
import {AuthenticationModule} from 'src/app/core/authentication/authentication.module';
import {ViewRiskReportComponent} from './view-riskReport/view-riskReport.component';
import {FindRiskReportComponent} from './find-riskReport/find-riskReport.component';
import {ManifestTreeComponent} from './view-riskReport/manifesTree/manifest-tree.component';
import {WaybillTreeComponent} from './view-riskReport/waybillTree/waybill-tree.component';

@NgModule({
  declarations: [
    ViewRiskReportComponent,
    FindRiskReportComponent,
    ManifestTreeComponent,
    WaybillTreeComponent
  ],
  imports: [
    CoreCommonModule,
    AuthenticationModule
  ],
  exports: [],
  entryComponents: [
    ViewRiskReportComponent,
    FindRiskReportComponent
  ]
})
export class RiskReportModule {
}
