export class DataRequest {
    header: {
        id: string,
        date?: Date
    };
    data: any;
}

export interface DataServiceError {
    message: string;
    path: string;
    value: string;
}
