import { Component, OnInit, EventEmitter, OnDestroy } from '@angular/core';
import { NavigationComponentType } from 'src/app/tree.service';
import { AuthenticationService } from 'src/app/core/authentication/services/authentication.service';
import { NavigationService } from 'src/app/core/navigation/services/navigation.service';
import { NavigationParams } from 'src/app/core/common/types/NavigationParams.type';
import { FormGroup, Validators, FormBuilder, AbstractControl, FormArray } from '@angular/forms';
import { DialogService } from 'src/app/core/common/services/dialog.service';
import { ReferenceService } from '../../../reference.service';
import { ICountry } from 'src/app/modules/reference-model';
import { IComboUserGroup, IComboSystemRole, IUserGroup } from '../../admin-model';
import { AdminGroupsService } from '../groups.service';
import { DataServiceError } from 'src/app/core/common/http/HttpModel';

@Component({
  selector: 'awwAdminAddGroup',
  templateUrl: './add-group.component.html',
  styleUrls: ['./add-group.component.css']
})
export class AddGroupComponent implements OnInit, OnDestroy {

  constructor(private auth: AuthenticationService,
    private nav: NavigationService,
    private params: NavigationParams,
    private groupserv: AdminGroupsService,
    private table: ReferenceService,
    private dialog: DialogService,
    private fb: FormBuilder) {

    if (false === auth.isUserInRole(this.type.accesses)) {
      throw new Error('Unauthorized navigation request.');
    }
  }

  private type: NavigationComponentType = AddGroupComponent.defineNavigation();

  processingEvent: EventEmitter<boolean> = new EventEmitter();

  errorsEvent: EventEmitter<DataServiceError[]> = new EventEmitter();

  resultEvent: EventEmitter<any> = new EventEmitter();

  isProcessed = false;

  isProcessing = false;

  groups: IComboUserGroup[] = [];

  countries: ICountry[] = [];

  roles: IComboSystemRole[] = [];

  rolesGranted: string[] = [];

  errors: string[] = [];

  private isDestroyed = false;

  private defaultFormValue: IUserGroup;

  userGroupForm: FormGroup = this.fb.group({
    groupCode: ['', Validators.required],
    groupName: ['', Validators.required],
    parentGroupCode: [''],
    address: this.fb.group({
      addressLine1: [''],
      addressLine2: [''],
      addressLine3: [''],
      building: [''],
      city: ['', Validators.required],
      countryCode: ['', Validators.required],
      region: [''],
      street: [''],
      unit: ['']
    }),
    contactPerson: this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      functionalTitle: ['', Validators.required],
      mobile: [''],
      telephone: [''],
      telephoneExtension: [''],
      emailAddress: ['', Validators.email]
    }),
    passwordPolicy: this.fb.group({
      minLength: [6, Validators.required],
      maxLength: [6, Validators.required],
      minUpperCase: [0, Validators.required],
      minLowerCase: [0, Validators.required],
      minDigits: [0, Validators.required],
      minSpecialChars: [0, Validators.required],
      warningChangeDays: [180, Validators.required],
      forceChangeDays: [180, Validators.required],
      failedAttempts: [99, Validators.required],
      lockoutDuration: [5, Validators.required]
    })
  });

  public static defineNavigation(): NavigationComponentType {
    return {
      component: AddGroupComponent,
      linkName: 'New',
      tabTitle: 'New User Group',
      windowTitle: 'User Groups > New User Group',
      windowClose: true,
      singleton: false,
      icon: 'group_add',
      accesses: ['admin.group.create']
    };
  }

  ngOnInit() {
    this.processingEvent.subscribe(isProcessing => this.isProcessing = isProcessing);

    this.errorsEvent.subscribe(errors => {
      this.errors = [];

      errors.forEach(error => {
        const fcontrol: AbstractControl = this.userGroupForm.get(error.path);

        if (fcontrol) {
          fcontrol.setErrors({ serverError: error.message });
        } else {
          this.errors.push(error.message);
        }

        if (this.errors.length === 0) {
          this.errors.push('The document has errors, please check the highlighted document fields.');
        }
      });
    });

    this.resultEvent.subscribe(result => {
      this.onSubmitResult(result);

      this.errors = [];
    });

    this.defaultFormValue = this.getDefaultFormValue();

    this.resetForm(false);

    this.refreshGroups();

    this.refreshCountries();

    this.refreshRoles();
  }

  ngOnDestroy() {
    this.isDestroyed = true;
  }

  private getDefaultFormValue(): IUserGroup {
    const value: IUserGroup = {
      groupCode: '',
      groupName: '',
      parentGroupCode: '',
      address: {
        addressLine1: '',
        addressLine2: '',
        addressLine3: '',
        building: '',
        city: '',
        countryCode: '',
        region: '',
        street: '',
        unit: ''
      },
      contactPerson: {
        emailAddress: '',
        firstName: '',
        functionalTitle: '',
        lastName: '',
        mobile: '',
        telephone: '',
        telephoneExtension: ''
      },
      passwordPolicy: {
        minLength: 6,
        maxLength: 6,
        minUpperCase: 0,
        minLowerCase: 0,
        minDigits: 0,
        minSpecialChars: 0,
        warningChangeDays: 180,
        forceChangeDays: 180,
        failedAttempts: 99,
        lockoutDuration: 5
      },
      roles: []
    };

    return value;
  }

  ctl(path: string) { return this.userGroupForm.get(path); }

  err(path: string) {
    try {
      const errors: any = this.ctl(path).errors;

      if (errors) {
        if (errors.serverError) {
          return errors.serverError;
        }

        if (errors.required === true) {
          return 'Required';
        }

        if (errors.email) {
          return 'Not a valid e-mail address';
        }
      }
    } catch (e) {
    }

    return '';
  }

  iserr(path: string) {
    return this.ctl(path).invalid && (this.ctl(path).touched || this.ctl(path).dirty || this.ctl(path).errors.serverError);
  }

  private onSubmitResult(result: any): void {
    if (result.data.groupCode.length > 0) {
      this.isProcessed = true;
    } else {
      this.isProcessed = false;
    }
  }

  submitForm(): void {
    this.markFormGroupTouched(this.userGroupForm);

    this.syncRoles();

    if (!this.userGroupForm.valid) {
      if (this.errors.length === 0) {
        this.errors.push('The document has errors, please check the highlighted document fields.');
      }

      return;
    }

    this.dialog.ask(
      'Do you want to verify and validate this form?',
      'Confirmation')
      .subscribe((decision: boolean) => {
        if (decision) {
          const userGroup: IUserGroup = this.prepareData(this.userGroupForm.value);

          userGroup.address = this.prepareData(userGroup.address);

          userGroup.contactPerson = this.prepareData(userGroup.contactPerson);

          userGroup.passwordPolicy = this.prepareData(userGroup.passwordPolicy);

          userGroup.roles = this.rolesGranted;

          this.groupserv.createUserGroup(userGroup, this.processingEvent, this.resultEvent, this.errorsEvent);
        }
      });
  }

  resetForm(isShowDialog: boolean = true): void {
    if (isShowDialog) {
      this.dialog.ask(
        'Do you want to reset this form?',
        'Confirmation')
        .subscribe((decision: boolean) => {
          if (decision) {
            if (this.defaultFormValue) {
              this.userGroupForm.reset(this.defaultFormValue);
            } else {
              this.userGroupForm.reset();
            }

            this.resetFormStatus();
          }
        });
    } else {
      if (this.defaultFormValue) {
        this.userGroupForm.reset(this.defaultFormValue);
      } else {
        this.userGroupForm.reset();
      }

      this.resetFormStatus();
    }
  }

  private resetFormStatus(): void {
    this.userGroupForm.markAsUntouched();

    this.userGroupForm.markAsPristine();

    this.errors = [];

    this.roles = [];

    this.syncRoles();
  }

  private prepareData(data: any): any {
    const preparedData: any = Object.assign({}, data);

    Object.keys(preparedData).forEach(k => preparedData[k] = preparedData[k] === '' ? null : preparedData[k]);

    return preparedData;
  }

  private markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      if (control.controls) {
        this.markFormGroupTouched(control);
      } else {
        control.markAsTouched();
      }
    });
  }

  refreshGroups(): void {
    if (this.isDestroyed) { return; }

    this.groups = [];

    this.groupserv.getGroups().subscribe((result: IComboUserGroup[]) => {
      if (result) {
        this.groups = result;
      }
    }, () => setTimeout(() => this.refreshGroups(), 5000));
  }

  refreshCountries(): void {
    if (this.isDestroyed) { return; }

    this.countries = [];

    this.table.getCountries().subscribe((result: ICountry[]) => {
      if (result) {
        this.countries = result;
      }
    }, () => setTimeout(() => this.refreshCountries(), 5000));
  }

  refreshRoles(): void {
    if (this.isDestroyed) { return; }

    this.roles = [];

    const code = this.ctl('parentGroupCode').value;

    if (!code || code.length === 0) {
      return;
    }

    this.groupserv.getRoles(code).subscribe((result: IComboSystemRole[]) => {
      if (result) {
        this.roles = result;
      }

      this.syncRoles();
    }, (errors: DataServiceError[]) => {
      this.syncRoles();

      setTimeout(() => this.refreshRoles(), 5000);
    });
  }

  toggleRole(role: IComboSystemRole, remove: boolean): void {
    if (!remove) {
      this.rolesGranted.push(role.roleCode);
    } else {
      this.rolesGranted = this.rolesGranted.filter((value) => {
        return value !== role.roleCode;
      });
    }
  }

  isGranted(role: IComboSystemRole): boolean {
    return -1 !== this.rolesGranted.indexOf(role.roleCode, 0);
  }

  private syncRoles(): void {
    this.rolesGranted = this.rolesGranted.filter((value: string) => {
      return this.roles.filter(role => {
        return role.roleCode === value;
      }).length > 0;
    });
  }
}
