import { Component, OnInit } from '@angular/core';
import { NavigationService } from 'src/app/core/navigation/services/navigation.service';
import { DialogService } from 'src/app/core/common/services/dialog.service'
import { environment } from 'src/environments/environment';

@Component({
  selector: 'awwDesktopToolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.css']
})
export class ToolbarComponent implements OnInit {

  applicationName: string = environment.applicationName;

  showCloseAll = false;

  constructor(private nav: NavigationService, private dialogs: DialogService) { }

  ngOnInit() {

    this.nav.toggleTabScreenEvent.subscribe((toggle) => this.showCloseAll = toggle);
  }

  toggle() {
    this.nav.toggle();
  }

}
