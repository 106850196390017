import { Component, OnInit, EventEmitter, OnDestroy } from '@angular/core';
import { AuthenticationService } from 'src/app/core/authentication/services/authentication.service';
import { NavigationParams } from 'src/app/core/common/types/NavigationParams.type';
import { NavigationComponentType } from 'src/app/tree.service';
import { FormBuilder, Validators, FormGroup, AbstractControl, FormArray } from '@angular/forms';
import { CargoService } from '../../cargo.service';
import { IManifest, IWayBillInManifest, IWayBillVehicle, IWayBillFreight, IWaybillContainer, IWayBillPackageList } from '../../cargo-model';
import { NavigationService } from 'src/app/core/navigation/services/navigation.service';
import { ICountry, ICustomsOffice } from 'src/app/modules/reference-model';
import { ReferenceService } from 'src/app/modules/reference.service';
import { formatDate} from '@angular/common';

@Component({
  selector: 'awwCargoViewWaybill',
  templateUrl: './view-waybill.component.html',
  styleUrls: ['./view-waybill.component.css']
})
export class ViewWaybillComponent implements OnInit, OnDestroy {

  constructor(
    private auth: AuthenticationService,
    private nav: NavigationService,
    private params: NavigationParams,
    private table: ReferenceService,
    private cargo: CargoService,
    private fb: FormBuilder) {

    if (false === this.auth.isUserInRole(this.type.accesses)) {
      throw new Error('Unauthorized navigation request.');
    }
  }

  private type: NavigationComponentType = ViewWaybillComponent.defineNavigation();

  // Tabs
  tab = 1;

  tabs: string[] = ['General', 'Containers', 'Vehicles'];
  // ==Tabs

  customsOffices: ICustomsOffice[] = [];

  countries: ICountry[] = [];

  processingEvent: EventEmitter<boolean> = new EventEmitter();

  errorsEvent: EventEmitter<any[]> = new EventEmitter();

  resultEvent: EventEmitter<IManifest> = new EventEmitter();

  isProcessed = false;

  isProcessing = false;

  errors: string[] = [];

  waybillId: string;

  containers: IWaybillContainer[] = [];

  vehicles: IWayBillVehicle[] = [];

  freights: IWayBillFreight[] = [];

  packageList: IWayBillPackageList[] = [];

  containersTableColumns: string[] = [
    'containerReference',
    'goodsWeight',
    'packages',
    'waybillCtnGoodsDesc',
    'dangerousGoods'
  ];

  harmonizeGoodsTableColumns: string[] = [
    'hs6Code',
    'hs6Description',
    'packages',
    'containerReference'
  ];

  vehiclesTableColumns: string[] = [
    'chassisNumber',
    'engineNumber',
    'displacement',
    'odometer',
    'make',
    'brand',
    'manufacturedYear',
    'color',
    'condition'
  ];

  freightsTableColumns: string[] = [
    'freightCode',
    'freightMode',
    'freightDescription',
    'paymentMethod',
    'currency',
    'amount'
  ];

  packageListTableColumns: string[] = [
    'packageType',
    'manifestedWeight',
    'manifestedPackages',
    'shippingMarks',
    'waybillPkgLineGoodsDesc',
    'goodsUnloaded',
    'containerReference'
  ];

  private isDestroyed = false;

  waybillForm: FormGroup = this.fb.group({
    id: ['', Validators.required],
    voyageId: this.fb.group({
      countryCode: ['', Validators.required],
      officeCode: ['', Validators.required],
      voyageNumber: ['', Validators.required],
      departureDate: ['', Validators.required],
    }),
    reference: ['', Validators.required],
    parentReference: ['', Validators.required],
    carrier: this.fb.group({
      carrierCode: ['', Validators.required],
      carrierName: ['', Validators.required],
      carrierAddress: ['', Validators.required],
    }),
    shippingAgent: this.fb.group({
      shippingAgentCode: ['', Validators.required],
      shippingAgentName: ['', Validators.required],
    }),
    lastDischage: ['', Validators.required],
    lineNumber: ['', Validators.required],
    indicator: ['', Validators.required],
    waybillNature: ['', Validators.required],
    uniqueConsignmentReference: ['', Validators.required],
    manifestedWeight: ['', Validators.required],
    manifestedPackages: ['', Validators.required],
    packageType: ['', Validators.required],
    volume: ['', Validators.required],
    waybillType: ['', Validators.required],
    exporterCode: ['', Validators.required],
    exporterName: ['', Validators.required],
    exporterAddress: ['', Validators.required],
    consigneeCode: ['', Validators.required],
    consigneeName: ['', Validators.required],
    consigneeAddress: ['', Validators.required],
    notifyCode: ['', Validators.required],
    notifyName: ['', Validators.required],
    notifyAddress: ['', Validators.required],
    loadingPort: ['', Validators.required],
    unloadingPort: ['', Validators.required],
    shippingMarks: ['', Validators.required],
    goodsDescription: ['', Validators.required],
    vesselLoadingPort: ['', Validators.required],
    vesselUnloadingPort: ['', Validators.required],
    sealNumber: ['', Validators.required],
    sealMark: ['', Validators.required],
    sealOperator: ['', Validators.required],
    information: ['', Validators.required],
    originPlace: ['', Validators.required],
    destinationPlace: ['', Validators.required],
    locationInformation: ['', Validators.required],
    totalContainers: ['', Validators.required],
    totalVehicles: ['', Validators.required],
    authorizedDeclarant: ['', Validators.required],
    authorizedCompany: ['', Validators.required],
    hs6Code: ['', Validators.required],
    fastLiner: ['', Validators.required],
    location: ['', Validators.required]
  });

  public static defineNavigation(): NavigationComponentType {
    return {
      component: ViewWaybillComponent,
      linkName: 'View',
      tabTitle: 'View Waybill',
      windowTitle: 'Cargo Waybill > View',
      windowClose: true,
      singleton: false,
      icon: '',
      accesses: ['cargo.waybill.get']
    };
  }

  clickTab(tabRank: number) {
    this.tab = tabRank;
  }

  isActiveTab(tabRank: number): boolean {
    return this.tab === tabRank;
  }

  ngOnInit() {
    this.processingEvent.subscribe(isProcessing => this.isProcessing = isProcessing);

    this.errorsEvent.subscribe(errors => {
      this.errors = [];

      errors.forEach(error => {
        let fcontrol: AbstractControl = this.waybillForm.get(error.path);

        if (fcontrol) {
          fcontrol.setErrors({ serverError: error.message });
        } else {
          this.errors.push(error.message);
        }

        if (this.errors.length === 0) {
          this.errors.push('Unable to view manifest data, please try again.');
        }
      });
    });

    this.resultEvent.subscribe(result => {
      if (result) {
        this.isProcessed = true;

        this.waybillForm.disable();

        this.nav.changeTitle(`View Waybill [${result.reference}]`);

        this.waybillForm.patchValue(result);

        (this.waybillForm.controls.voyageId as any).controls.departureDate.setValue(formatDate(result.voyageId.departureDate, 'd/M/yyyy', 'en_BB'));

        let tmpVehicles: IWayBillVehicle[] = [];

        let tmpFreights: IWayBillFreight[] = [];

        let tmpContainers: IWaybillContainer[] = [];

        let tmpPackageList: IWayBillPackageList[] = [];

        result.vehicles.forEach(v => {
          tmpVehicles.push(v);
        });

        result.freights.forEach(f => {
          tmpFreights.push(f);
        });

        result.containers.forEach(c => {
          tmpContainers.push(c);
        });

        result.packageList.forEach(p => {
          tmpPackageList.push(p);
        });

        this.vehicles = tmpVehicles;

        this.freights = tmpFreights;

        this.containers = tmpContainers;

        this.packageList = tmpPackageList;

        this.waybillForm.disable();
      }

      this.errors = [];
    });

    this.waybillId = this.params.params[0];

    this.refreshCustomsOffices();

    this.refreshCountries();

    this.initWaybill();
  }

  ngOnDestroy() {
    this.isDestroyed = true;
  }

  refreshCustomsOffices(): void {
    if (this.isDestroyed) { return; }

    this.countries = [];

    this.table.getCustomsOffices().subscribe((result: ICustomsOffice[]) => {
      if (result) {
        this.customsOffices = result;
      }
    }, () => setTimeout(() => this.refreshCustomsOffices(), 5000));
  }

  refreshCountries(): void {
    if (this.isDestroyed) { return; }

    this.countries = [];

    this.table.getCountries().subscribe((result: ICountry[]) => {
      if (result) {
        this.countries = result;
      }
    }, () => setTimeout(() => this.refreshCountries(), 5000));
  }

  private initWaybill() {
    this.cargo.getWaybillById(this.waybillId, this.processingEvent, this.resultEvent, this.errorsEvent);
  }

  ctl(path: string) { return this.waybillForm.get(path); }
}
