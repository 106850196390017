import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy} from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthRouteReuseStrategy implements RouteReuseStrategy {

  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    return undefined; // Will be used only if shouldAttach return TRUE, in our case, it is FALSE
  }

  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    return false;
  }

  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return false;
  }

  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    return false;
  }

  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle | null): void {
    // Will be used only if shouldDetach return TRUE, in our case, it is FALSE
  }
}
