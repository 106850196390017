import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanDeactivate, Router, RouterStateSnapshot} from '@angular/router';
import {DesktopMainComponent} from '../desktop-client/components/desktop-main/desktop-main.component';
import {AuthenticationService} from '../authentication/services/authentication.service';
import {DialogService} from '../common/services/dialog.service';

@Injectable({
  providedIn: 'root',
})
export class RoutingGuard implements CanActivate, CanDeactivate<DesktopMainComponent> {

  constructor(private router: Router, private auth: AuthenticationService, private dialog: DialogService) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.checkIsAuthenticated();
  }

  canDeactivate(component: DesktopMainComponent, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot): boolean {
    /* var accept: boolean = false;

    this.dialog.ask('You will be logged out and any unsaved data will be lost!').subscribe(decision => {
        accept = decision && decision === true;
    });

    return accept; */

    return true;
  }

  private checkIsAuthenticated(): boolean {
    if (this.auth.isAuthenticated()) {
      return true;
    }

    this.router.navigate(['logout']);

    return false;
  }
}
