import { Component, OnInit } from '@angular/core';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { of as observableOf } from 'rxjs';
import { FlatTreeControl } from '@angular/cdk/tree';
import { NavigationService } from 'src/app/core/navigation/services/navigation.service';
import { TreeService, TreeNode } from 'src/app/tree.service';
import { AuthenticationService } from 'src/app/core/authentication/services/authentication.service';

/**
 * Flattened tree node that has been created from a FileNode through the flattener. Flattened
 * nodes include level index and whether they can be expanded or not.
 */
export interface FlatTreeNode {
  navigation: string;
  level: number;
  expandable: boolean;
}

@Component({
  selector: 'awwDesktopNavigationTree',
  templateUrl: './navtree.component.html',
  styleUrls: ['./navtree.component.css'],
})
export class NavtreeComponent implements OnInit {

  constructor(private auth: AuthenticationService, private nav: NavigationService, private tree: TreeService) {
    this.treeFlattener = new MatTreeFlattener(
      this.transformer,
      this.getLevel,
      this.isExpandable,
      this.getChildren);

    this.treeControl = new FlatTreeControl(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    let treeNodes: TreeNode[] = tree.getTreeNodes(auth.getUserAccesses());

    this.treeNodesAsJson = JSON.stringify(treeNodes);

    this.dataSource.data = JSON.parse(this.treeNodesAsJson);
  }

  /** The TreeControl controls the expand/collapse state of tree nodes.  */
  treeControl: FlatTreeControl<FlatTreeNode>;

  /** The TreeFlattener is used to generate the flat list of items from hierarchical data. */
  private treeFlattener: MatTreeFlattener<TreeNode, FlatTreeNode>;

  /** The MatTreeFlatDataSource connects the control and flattener to provide data. */
  dataSource: MatTreeFlatDataSource<TreeNode, FlatTreeNode>;

  /** Tree nodes */
  private treeNodesAsJson: string;
  // == Expand+Collapse tree

  // Filtering
  filter = '';

  ngOnInit() {

  }

  /** Transform the data to something the tree can read. */
  transformer(node: TreeNode, level: number) {
    return {
      name: this.getLinkName,
      navigation: node.navigation,
      level: level,
      expandable: !!node.children,
      component: this.getComponent,
      icon: this.getIcon,
      params: node.params
    };
  }

  /** Get the name of the node */
  getLinkName(node: TreeNode) {
    return this.tree.getLinkName(node.navigation);
  }

  /** Get the level of the node */
  getLevel(node: FlatTreeNode) {
    return node.level;
  }

  /** Get whether the node is expanded or not. */
  isExpandable(node: FlatTreeNode) {
    return node.expandable;
  }

  /** Get whether the node has children or not. */
  hasChild(index: number, node: FlatTreeNode) {
    return node.expandable;
  }

  /** Get the children for the node. */
  getChildren(node: TreeNode) {
    return observableOf(node.children);
  }

  /** Get the navigation for the node. */
  getNavigation(node: TreeNode) {
    return node.navigation;
  }

  /** Get the component for the node. */
  getComponent(node: TreeNode) {
    return this.tree.getComponent(node.navigation);
  }

  /** Get the icon of the node */
  getIcon(node: TreeNode) {
    return this.tree.getIcon(node.navigation);
  }

  // Expand+Collapse tree
  expandTree() {
    this.treeControl.expandAll();
  }

  collapseTree() {
    this.treeControl.collapseAll();
  }

  private doFilterTreeNode(node: TreeNode): boolean {
    if (this.getLinkName(node).toUpperCase().match(this.filter.toUpperCase())) {
      return true;
    } else {
      if (node.children && node.children.length > 0) {
        node.children = node.children.filter((child: TreeNode) => {
          return this.doFilterTreeNode(child);
        });

        return node.children.length > 0;
      }
    }

    return false;
  }

  public doFilter(): void {
    let treeNodes: TreeNode[] = JSON.parse(this.treeNodesAsJson);

    this.dataSource.data = treeNodes.filter((node: TreeNode) => {
      return this.doFilterTreeNode(node);
    });

    if (this.filter.length > 0) {
      this.expandTree();
    } else {
      this.collapseTree();
    }
  }
  // == Filtering

  toggle() {
    this.nav.toggle();
  }
}
