import { Component, OnInit, ViewChild, EventEmitter } from '@angular/core';
import { NavigationComponentType } from 'src/app/tree.service';
import { AuthenticationService } from 'src/app/core/authentication/services/authentication.service';
import { MatSort } from '@angular/material';
import { FindDataRequest, FindResult } from 'src/app/core/common/model/FinderModel';
import { RiskService } from '../../risk.service';
import { StringFinderOptions, GeneralFinderOptions } from 'src/app/core/common/model/FinderModel';
import { DialogService } from 'src/app/core/common/services/dialog.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'awwFindRiskList',
  templateUrl: './find-riskList.component.html',
  styleUrls: ['./find-riskList.component.css']
})
export class FindRiskListComponent implements OnInit {

  // component references
  @ViewChild('formOverlay') overlay:any;
  @ViewChild(MatSort) sort: MatSort;

  // sizes of pages for pagination
  pageSizes:number[] = [20, 50, 75];

  constructor(
    private auth: AuthenticationService,
    private risk: RiskService, private dialog: DialogService) {
    if (false === auth.isUserInRole(this.type.accesses)) {
      throw new Error('Unauthorized navigation request.');
    }
  }

  private type: NavigationComponentType = FindRiskListComponent.defineNavigation();

  // api request event.
  $processingEvent: EventEmitter<boolean> = new EventEmitter();
  $errorsEvent: EventEmitter<any> = new EventEmitter();
  $resultEvent: EventEmitter<FindResult> = new EventEmitter();
  $deleteResultEvent: EventEmitter<any> = new EventEmitter();

  // subscriptions
  private subscriptions: Subscription[] = [];

  // table results fields
  resultColumns: string[] = ['Id', 'listCode', 'listName', 'beginDate', 'endDate'];
  displayedColumns: string[] = [ 'listCode', 'listName',  'beginDate', 'endDate', 'actions*'];

  // find request.
  findDataRequest: FindDataRequest = {
    isDefault: true,
    page: 1,
    pageSize: 100,
    sortColumn: this.displayedColumns[1],
    ascendingOrder: true,
    viewColumn: this.resultColumns
  };

  tableDataSource: {}[] = [];


  isShowLoadMore = false;

  // navigation definition
  public static defineNavigation(): NavigationComponentType {
    return {
      component: FindRiskListComponent,
      linkName: 'Find',
      tabTitle: 'Watch Lists',
      windowTitle: 'Watch List > Find',
      windowClose: true,
      singleton: true,
      icon: 'search',
      accesses: ['risk.list.find']
    };
  }

  // open finder window
  openFinder(): void {

    // fields to build search form.
    const searchFields: any[] = [
      {title: 'LIST CODE', field: 'listCode', options: StringFinderOptions, type:'string'},
      {title: 'LIST NAME', field: 'listName', options: StringFinderOptions, type:'string'},
      {title: 'BEGIN DATE', field: 'beginDate', options: GeneralFinderOptions, type:'date'},
      {title: 'END DATE', field: 'endDate', options: GeneralFinderOptions, type:'date'}
    ];

    // delay the display of search dialog.
    // prevents issue where error is thrown because component is not created yet.
    // https://github.com/angular/material2/issues/10705
    setTimeout(() => {

      this.dialog.finder('Find Watch Lists', searchFields).subscribe((criteria) => {

        // if the criteria is passed we assume the user chose to submit the search
        if (criteria) {
           this.findDataRequest.criteria = criteria;
           this.risk.findRiskLists(this.findDataRequest, this.$processingEvent, this.$resultEvent, this.$errorsEvent);
        }

      });
    });

  }

  ngOnInit() {

    this.subscriptions['$processingEvent'] = this.$processingEvent.subscribe(isProcessing => this.overlay.isProcessing = isProcessing);

    this.subscriptions['$errorsEvent'] = this.$errorsEvent.subscribe(errors => {
      this.overlay.isError = true;
      this.overlay.errors = errors;
    });

    this.subscriptions['$resultEvent'] = this.$resultEvent.subscribe(result => {
      this.overlay.isError = false;
      if (result) {
        let findResult: FindResult = result;
        if (findResult) {
          this.findDataRequest = {
            isDefault: false,
            page: findResult.page,
            pageSize: findResult.pageSize,
            sortColumn: findResult.sortColumn,
            ascendingOrder: findResult.ascendingOrder,
            criteria: this.findDataRequest.criteria
          };
          try {
            this.isShowLoadMore = findResult.resultItems && findResult.resultItems.length === this.findDataRequest.pageSize;
          } catch (e) {
          }

          // clear out previous list
          this.tableDataSource = [];

          if (findResult.resultItems && findResult.resultItems.length > 0) {

            const tmpResult: {}[] = [];

            this.tableDataSource.forEach(row => {
              tmpResult.push(row);
            });

            findResult.resultItems.forEach(row => {

              tmpResult.push(row);
            });

            this.tableDataSource = tmpResult;
          }
        }
      } else {
        this.isShowLoadMore = false;
      }
    });

    this.sort.sortChange.subscribe(() => {
      if (!this.sort.active) {
        this.sort.active = this.findDataRequest.sortColumn;
      }

      this.findDataRequest.sortColumn = this.sort.active;

      if (!this.sort.direction) {
        this.sort.direction = 'asc';
      }

      if (this.sort.direction === 'asc') {
        this.findDataRequest.ascendingOrder = true;
      } else {
        this.findDataRequest.ascendingOrder = false;
      }

      this.refreshTableData(this.findDataRequest, true);
    });

    // handle delete result event.
    this.subscriptions['$deleteResultEvent'] = this.$deleteResultEvent.subscribe((result) => {
      this.dialog.alert("Selected Risk List Deleted.", "Result");
      this.refreshTableData(this.findDataRequest, true);
    });

    // this.refreshTableData(this.findDataRequest, true);
    this.openFinder();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((handle) => handle.unsubscribe());
    this.subscriptions = null;
  }

  refreshTableData(findDataRequest: FindDataRequest, reset: boolean) {
    if (reset === true) {
      this.tableDataSource = [];
    }

    this.risk.getSearchResultsForRiskList(findDataRequest, this.$processingEvent, this.$resultEvent, this.$errorsEvent);
  }

  loadMoreData() {
    const findDataRequest: FindDataRequest = {
      isDefault: false,
      page: this.findDataRequest.page, // + 1,
      pageSize: this.findDataRequest.pageSize,
      sortColumn: this.findDataRequest.sortColumn,
      ascendingOrder: this.findDataRequest.ascendingOrder
    };

    this.refreshTableData(findDataRequest, false);
  }

  /**
   * Remove a Risk List from the system
   * @param listId - persistent id of the risk list to remove
   */
  delete(listId: string): void {
    this.dialog.ask(
      'Delete Selected Risk List?',
      'Confirmation')
      .subscribe((decision: boolean) => {
        if (decision) {

          this.risk.deleteRiskList(listId, this.$processingEvent, this.$deleteResultEvent, this.$errorsEvent);
        }
    });
  }
}
