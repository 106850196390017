import {LOCALE_ID, Component, OnInit, EventEmitter, OnDestroy, ElementRef, ViewChild} from '@angular/core';
import {AuthenticationService} from 'src/app/core/authentication/services/authentication.service';
import {NavigationParams} from 'src/app/core/common/types/NavigationParams.type';
import {NavigationComponentType} from 'src/app/tree.service';
import {FormBuilder, Validators, FormGroup, AbstractControl, FormArray} from '@angular/forms';
import {NavigationService} from 'src/app/core/navigation/services/navigation.service';
import {ReferenceService} from 'src/app/modules/reference.service';
import {RiskService} from '../../risk.service';
import {MatOption, MatSelectChange, MatTableDataSource} from '@angular/material';
import {RiskTypes} from '../../shared/RiskTypes.model';
import {DataServiceError} from 'src/app/core/common/http/HttpModel';
import {Element} from '../../shared/element';
import {RiskList} from '../../shared/riskList.model';
import {formatDate} from '@angular/common';
import {DialogService} from '../../../../core/common/services/dialog.service';
import {FieldNameMapper} from '../../shared/FieldNameMapper';
import { UNBaseFormComponent, FormMode } from '../../../shared/base-form.component';
import { Subscription } from 'rxjs';


@Component({
  selector: 'awwRiskListDetails',
  templateUrl: './riskList-details.component.html',
  styleUrls: ['./riskList-details.component.css']
})
export class RiskListDetailsComponent extends UNBaseFormComponent implements OnInit, OnDestroy {

  // component references
  @ViewChild('formOverlay') overlay:any;
  @ViewChild('enableToggle') enableToggle:any;

  // event handlers
  $processingEvent: EventEmitter<boolean> = new EventEmitter();
  $errorsEvent: EventEmitter<DataServiceError[]> = new EventEmitter();
  $resultEvent: EventEmitter<RiskList> = new EventEmitter();
  $loadRiskListEvent: EventEmitter<RiskList> = new EventEmitter();

  // flags
  isProcessed = false;
  isProcessing = false;
  private isDestroyed = false;
  private enabledFlag:boolean;

  // lists
  errors: string[] = [];
  fields: FieldNameMapper[];
  types: RiskTypes[];
  ELEMENT_DATA: Element[] = [];
  listItems: string[] = [];
  handlers:Subscription[] = [];

  fieldHuman: string;
  risklisttosend: RiskList = null;

  public resultMsg:string;

  // Tabs
  tab = 1;
  tabs: string[] = ['General', 'Items'];

  /* add risk list table */
  value1 = '';
  displayedColumns = ['name', 'actions*'];
  dataSource = new MatTableDataSource(this.ELEMENT_DATA);

  // Form Group
  riskListForm: FormGroup = this.fb.group({
    listCode: ['', Validators.required],
    listName: ['', Validators.required],
    listType: ['', Validators.required],
    listField: ['', Validators.required],
    BeginDate: [new Date(), Validators.required],
    EndDate: [''],
    listFlag: ['']
  });

  // default constructor
  constructor(
    private auth: AuthenticationService,
    private nav: NavigationService,
    private navParams: NavigationParams,
    private table: ReferenceService,
    private risk: RiskService,
    private dialog: DialogService,
    private fb: FormBuilder) {

    // call base constructor.
    super(navParams.params, auth, 
      {new:'risk.list.create', edit:'risk.list.update', view:'risk.list.view'});
  }

  //private type: NavigationComponentType = NewRiskListComponent.defineNavigation();

  public static defineNewNavigation(): NavigationComponentType {
    return {
      component: RiskListDetailsComponent,
      linkName: 'New',
      tabTitle: 'New Watch List',
      windowTitle: 'Watch List > New',
      windowClose: true,
      singleton: false,
      icon: 'add',
      accesses: ['risk.list.create']
    };
  }

  public static defineEditNavigation(): NavigationComponentType {
    return {
      component: RiskListDetailsComponent,
      linkName: 'Edit',
      tabTitle: 'Edit Watch List',
      windowTitle: 'Watch List > Edit',
      windowClose: true,
      singleton: false,
      icon: 'edit',
      accesses: ['risk.list.update']
    };
  }

  public static defineViewNavigation(): NavigationComponentType {
    return {
      component: RiskListDetailsComponent,
      linkName: 'View',
      tabTitle: 'View Risk List',
      windowTitle: 'Risk List > View',
      windowClose: true,
      singleton: false,
      icon: 'view',
      accesses: ['risk.list.view']
    };
  }

  clickTab(tabRank: number) {
    this.tab = tabRank;
  }

  isActiveTab(tabRank: number): boolean {
    return this.tab === tabRank;
  }

  transformDate(date) {
    return formatDate(date, 'yyyy-MM-dd', 'en_BB');
  }

  ngOnInit() {

    this.enabledFlag = true;

    this.handlers['$processingEvent'] =  this.$processingEvent.subscribe(isProcessing => this.overlay.isProcessing = isProcessing);

    this.handlers['$errorsEvent'] = this.$errorsEvent.subscribe(errors => {
      this.overlay.errors = errors;
      this.errors = [];

      errors.forEach(error => {
        const fcontrol: AbstractControl = this.riskListForm.get(error.path);

        if (fcontrol) {
          fcontrol.setErrors({serverError: error.message});
        } else {
          this.errors.push(error.message);
        }

        if (this.errors.length === 0) {
          this.errors.push('The document has errors, please check the highlighted document fields.');
        }
      });
    });

    this.handlers['$resultEvent'] = this.$resultEvent.subscribe(result => {
      this.onSubmitResult(result);

      this.errors = [];
    });

    // handle the load event for RiskList.
    this.handlers['$loadRiskListEvent'] = this.$loadRiskListEvent.subscribe(result => {
      this.prepEdit(result.data);
    });

    /* get risk Types */
    this.refreshRiskTyps();

    if(this.Mode != FormMode.NEW)
      this.risk.getRiskListbyId(this.RecordId, this.$processingEvent, this.$loadRiskListEvent, this.$errorsEvent);

  }


  /**
   * Prepare the form fields with the record data for editing
   * @param record - Risk List Record
   */
  prepEdit(record:any):void {

    this.refreshFieldHumanNames(record.listType, () => {

      // change the nav to the code of the risk list..
      this.nav.changeTitle(`Edit Watch List [ ${record.listCode} ]`);
      
      this.riskListForm.patchValue(record);

      this.riskListForm.controls['BeginDate'].setValue(new Date(record.beginDate+" EDT"));

      if(record.endDate)
        this.riskListForm.controls['EndDate'].setValue(record.endDate);

      this.enableToggle.checked = record.listFlag;

      this.enabledFlag = record.listFlag;

      this.fieldHuman = record.listHumanName;

      // disable fields from being updated.
      this.riskListForm.controls['BeginDate'].disable();
      this.riskListForm.controls['listCode'].disable();

      if(this.Mode == FormMode.VIEW)
        this.riskListForm.disable();
      
    });

    this.dataSource.data = record.fields;

    this.dataSource.data.forEach((item, idx, arr) => {
      this.listItems.push(String(item));
      this.ELEMENT_DATA.push({name: String(item)});
      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
    });

  }

  onListTypeChange() {
    this.refreshFieldHumanNames(this.riskListForm.controls['listType'].value);

  }


  onHumanNameChange(event: MatSelectChange) {
    const selectedData = {
      text: (event.source.selected as MatOption).viewValue,
      value: event.source.value
    };
    this.fieldHuman = selectedData.text;

  }

  refreshRiskTyps(): void {

    this.types = [];

    this.risk.getRiskTypes().subscribe((result: RiskTypes[]) => {

      this.types = result;

    }, () => setTimeout(() => this.refreshRiskTyps(), 5000));
  }


  refreshFieldHumanNames(entity: string, action?:Function): void {
    // if (this.isDestroyed) return;

    this.fields = [];

    this.risk.getFields(entity).subscribe((result: FieldNameMapper[]) => {

      this.fields = result;

      // callback action to notify caller list is loaded.
      if(action)
        action();

    }, () => setTimeout(() => this.refreshFieldHumanNames(entity), 5000));

  }

  ctl(path: string) {
    return this.riskListForm.get(path);
  }

  err(path: string) {
    try {
      const errors: any = this.ctl(path).errors;

      if (errors) {
        if (errors.serverError) {
          return errors.serverError;
        }

        if (errors.required === true) {
          return 'Required';
        }
      }
    } catch (e) {
    }

    return '';
  }

  iserr(path: string) {
    return this.ctl(path).invalid && (this.ctl(path).touched || this.ctl(path).dirty || this.ctl(path).errors.serverError);
  }

  /**
   * Process the form submission result
   * @param result - result return from the submission
   */
  private onSubmitResult(result: any): void {
    if (result.status.code === 200) {
      this.isProcessed = true;
      this.resultMsg = (this.Mode == FormMode.NEW) ? 'Watch List Created Sucessfully.' : 'Watch List Update Sucessfully.'
    } else {
      this.isProcessed = false;
    }
  }

  // Store the list
  submitForm() {
    this.markFormGroupTouched(this.riskListForm);

    if (!this.riskListForm.valid) {
      if (this.errors.length === 0) {
        this.errors.push('The document has errors, please check the highlighted document fields.');
      }

      return;
    }

    this.dialog.ask(
      'Do you want to verify and validate this form?',
      'Confirmation')
      .subscribe((decision: boolean) => {
        if (decision) {
          // this.risklisttosend.tmpBeginDate === '';

          this.risklisttosend = {
            fields: this.listItems,
            flag: true,
            listFlag: this.enabledFlag,
            listCode: this.riskListForm.controls['listCode'].value,
            listName: this.riskListForm.controls['listName'].value,
            listType: this.riskListForm.controls['listType'].value,
            listField: this.riskListForm.controls['listField'].value,
            listHumanName: this.fieldHuman,
            tmpBeginDate: this.transformDate(this.riskListForm.controls['BeginDate'].value)
          };

          if (this.riskListForm.controls['EndDate'].value !== '') {
            this.risklisttosend.tmpEndDate = this.transformDate(this.riskListForm.controls['EndDate'].value);
          }

          if(this.Mode == FormMode.EDIT) {
            (<any>this.risklisttosend).id = this.RecordId;
            this.risk.updateRiskList(this.risklisttosend, this.$processingEvent, this.$resultEvent, this.$errorsEvent);
          }
          else {
            this.risk.saveRiskList(this.risklisttosend, this.$processingEvent, this.$resultEvent, this.$errorsEvent);
          }
        }
      });


    // this.riskListForm.disable();
  }


  ngOnDestroy() {
    this.isDestroyed = true;
  }

  private markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      if (control.controls) {
        this.markFormGroupTouched(control);
      } else {
        control.markAsTouched();
      }
    });
  }


  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  addElement() {

    this.ELEMENT_DATA.push({name: this.value1});
    this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
    this.listItems.push(<string>this.value1);
    this.value1 = '';

  }

  removeAt(index: number) {
    const data = this.dataSource.data;
    data.splice(index, 1);
    for (const en of this.ELEMENT_DATA) {

    }
    this.dataSource.data = data;
    this.listItems.splice(index, 1);

  }

  /**
   * Handle the change event for the criteria enabled toggle component
   * @param - $event toggle event
   */
  public onEnableToggleChanged($event: any): void {
    this.enabledFlag = $event.checked;
  }
}



