import { Component, OnInit, EventEmitter, OnDestroy } from '@angular/core';
import { AuthenticationService } from 'src/app/core/authentication/services/authentication.service';
import { NavigationParams } from 'src/app/core/common/types/NavigationParams.type';
import { NavigationComponentType } from 'src/app/tree.service';
import { FormBuilder, Validators, FormGroup, AbstractControl, FormArray } from '@angular/forms';
import { IManifest, IWayBillInManifest, IColoader } from '../../cargo-model';
import { NavigationService } from 'src/app/core/navigation/services/navigation.service';
import { ICountry, ICustomsOffice, IUNLocode } from 'src/app/modules/reference-model';
import { ReferenceService } from 'src/app/modules/reference.service';
import { CargoService } from '../../cargo.service';
import { formatDate} from '@angular/common';
import { isUndefined, isNullOrUndefined } from 'util';

@Component({
  selector: 'awwCargoViewManifest',
  templateUrl: './view-manifest.component.html',
  styleUrls: ['./view-manifest.component.css']
})
export class ViewManifestComponent implements OnInit, OnDestroy {

  constructor(
    private auth: AuthenticationService,
    private nav: NavigationService,
    private params: NavigationParams,
    private table: ReferenceService,
    private cargo: CargoService,
    private fb: FormBuilder) {

    if (false === this.auth.isUserInRole(this.type.accesses)) {
      throw new Error('Unauthorized navigation request.');
    }
  }

  get containers() {
    return this.manifestForm.get('containers') as FormArray;
  }

  get containersc() {
    return this.containers.controls as FormGroup[];
  }

  private type: NavigationComponentType = ViewManifestComponent.defineNavigation();

  // Tabs
  tab = 1;

  tabs: string[] = ['General', 'Bills', 'Containers', 'Coloaders'];
  // ==Tabs

  customsOffices: ICustomsOffice[] = [];

  countries: ICountry[] = [];

  unlocodes: IUNLocode[] = [];

  processingEvent: EventEmitter<boolean> = new EventEmitter();

  errorsEvent: EventEmitter<any[]> = new EventEmitter();

  resultEvent: EventEmitter<IManifest> = new EventEmitter();

  isProcessed = false;

  isProcessing = false;

  errors: string[] = [];

  manifestId: string;

  waybills: IWayBillInManifest[] = [];

  waybillsTableColumns: string[] = [
    'lineNumber',
    'reference',
    'carrierCode',
    'waybillNature',
    'manifestedWeight',
    'manifestedPackages',
    'totalContainers',
    'totalVehicles',
    'actions*'
  ];

  coloaders: IColoader[] = [];

  coloadersTableColumns: string[] = [
    'coloader',
    'totalWaybills',
    'totalContainers',
    'totalPackages',
    'totalVehicles',
    'totalWeight',
    'validationDateTime'
  ];

  private isDestroyed = false;

  manifestForm: FormGroup = this.fb.group({
    id: ['', Validators.required],
    voyageId: this.fb.group({
      countryCode: ['', Validators.required],
      officeCode: ['', Validators.required],
      voyageNumber: ['', Validators.required],
      departureDate: ['', Validators.required],
    }),
    departureTime: ['', Validators.required],
    master: ['', Validators.required],
    totalWaybills: ['', Validators.required],
    totalContainers: ['', Validators.required],
    totalPackages: ['', Validators.required],
    totalVehicles: ['', Validators.required],
    totalWeight: ['', Validators.required],
    arrivalDate: ['', Validators.required],
    arrivalTime: ['', Validators.required],
    registrationId: this.fb.group({
      registrationYear: ['', Validators.required],
      registrationNumber: ['', Validators.required],
    }),
    registrationDate: Date,
    registrationDateTime: ['', Validators.required],
    loadingPort: ['', Validators.required],
    unloadingPort: ['', Validators.required],
    carrier: this.fb.group({
      carrierCode: ['', Validators.required],
      carrierName: ['', Validators.required],
      carrierAddress: ['', Validators.required],
    }),
    shippingAgent: this.fb.group({
      shippingAgentCode: ['', Validators.required],
      shippingAgentName: ['', Validators.required],
    }),
    indicator: ['', Validators.required],
    transporterName: ['', Validators.required],
    transporterPlace: ['', Validators.required],
    transporterIdentifier: ['', Validators.required],
    transporterIdentifierDate: ['', Validators.required],
    transporterNationality: ['', Validators.required],
    transportMode: ['', Validators.required],
    netTonnage: ['', Validators.required],
    grossTonnage: ['', Validators.required],
    lastDischage: ['', Validators.required],
    previousRegistrationOffice: ['', Validators.required],
    previousRegistration: this.fb.group({
      registrationYear: ['', Validators.required],
      registrationNumber: ['', Validators.required],
    }),
    destinationOffice: ['', Validators.required],
    containers: this.fb.array([])
  });

  public static defineNavigation(): NavigationComponentType {
    return {
      component: ViewManifestComponent,
      linkName: 'View',
      tabTitle: 'View Manifest',
      windowTitle: 'Cargo Manifest > View',
      windowClose: true,
      singleton: false,
      icon: '',
      accesses: ['cargo.manifest.get']
    };
  }

  clickTab(tabRank: number) {
    this.tab = tabRank;
  }

  isActiveTab(tabRank: number): boolean {
    return this.tab === tabRank;
  }

  ngOnInit() {
    this.processingEvent.subscribe(isProcessing => this.isProcessing = isProcessing);

    this.errorsEvent.subscribe(errors => {
      this.errors = [];

      errors.forEach(error => {
        const fcontrol: AbstractControl = this.manifestForm.get(error.path);

        if (fcontrol) {
          fcontrol.setErrors({ serverError: error.message });
        } else {
          this.errors.push(error.message);
        }

        if (this.errors.length === 0) {
          this.errors.push('Unable to view manifest data, please try again.');
        }
      });
    });

    this.resultEvent.subscribe(result => {
      if (result) {
        this.isProcessed = true;

        this.manifestForm.disable();

        this.nav.changeTitle(`View Manifest [${result.voyageId.countryCode} ${result.voyageId.officeCode} ${result.voyageId.voyageNumber}]`);

        this.manifestForm.patchValue(result);

        if(!isNullOrUndefined(result.voyageId.departureDate)) {
          (this.manifestForm.controls.voyageId as any).controls.departureDate.setValue(formatDate(result.voyageId.departureDate, 'dd/MM/yyyy', 'en_BB'));
        }

       if(!isNullOrUndefined(result.departureTime)) {
          //this.manifestForm.controls.departureTime.setValue(formatDate(result.departureTime, 'HH:mm', 'en_BB'));
        }

        if(!isNullOrUndefined(result.arrivalDate)) {
          this.manifestForm.controls.arrivalDate.setValue(formatDate(result.arrivalDate, 'dd/MM/yyyy', 'en_BB'));
        }

        if(!isNullOrUndefined(result.registrationDateTime)) {
          this.manifestForm.controls.registrationDate.setValue(formatDate(result.registrationDateTime, 'dd/MM/yyyy', 'en_BB'));  
          this.manifestForm.controls.registrationDateTime.setValue(formatDate(result.registrationDateTime, 'HH:mm:ss', 'en_BB'));
        }

        if(!isNullOrUndefined(result.lastDischage)) {
          this.manifestForm.controls.lastDischage.setValue(formatDate(result.lastDischage, 'dd/MM/yyyy', 'en_BB'));
        }

        result.containers.forEach(c => {
          this.containers.push(
            this.fb.group({
              id: [c.id, Validators.required],
              voyageId: this.fb.group({
                countryCode: [c.countryCode, Validators.required],
                officeCode: [c.officeCode, Validators.required],
                voyageNumber: [c.voyageNumber, Validators.required],
                departureDate: [c.departureDate, Validators.required],
              }),
              containerReference: [c.containerReference, Validators.required],
              containerType: [c.containerType, Validators.required],
              loadIndicator: [c.loadIndicator, Validators.required],
              carrierOwner: [c.carrierOwner, Validators.required],
              unladenWeight: [c.unladenWeight, Validators.required],
              minTemperature: [c.minTemperature, Validators.required],
              maxTemperature: [c.maxTemperature, Validators.required],
              volume: [c.volume, Validators.required],
              seals: this.fb.group({
                sealReference: [c.sealReference, Validators.required],
                operator: [c.operator, Validators.required],
              }),
              disposition: [c.disposition, Validators.required],
              packages: [c.packages, Validators.required],
              goodsWeight: [c.goodsWeight, Validators.required]
            })
          );
        });

        const tmpWaybills: IWayBillInManifest[] = [];

        result.waybills.forEach(w => {
          tmpWaybills.push({
            id: w.id,
            reference: w.reference,
            parentReference: w.parentReference,
            carrierCode: w.carrier.carrierCode,
            lineNumber: w.lineNumber,
            waybillNature: w.waybillNature,
            manifestedWeight: w.manifestedWeight,
            manifestedPackages: w.manifestedPackages,
            totalContainers: w.totalContainers,
            totalVehicles: w.totalVehicles
          });
        });

        tmpWaybills.sort((a, b) => {
          if (a.lineNumber < b.lineNumber) { return -1; }
          if (a.lineNumber === b.lineNumber) { return 0; }
          return 1;
        });

        this.waybills = tmpWaybills;

        const tmpColoaders: IColoader[] = [];

        result.coloaders.forEach(c => {
          tmpColoaders.push({
            coloader: c.coloader,
            totalWaybills: c.totalWaybills,
            totalContainers: c.totalContainers,
            totalPackages: c.totalPackages,
            totalVehicles: c.totalVehicles,
            totalWeight: c.totalWeight,
            indicator: c.indicator,
            validationDateTime: c.validationDateTime
          });
        });

        this.coloaders = tmpColoaders;

        this.manifestForm.disable();
      }

      this.errors = [];
    });

    this.manifestId = this.params.params[0];

    this.refreshCustomsOffices();

    this.refreshCountries();

    //this.refreshUNLocodes();

    this.initManifest();
  }

  ngOnDestroy() {
    this.isDestroyed = true;
  }

  refreshCustomsOffices(): void {
    if (this.isDestroyed) { return; }

    this.countries = [];

    this.table.getCustomsOffices().subscribe((result: ICustomsOffice[]) => {
      if (result) {
        this.customsOffices = result;
      }
    }, () => setTimeout(() => this.refreshCustomsOffices(), 5000));
  }

  refreshCountries(): void {
    if (this.isDestroyed) { return; }

    this.countries = [];

    this.table.getCountries().subscribe((result: ICountry[]) => {
      if (result) {
        this.countries = result;
      }
    }, () => setTimeout(() => this.refreshCountries(), 5000));
  }

  refreshUNLocodes(): void {
    if (this.isDestroyed) { return; }

    this.unlocodes = [];

    this.table.getUnlocodes().subscribe((result: IUNLocode[]) => {
      if (result) {
        this.unlocodes = result;
      }
    }, () => setTimeout(() => this.refreshUNLocodes(), 35000));
  }

  private initManifest() {
    this.cargo.getManifestById(this.manifestId, this.processingEvent, this.resultEvent, this.errorsEvent);
  }

  ctl(path: string) { return this.manifestForm.get(path); }
}
