import { NgModule } from '@angular/core';
import { CoreCommonModule } from 'src/app/core/common/core-common.module';
import { FindRolesComponent } from './find-roles/find-roles.component';
import { AddRoleComponent } from './add-role/add-role.component';
import { UpdateRoleComponent } from './update-role/update-role.component';
import { ViewRoleComponent } from './view-role/view-role.component';
import { AuthenticationModule } from 'src/app/core/authentication/authentication.module';

@NgModule({
  imports: [
    CoreCommonModule,
    AuthenticationModule
  ],
  declarations: [
    FindRolesComponent,
    AddRoleComponent,
    UpdateRoleComponent,
    ViewRoleComponent
  ],
  entryComponents: [
    FindRolesComponent,
    AddRoleComponent,
    UpdateRoleComponent,
    ViewRoleComponent
  ]
})
export class RolesModule { }
