import {Component, EventEmitter, OnDestroy, OnInit} from '@angular/core';
import {AuthenticationService} from 'src/app/core/authentication/services/authentication.service';
import {NavigationService} from 'src/app/core/navigation/services/navigation.service';
import {NavigationParams} from 'src/app/core/common/types/NavigationParams.type';
import {NavigationComponentType} from 'src/app/tree.service';
import {ReferenceService} from 'src/app/modules/reference.service';
import {AbstractControl, FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {IComboSystemRole, IComboUserGroup} from '../../admin-model';
import {AdminProfileService} from '../profile.service';
import {ICountry} from 'src/app/modules/reference-model';
import {DataServiceError} from 'src/app/core/common/http/HttpModel';

@Component({
  selector: 'awwAdminMyProfile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.css']
})
export class MyProfileComponent implements OnInit, OnDestroy {

  processingEvent: EventEmitter<boolean> = new EventEmitter();
  errorsEvent: EventEmitter<DataServiceError[]> = new EventEmitter();
  resultEvent: EventEmitter<any> = new EventEmitter();
  isDirectAccess = false;
  isProcessed = false;
  isProcessing = false;
  groups: IComboUserGroup[] = [];
  countries: ICountry[] = [];
  roles: IComboSystemRole[] = [];
  rolesGranted: string[] = [];
  errors: string[] = [];
  profileForm: FormGroup = this.fb.group({
    username: ['', Validators.required],
    profile: this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      functionalTitle: ['', Validators.required],
      mobile: [''],
      telephone: [''],
      telephoneExtension: [''],
      emailAddress: ['', Validators.email]
    }),
    address: this.fb.group({
      addressLine1: [''],
      addressLine2: [''],
      addressLine3: [''],
      building: [''],
      city: ['', Validators.required],
      countryCode: ['', Validators.required],
      region: [''],
      street: [''],
      unit: ['']
    }),
    groupCode: ['', Validators.required],
    properties: this.fb.array([]),
    active: [true, Validators.required],
    clientAccess: [true, Validators.required]
  });
  private type: NavigationComponentType = MyProfileComponent.defineNavigation();
  private isDestroyed = false;

  constructor(
    private auth: AuthenticationService,
    private params: NavigationParams,
    private profile: AdminProfileService,
    private nav: NavigationService,
    private table: ReferenceService,
    private fb: FormBuilder) {

    if (false === this.auth.isUserInRole(this.type.accesses)) {
      throw new Error('Unauthorized navigation request.');
    }
  }

  get properties() {
    return this.profileForm.get('properties') as FormArray;
  }

  public static defineNavigation(): NavigationComponentType {
    return {
      component: MyProfileComponent,
      linkName: 'My Profile',
      tabTitle: 'My Profile',
      windowTitle: 'Home > My Profile',
      windowClose: true,
      singleton: true,
      icon: 'account_box',
      accesses: ['profile']
    };
  }

  ngOnInit() {
    this.processingEvent.subscribe(isProcessing => this.isProcessing = isProcessing);

    this.errorsEvent.subscribe(errors => {
      this.errors = [];

      errors.forEach(error => {
        const fcontrol: AbstractControl = this.profileForm.get(error.path);

        if (fcontrol) {
          fcontrol.setErrors({serverError: error.message});
        } else {
          this.errors.push(error.message);
        }

        if (this.errors.length === 0) {
          this.errors.push('Unable to view profile information, please try again.');
        }
      });
    });

    this.resultEvent.subscribe(result => {
      if (result && result.data) {
        this.isProcessed = true;

        this.profileForm.disable();

        this.profileForm.patchValue(result.data);

        result.data.properties.forEach(p => {
          this.properties.push(this.fb.group({
            propertyCode: [p.propertyCode, Validators.required],
            propertyValue: [p.propertyValue, Validators.required]
          }));
        });

        this.rolesGranted = result.data.roles;

        if (this.isDirectAccess === true) {
          this.refreshGroups();

          this.refreshCountries();

          this.refreshRoles();
        }

        this.profileForm.disable();
      }

      this.errors = [];
    });

    this.isDirectAccess = this.auth.isDirectAccess();

    this.initProfile();
  }

  ngOnDestroy() {
    this.isDestroyed = true;
  }

  ctl(path: string) {
    return this.profileForm.get(path);
  }

  refreshGroups(): void {
    if (this.isDestroyed) {
      return;
    }

    this.groups = [];

    this.profile.getGroups().subscribe((result: IComboUserGroup[]) => {
      if (result) {
        this.groups = result;
      }
    }, () => setTimeout(() => this.refreshGroups(), 5000));
  }

  refreshCountries(): void {
    if (this.isDestroyed) {
      return;
    }

    this.countries = [];

    this.table.getCountries().subscribe((result: ICountry[]) => {
      if (result) {
        this.countries = result;
      }
    }, () => setTimeout(() => this.refreshCountries(), 5000));
  }

  refreshRoles(): void {
    if (this.isDestroyed) {
      return;
    }

    this.roles = [];

    const code = this.ctl('groupCode').value;

    if (!code || code.length === 0) {
      return;
    }

    this.profile.getRoles(code).subscribe((result: IComboSystemRole[]) => {
      if (result) {
        this.roles = result;
      }

      this.syncRoles();
    }, (errors: DataServiceError[]) => {
      this.syncRoles();

      setTimeout(() => this.refreshRoles(), 5000);
    });
  }

  isGranted(role: IComboSystemRole): boolean {
    return -1 !== this.rolesGranted.indexOf(role.roleCode, 0);
  }

  private initProfile() {
    this.profile.getProfile(this.processingEvent, this.resultEvent, this.errorsEvent);
  }

  private syncRoles(): void {
    this.rolesGranted = this.rolesGranted.filter((value: string) => {
      return this.roles.filter(role => {
        return role.roleCode === value;
      }).length > 0;
    });
  }
}
