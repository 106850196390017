import { Component, OnInit, ViewChild, EventEmitter } from '@angular/core';
import { NavigationComponentType } from 'src/app/tree.service';
import { AuthenticationService } from 'src/app/core/authentication/services/authentication.service';
import { FindDataRequest, FindResult } from 'src/app/core/common/model/FinderModel';
import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { MatTableDataSource, MatSort, MatPaginator } from '@angular/material';  
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-passengervetting',
  templateUrl: './passengervetting.component.html',
  styleUrls: ['./passengervetting.component.css']
})

@Injectable()
export class PassengervettingComponent implements OnInit {

  dataSource : any =[];

  displayedColumns: string[] = ['REQUEST_DATE', 'FIRST_NAME', 'LAST_NAME', 'DATE_OF_BIRTH', 'SEX', 'TRAVEL_DOCUMENT_TYPE', 'QUERY_REQUESTED_DETAILS', 'RESPONSE'];  
  @ViewChild(DatatableComponent) table:  DatatableComponent;
  @ViewChild(MatPaginator) paginator: MatPaginator;  
  @ViewChild(MatSort) sort: MatSort;  
  items : any = [];


  constructor(private http : HttpClient){}


  ngOnInit(): void {
    
    this.http.get(environment.applicationAPIURL + 'acisvettingrequestpassenger')
    .subscribe((Response : {}[]) => {
      this.dataSource = new MatTableDataSource(Response);
      this.dataSource.paginator = this.paginator;  
      this.dataSource.sort = this.sort;
    });
  }


  applyFilter(filterValue: string) {  
  this.dataSource.filter = filterValue.trim().toLowerCase();  

  if (this.dataSource.paginator) {  
    this.dataSource.paginator.firstPage();  
  }  
  }  

  public static defineNavigation(): NavigationComponentType {
    return {
      component: PassengervettingComponent,
      linkName: 'Passenger Vetting',
      tabTitle: 'Passenger Vetting',
      windowTitle: 'Acis Vetting Request > Passenger Vetting',
      windowClose: true,
      singleton: true,
      icon: 'description',
      accesses: ['risk.report.find']
    };
  }

}
