import { Component, OnInit, ViewChild, EventEmitter } from '@angular/core';
import { NavigationComponentType } from 'src/app/tree.service';
import { AuthenticationService } from 'src/app/core/authentication/services/authentication.service';
import { MatSort } from '@angular/material';
import { FindDataRequest, FindResult } from 'src/app/core/common/model/FinderModel';
import { CargoService } from '../../cargo.service';
import { DialogService } from 'src/app/core/common/services/dialog.service';
import { StringFinderOptions, GeneralFinderOptions, DateFinderOptions, NumberFinderOptions, ScalarFinderOptions } from 'src/app/core/common/model/FinderModel';
import { countryName, CaricomCountries } from 'src/app/core/common/model/lookups.model';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'awwFindManifests',
  templateUrl: './find-manifests.component.html',
  styleUrls: ['./find-manifests.component.css']
})
export class FindManifestsComponent implements OnInit {

  public apiErrors: any[] = []

  @ViewChild(MatPaginator) paginator: MatPaginator;

  tableDataSource = new MatTableDataSource<any>();

  // default constructor
  constructor(
    private auth: AuthenticationService,
    private cargo: CargoService, private dialog: DialogService) {
    if (false === auth.isUserInRole(this.type.accesses)) {
      throw new Error('Unauthorized navigation request.');
    }

    // get list of country options to show in finder.
    this.countries = CaricomCountries.map((item) => {
      return { name: item.name, value: item.code };
    });
  }

  // conponenet reference
  private type: NavigationComponentType = FindManifestsComponent.defineNavigation();
  private countries: any[] = [];

  // events..
  $processingEvent: EventEmitter<boolean> = new EventEmitter();
  $errorsEvent: EventEmitter<any> = new EventEmitter();
  $resultEvent: EventEmitter<FindResult> = new EventEmitter();

  // table fields and data source
  resultColumns: string[] = ['id', 'voyageId', 'carrier', 'arrivalDate', 'arrivalTime', 'registrationId', 'loadingPort', 'unloadingPort'];
  displayedColumns: string[] = ['countryCode', 'officeCode', 'departureDate', 'voyageNumber', 'carrierCode', 'arrivalDate', 'arrivalTime', 'registrationYear', 'registrationNumber', 'loadingPort', 'unloadingPort', 'actions*'];

  // find data request.
  findDataRequest: FindDataRequest = {
    isDefault: true,
    page: 1,
    pageSize: 100,
    sortColumn: this.resultColumns[1],
    ascendingOrder: false,
    viewColumn: this.resultColumns
  };

  // form flags
  isProcessing = false;
  isError = false;

  // form element references
  @ViewChild(MatSort) sort: MatSort;

  // component definition
  public static defineNavigation(): NavigationComponentType {
    return {
      component: FindManifestsComponent,
      linkName: 'Find',
      tabTitle: 'Cargo Manifests',
      windowTitle: 'Cargo Manifests > Find',
      windowClose: true,
      singleton: true,
      icon: 'search',
      accesses: ['cargo.manifest.find']
    };
  }

  // open a search finder for Manifest
  openFinder(): void {

    // fields to build search form.
    const searchFields: any[] = [
      { title: 'VOYAGE #', field: 'voyageId.voyageNumber', options: StringFinderOptions, type: 'string' },
      { title: 'DATE OF DEPARTURE', field: 'voyageId.departureDate', options: DateFinderOptions, type: 'date' },
      { title: 'OFFICE CODE', field: 'voyageId.officeCode', options: StringFinderOptions, type: 'string' },
      { title: 'COUNTRY', field: 'voyageId.countryCode', options: ScalarFinderOptions, type: 'lookup', list: this.countries },
      { title: 'PLACE OF DEPARTURE', field: 'loadingPort', options: StringFinderOptions, type: 'string' },
      { title: 'PLACE OF DESTINATION', field: 'unloadingPort', options: StringFinderOptions, type: 'string' },
      { title: 'DATE OF ARRIVAL', field: 'arrivalDate', options: DateFinderOptions, type: 'date' },
      { title: 'REGISTRATION YEAR', field: 'registrationId.registrationYear', options: NumberFinderOptions, type: 'number' },
      { title: 'REGISTRATION #', field: 'registrationId.registrationNumber', options: NumberFinderOptions, type: 'number' },
    ];

    // delay the display of search dialog.
    // prevents issue where error is thrown because component is not created yet.
    // https://github.com/angular/material2/issues/10705
    setTimeout(() => {

      this.dialog.finder('Find Manifest', searchFields).subscribe((criteria) => {
        // if the criteria is passed we assume the user chose to submit the search
        if (criteria) {
          criteria.forEach((crit) => {
            if (crit.fieldName == 'registrationId.registrationYear' || crit.fieldName == 'registrationId.registrationNumber') {
              if (crit.value) {
                crit.value = parseInt(crit.value);
              }
            }
          });
          this.findDataRequest.viewColumn = this.resultColumns;
          this.findDataRequest.criteria = criteria;
          this.cargo.getSearchResultsForManifest(this.findDataRequest, this.$processingEvent, this.$resultEvent, this.$errorsEvent);
        }

      });
    });

  }

  ngOnInit() {

    this.$processingEvent.subscribe(isProcessing => this.isProcessing = isProcessing);

    this.$errorsEvent.subscribe(errors => {
      this.isError = true;
      this.apiErrors = errors;
    });

    this.$resultEvent.subscribe(result => {
      this.isError = false;

      if (result) {
        const findResult: FindResult = result;

        /*// if results are returned
        if (result.resultItems) {
          // map the country names
          result.resultItems.forEach((item) => {
            item.country = countryName(item.voyageId.countryCode);
          });
        }*/

        if (findResult) {
          this.findDataRequest = {
            isDefault: false,
            page: findResult.page,
            pageSize: findResult.pageSize,
            sortColumn: findResult.sortColumn,
            ascendingOrder: findResult.ascendingOrder,
            criteria: this.findDataRequest.criteria
          };

          // clear out previous search list.
          this.tableDataSource.data = [];

          if (findResult.resultItems && findResult.resultItems.length > 0) {

            this.tableDataSource.data = findResult.resultItems;
          }
        }
      }
    });

    // open the search form
    this.openFinder();
  }

  ngAfterViewInit() {    
    this.tableDataSource.sort = this.sort;
    this.tableDataSource.paginator = this.paginator;
  }

  refreshTableData(findDataRequest: FindDataRequest, reset: boolean) {
    if (reset === true) {
      this.tableDataSource.data = [];
    }
    this.cargo.getSearchResultsForManifest(findDataRequest, this.$processingEvent, this.$resultEvent, this.$errorsEvent);
  }

  closeErr(): void {
    this.isError = false;
    this.apiErrors = [];
  }
}
