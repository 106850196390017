import { Component, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { formatDate } from '@angular/common';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from 'src/app/core/authentication/services/authentication.service';
import { DialogService } from 'src/app/core/common/services/dialog.service';
import { NavigationParams } from 'src/app/core/common/types/NavigationParams.type';
import { NavigationComponentType } from 'src/app/tree.service';
import { TimedFieldDefinition, TimedTableDefinition, TimedTableResult, TimedTableUpdate, OriginalRowValue } from '../table-model';
import { TableService } from '../table.service';
import { FindDataRequest } from 'src/app/core/common/model/FinderModel';

@Component({
  selector: 'app-edit-table',
  templateUrl: './timed-table.component.html',
  styleUrls: ['./timed-table.component.css']
})
export class TimedTableComponent implements OnInit, OnDestroy {

  constructor(
    private auth: AuthenticationService,
    private params: NavigationParams,
    private table: TableService,
    private fb: FormBuilder,
    private dialog: DialogService
  ) {
    const viewOnlyMode: boolean = this.params.params.viewOnlyMode;

    try {
      if (viewOnlyMode === true) {
        this.type = this.typeViewMode;
        this.viewOnlyMode = true;
      }

      if (viewOnlyMode === false) {
        this.type = this.typeEditMode;
        this.viewOnlyMode = false;
      }
    } catch (e) {
      // More safe to make view mode in case of error
      this.type = this.typeViewMode;
      this.viewOnlyMode = true;
    }

    if (this.type) {
      if (false === this.auth.isUserInRole(this.type.accesses)) {
        throw new Error('Unauthorized navigation request.');
      }
    } else {
      // Just in case of error in selecting the type
      throw new Error('Unauthorized navigation request (type has not been provided).');
    }
  }

  get tableRows() {
    return this.referenceTableForm.get('tableRows') as FormArray;
  }

  private type: NavigationComponentType;

  private typeViewMode: NavigationComponentType = TimedTableComponent.defineNavigationViewMode('');

  private typeEditMode: NavigationComponentType = TimedTableComponent.defineNavigationEditMode('');

  windowTitle: string;

  tableName: string;

  viewOnlyMode: boolean;

  findDataRequest: FindDataRequest = {
    isDefault: true,
    page: 1,
    pageSize: 100,
    sortColumn: null,
    ascendingOrder: null
  };

  processingEvent: EventEmitter<boolean> = new EventEmitter();

  errorsEvent: EventEmitter<any> = new EventEmitter();

  resultTableDefinitionEvent: EventEmitter<TimedTableDefinition> = new EventEmitter();

  resultTableResultEvent: EventEmitter<TimedTableResult> = new EventEmitter();

  resultTableSaveEvent: EventEmitter<TimedTableResult> = new EventEmitter();

  isProcessing = false;

  isShowLoadMore = false;

  errors: string[] = [];

  tableDefinition: TimedTableDefinition;

  controlsConfig: {
    [key: string]: any;
  } = {};

  timedTableUpdate: TimedTableUpdate = {
    addEntries: [],
    deleteEntries: [],
    updateEntries: []
  };

  originalValues: OriginalRowValue[] = [];

  referenceTableForm: FormGroup = this.fb.group({
    tableRows: this.fb.array([])
  });

  public static defineNavigationViewMode(tableHumanName: string): NavigationComponentType {
    return {
      component: TimedTableComponent,
      linkName: 'View',
      tabTitle: 'View [' + tableHumanName + ']',
      windowTitle: 'Reference > View > ' + tableHumanName,
      windowClose: true,
      singleton: true,
      icon: 'view_list',
      accesses: ['tt.view']
    };
  }

  public static defineNavigationEditMode(tableHumanName: string): NavigationComponentType {
    return {
      component: TimedTableComponent,
      linkName: 'Edit',
      tabTitle: 'Edit [' + tableHumanName + ']',
      windowTitle: 'Reference > Edit > ' + tableHumanName,
      windowClose: true,
      singleton: true,
      icon: 'edit',
      accesses: ['tt.edit']
    };
  }

  ngOnInit() {
    this.windowTitle = this.type.windowTitle;

    this.tableName = this.params.params.name;

    this.processingEvent.subscribe(isProcessing => this.isProcessing = isProcessing);

    this.errorsEvent.subscribe(errors => {
      this.errors = [];

      errors.forEach(error => {
        const fcontrol: AbstractControl = this.referenceTableForm.get(error.path);

        if (fcontrol) {
          fcontrol.setErrors({ serverError: error.message });
        } else {
          this.errors.push(error.message);
        }

        if (this.errors.length === 0) {
          this.errors.push('Unable to load this reference table, please try again.');
        }
      });
    });

    this.resultTableDefinitionEvent.subscribe(result => {

      this.tableDefinition = null;

      this.errors = [];

      if (result) {
        this.tableDefinition = result;

        if (this.tableDefinition.fields) {
          this.tableDefinition.fields.sort((a, b) => {
            if (a.fieldName === 'validTo') {
              return 1;
            }

            if (a.fieldName === 'validFrom' && b.fieldName === 'validTo') {
              return -1;
            }

            if (a.fieldName === 'validFrom' && b.fieldName !== 'validTo') {
              return 1;
            }

            if (a.primaryKey && !b.primaryKey) {
              return -1;
            }

            return a.fieldName < b.fieldName ? -1 : (a.fieldName === b.fieldName ? 0 : 1);
          });

          this.tableDefinition.fields.forEach(control => {
            if (control.required) {
              this.controlsConfig[control.fieldName] = ['', Validators.required];
            } else {
              this.controlsConfig[control.fieldName] = [''];
            }
          });
        }

        this.refreshTableData(this.findDataRequest, false);
      }
    });

    this.resultTableResultEvent.subscribe(result => {
      this.errors = [];

      if (this.viewOnlyMode) {
        this.referenceTableForm.disable();
      }

      if (result) {
        const timedTableResult: TimedTableResult = result;

        if (timedTableResult) {
          this.findDataRequest = {
            isDefault: false,
            page: timedTableResult.page,
            pageSize: timedTableResult.pageSize,
            sortColumn: timedTableResult.sortColumn,
            ascendingOrder: timedTableResult.ascendingOrder
          };

          const formArray: FormArray = this.referenceTableForm.get('tableRows') as FormArray;

          let index: number = formArray.controls.length;

          try {
            this.isShowLoadMore = timedTableResult.resultItems && timedTableResult.resultItems.length === timedTableResult.pageSize;
          } catch (e) {
          }

          for (let rowIndex = 0; timedTableResult.resultItems && rowIndex < timedTableResult.resultItems.length; rowIndex++) {
            this.addTableRow(index++, timedTableResult.resultItems[rowIndex], false);
          }
        }
      } else {
        this.isShowLoadMore = false;
      }

      if (this.viewOnlyMode) {
        this.referenceTableForm.disable();
      }
    });

    this.resultTableSaveEvent.subscribe(result => {
      this.errors = [];

      this.dialog.alert('Changes have been save successfully.', 'Operation Done').subscribe(nothing => {
        this.refreshTableData(this.findDataRequest, true);
      });
    });

    this.refreshTableDefinition();

    if (this.viewOnlyMode) {
      this.referenceTableForm.disable();
    }
  }

  ngOnDestroy() {

  }

  ctl(path: string) { return this.referenceTableForm.get(path); }

  err(path: string) {
    try {
      const errors: any = this.ctl(path).errors;

      if (errors) {
        if (errors.serverError) {
          return errors.serverError;
        }

        if (errors.required === true) {
          return 'Required';
        }

        if (errors.email) {
          return 'Not a valid e-mail address';
        }
      }
    } catch (e) {
    }

    return '';
  }

  iserr(path: string) {
    return this.ctl(path).invalid && (this.ctl(path).touched || this.ctl(path).dirty || this.ctl(path).errors.serverError);
  }

  addTableRow(index: number, dataRow: {}, isCreatedRow: boolean) {
    const newFormGroup: FormGroup = this.fb.group(this.controlsConfig);

    this.tableRows.push(newFormGroup);

    const formGroup: FormGroup = this.tableRows.controls[index] as FormGroup;

    if (dataRow !== null) {
      this.tableDefinition.fields.forEach(control => {
        formGroup.controls[control.fieldName].setValue(dataRow[control.fieldName]);
      });
    }

    this.originalValues[index] = {
      value: isCreatedRow === false ? (JSON.stringify(formGroup.value)) : null,
      isCreated: isCreatedRow,
      isDeleted: false,
      isUpdated: false
    };

    // Disable P.K.
    if (!isCreatedRow) {
      this.tableDefinition.fields.forEach(f => {
        if (f.primaryKey === true) {
          newFormGroup.controls[f.fieldName].disable();
        }
      });
    }
  }

  addNewTableRow() {
    const formArray: FormArray = this.referenceTableForm.get('tableRows') as FormArray;

    let index: number = formArray.controls.length;

    this.addTableRow(index++, null, true);
  }

  deleteTableRow(index: number) {
    const tmpOriginalValue: OriginalRowValue = this.originalValues[index];

    if (tmpOriginalValue.isUpdated === true) {
      this.undoUpdateTableRow(index);
    }

    tmpOriginalValue.isDeleted = true;

    if (tmpOriginalValue.isCreated === true) {
      this.originalValues.splice(index, 1);

      this.tableRows.controls.splice(index, 1);
    }
  }

  undoDeleteTableRow(index: number) {
    const tmpOriginalValue: OriginalRowValue = this.originalValues[index];

    tmpOriginalValue.isDeleted = false;
  }

  updateTableRow(index: number) {
    const tmpOriginalValue: OriginalRowValue = this.originalValues[index];

    if (tmpOriginalValue.isCreated === true) {
      return;
    }

    if (tmpOriginalValue.isDeleted === true) {
      this.undoDeleteTableRow(index);
    }

    tmpOriginalValue.isUpdated = true;
  }

  undoUpdateTableRow(index: number) {
    const tmpOriginalValue: OriginalRowValue = this.originalValues[index];

    const formGroup: FormGroup = this.tableRows.controls[index] as FormGroup;

    formGroup.setValue(JSON.parse(tmpOriginalValue.value));

    tmpOriginalValue.isUpdated = false;
  }

  getControlName(index: number) {
    return this.getTableFields()[index].fieldName;
  }

  getControlWidth(index: number) {
    if (this.isStringControl(index)) {
      let width: number = this.tableDefinition.fields[index].maxSize * 20;

      if (width > 400) {
        width = 400;
      }

      if (width < 100) {
        width = 100;
      }

      return width;
    }

    if (this.isDateControl(index)) {
      return 250;
    }

    if (this.isNumberControl(index)) {
      return 100;
    }

    if (this.isBooleanControl(index)) {
      return 100;
    }
  }

  getTotalControlsWidth(): number {
    let totalWidth: number = 50 + (this.viewOnlyMode ? 0 : 50);

    this.getTableFields().forEach((v, index) => {
      totalWidth += this.getControlWidth(index);
    });

    return totalWidth;
  }

  getTableFields(): TimedFieldDefinition[] {
    try {
      return this.tableDefinition.fields;
    } catch (err) {
    }

    return [];
  }

  getControlDescription(index: number) {
    return this.getTableFields()[index].fieldDescription;
  }

  isStringControl(index: number): boolean {
    return 'StringType' === this.getTableFields()[index].dataType;
  }

  isDateControl(index: number): boolean {
    return 'DateType' === this.getTableFields()[index].dataType;
  }

  isNumberControl(index: number): boolean {
    return 'NumberType' === this.getTableFields()[index].dataType;
  }

  isBooleanControl(index: number): boolean {
    return 'BooleanType' === this.getTableFields()[index].dataType;
  }

  isSortColumn(index: number): boolean {
    return this.findDataRequest.sortColumn === this.getTableFields()[index].fieldName;
  }

  isSortAsc(): boolean {
    return this.findDataRequest.ascendingOrder === true;
  }

  isCreated(index: number): boolean {
    return this.originalValues[index].isCreated === true;
  }

  isUpdated(index: number): boolean {
    return this.originalValues[index].isUpdated === true;
  }

  isDeleted(index: number): boolean {
    return this.originalValues[index].isDeleted === true;
  }

  sortTableData(index: number, isAscending: boolean) {
    if (this.viewOnlyMode === true) {
      this.sortTableDataAfterConfirmation(index, isAscending);
    } else {
      this.dialog
        .ask(
          'Unsaved changes are going to be lost, do you want to proceed in sorting?',
          'Warning'
        ).subscribe(decision => {
          if (decision === true) {
            this.sortTableDataAfterConfirmation(index, isAscending);
          }
        });
    }
  }

  private sortTableDataAfterConfirmation(index: number, isAscending: boolean) {
    const findDataRequest: FindDataRequest = {
      isDefault: false,
      page: 1,
      pageSize: this.findDataRequest.pageSize,
      sortColumn: this.getTableFields()[index].fieldName,
      ascendingOrder: isAscending
    };

    this.refreshTableData(findDataRequest, true);
  }

  refreshTableDefinition() {
    this.table.getTableDefinition(this.tableName, this.processingEvent, this.resultTableDefinitionEvent, this.errorsEvent);
  }

  refreshTableData(findDataRequest: FindDataRequest, reset: boolean) {
    if (reset) {
      this.tableRows.controls.splice(0);

      this.originalValues.splice(0);
    }

    this.table.getTableData(this.tableName, findDataRequest, this.processingEvent, this.resultTableResultEvent, this.errorsEvent);
  }

  loadMoreData() {
    const findDataRequest: FindDataRequest = {
      isDefault: false,
      page: this.findDataRequest.page + 1,
      pageSize: this.findDataRequest.pageSize,
      sortColumn: this.findDataRequest.sortColumn,
      ascendingOrder: this.findDataRequest.ascendingOrder
    };

    this.refreshTableData(findDataRequest, false);
  }

  saveTableChanges() {
    this.dialog.ask('Do you want to save changes?', 'Save Confirmation').subscribe(decision => {
      if (decision === true) {
        this.timedTableUpdate.addEntries.splice(0);
        this.timedTableUpdate.updateEntries.splice(0);
        this.timedTableUpdate.deleteEntries.splice(0);

        this.originalValues.forEach((entry: OriginalRowValue, index: number) => {
          if (entry) {
            const formGroup: FormGroup = this.tableRows.controls[index] as FormGroup;

            const value = formGroup.getRawValue();

            if (value.validFrom === '') {
              value.validFrom = null;
            } else {
               value.validFrom = formatDate(value.validFrom, 'yyyy-MM-ddT00:00:00', 'en_BB');
            }

            if ( value.validTo === '' || value.validTo === null ) {
              value.validTo = null;
            } else {
              value.validTo = formatDate(value.validTo, 'yyyy-MM-ddT00:00:00', 'en_BB');
            }

            // New Created Rows
            if (entry.isCreated === true) {
              this.timedTableUpdate.addEntries.push({
                id: index + '',
                entry: value,
                newValues: null
              });
            }

            // Updated Rows
            if (entry.isUpdated === true) {
              this.timedTableUpdate.updateEntries.push({
                id: index + '',
                entry: JSON.parse(entry.value),
                newValues: value
              });
            }

            // Deleted Rows
            if (entry.isDeleted === true) {
              this.timedTableUpdate.deleteEntries.push({
                id: index + '',
                entry: JSON.parse(entry.value),
                newValues: null
              });
            }
          }
        });

        if(this.timedTableUpdate.addEntries.length == 0 && 
          this.timedTableUpdate.updateEntries.length == 0 && this.timedTableUpdate.deleteEntries.length == 0) {
          this.dialog.alert('No updates to perform.', 'Alert');
          return;
        }

        this.table.saveTableData(this.tableName, this.timedTableUpdate, this.processingEvent, this.resultTableSaveEvent, this.errorsEvent);
      }
    });
  }
}
