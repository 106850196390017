import { Injectable, EventEmitter } from '@angular/core';
import { AuthenticationService } from 'src/app/core/authentication/services/authentication.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { throwError, Observable } from 'rxjs';
import { IComboSystemRole, IComboUserGroup, IChangePasswordModel } from '../admin-model';
import { DataRequest, DataServiceError } from 'src/app/core/common/http/HttpModel';
import { HttpUtils } from 'src/app/core/common/http/HttpUtils';

@Injectable({
  providedIn: 'root'
})
export class AdminReportingService {
  private httpUtils: HttpUtils;

  constructor(private auth: AuthenticationService, private http: HttpClient) {
    this.httpUtils = new HttpUtils(auth, http);
  }

}

