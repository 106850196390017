import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CloseTabDirective } from './directives/close-tab.directive';
import { CloseOpenTabsDirective } from './directives/close-open-tabs.directive';
import { MaxMinTabDirective } from './directives/maxmin-tab.directive';
import { NewTabDirective } from './directives/new-tab.directive';
import { TitleBarComponent } from './components/title-bar.component';
import { WindowComponent } from './components/window/window.component';
import { MaterialModule } from 'src/app/material.module';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { DesktopDialogComponent } from './components/desktop-dialog/desktop-dialog.component';
import { DesktopAlertComponent } from './components/desktop-dialog/desktop-alert.component';
import { DesktopSearchComponent } from './components/desktop-dialog/desktop-search.component';
import { PasswordChangeDialogComponent } from './components/desktop-dialog/password-change.component';

import { FormOverlayComponent } from './components/form-overlay/form-overlay.component';
import { BooleanPipe } from 'src/app/core/common/pipes/boolean-pipe';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    CommonModule,
    MaterialModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NewTabDirective,
    CloseTabDirective,
    CloseOpenTabsDirective,
    MaxMinTabDirective,
    TitleBarComponent,
    WindowComponent,
    DesktopDialogComponent,
    DesktopAlertComponent,
    DesktopSearchComponent,
    FormOverlayComponent,
    BooleanPipe,
    PasswordChangeDialogComponent
  ],
  declarations: [
    NewTabDirective,
    CloseTabDirective,
    CloseOpenTabsDirective,
    MaxMinTabDirective,
    TitleBarComponent,
    WindowComponent,
    DesktopDialogComponent,
    DesktopAlertComponent,
    DesktopSearchComponent,
    FormOverlayComponent,
    BooleanPipe,
    PasswordChangeDialogComponent
  ],
  entryComponents: [
    DesktopDialogComponent,
    DesktopAlertComponent,
    DesktopSearchComponent,
    PasswordChangeDialogComponent
  ]
})
export class CoreCommonModule {
}
