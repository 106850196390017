import {Component, EventEmitter, OnDestroy, OnInit} from '@angular/core';
import {NavigationComponentType} from 'src/app/tree.service';
import {AuthenticationService} from 'src/app/core/authentication/services/authentication.service';
import {NavigationService} from 'src/app/core/navigation/services/navigation.service';
import {NavigationParams} from 'src/app/core/common/types/NavigationParams.type';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DialogService} from 'src/app/core/common/services/dialog.service';
import {ReferenceService} from '../../../reference.service';
import {ICountry} from 'src/app/modules/reference-model';
import {IComboSystemRole, IComboUserGroup} from '../../admin-model';
import {AdminGroupsService} from '../groups.service';
import {DataServiceError} from 'src/app/core/common/http/HttpModel';

@Component({
  selector: 'awwAdminViewGroup',
  templateUrl: './view-group.component.html',
  styleUrls: ['./view-group.component.css']
})
export class ViewGroupComponent implements OnInit, OnDestroy {

  processingEvent: EventEmitter<boolean> = new EventEmitter();
  errorsEvent: EventEmitter<DataServiceError[]> = new EventEmitter();
  resultEvent: EventEmitter<any> = new EventEmitter();
  isProcessed = false;
  isProcessing = false;
  groupCode: string;
  groups: IComboUserGroup[] = [];
  countries: ICountry[] = [];
  roles: IComboSystemRole[] = [];
  rolesGranted: string[] = [];
  errors: string[] = [];
  userGroupForm: FormGroup = this.fb.group({
    groupCode: ['', Validators.required],
    groupName: ['', Validators.required],
    parentGroupCode: [''],
    address: this.fb.group({
      addressLine1: [''],
      addressLine2: [''],
      addressLine3: [''],
      building: [''],
      city: ['', Validators.required],
      countryCode: ['', Validators.required],
      region: [''],
      street: [''],
      unit: ['']
    }),
    contactPerson: this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      functionalTitle: ['', Validators.required],
      mobile: [''],
      telephone: [''],
      telephoneExtension: [''],
      emailAddress: ['', Validators.email]
    }),
    passwordPolicy: this.fb.group({
      minLength: [6, Validators.required],
      maxLength: [6, Validators.required],
      minUpperCase: [0, Validators.required],
      minLowerCase: [0, Validators.required],
      minDigits: [0, Validators.required],
      minSpecialChars: [0, Validators.required],
      warningChangeDays: [180, Validators.required],
      forceChangeDays: [180, Validators.required],
      failedAttempts: [99, Validators.required],
      lockoutDuration: [5, Validators.required]
    })
  });
  private type: NavigationComponentType = ViewGroupComponent.defineNavigation();
  // Special Case [Admin]
  private isAdmin = false;

  private isDestroyed = false;

  constructor(private auth: AuthenticationService,
              private nav: NavigationService,
              private params: NavigationParams,
              private groupserv: AdminGroupsService,
              private table: ReferenceService,
              private dialog: DialogService,
              private fb: FormBuilder) {

    if (false === auth.isUserInRole(this.type.accesses)) {
      throw new Error('Unauthorized navigation request.');
    }
  }

  public static defineNavigation(): NavigationComponentType {
    return {
      component: ViewGroupComponent,
      linkName: 'View',
      tabTitle: 'View User Group',
      windowTitle: 'User Groups > View User Group',
      windowClose: true,
      singleton: false,
      icon: '',
      accesses: ['admin.group.view']
    };
  }

  ngOnInit() {
    this.processingEvent.subscribe(isProcessing => this.isProcessing = isProcessing);

    this.errorsEvent.subscribe(errors => {
      this.errors = [];

      errors.forEach(error => {
        const fcontrol: AbstractControl = this.userGroupForm.get(error.path);

        if (fcontrol) {
          fcontrol.setErrors({serverError: error.message});
        } else {
          this.errors.push(error.message);
        }

        if (this.errors.length === 0) {
          this.errors.push('Unable to view this user group, please try again.');
        }
      });
    });

    this.resultEvent.subscribe(result => {
      if (result && result.data) {
        this.isProcessed = true;

        this.userGroupForm.disable();

        this.userGroupForm.patchValue(result.data);

        this.nav.changeTitle('View User Group [' + result.data.groupCode + ']');

        // Special Case [Admin]
        if ('admin' === result.data.groupCode) {
          this.ctl('groupCode').disable();
          this.ctl('parentGroupCode').disable();
          this.isAdmin = true;
        }

        this.rolesGranted = result.data.roles;

        this.refreshGroups();

        this.refreshCountries();

        this.refreshRoles();

        this.userGroupForm.disable();
      }

      this.errors = [];
    });

    this.groupCode = this.params.params[0];

    this.initUserGroup();
  }

  ngOnDestroy() {
    this.isDestroyed = true;
  }

  ctl(path: string) {
    return this.userGroupForm.get(path);
  }

  refreshGroups(): void {
    if (this.isDestroyed) {
      return;
    }

    this.groups = [];

    this.groupserv.getGroups().subscribe((result: IComboUserGroup[]) => {
      if (result) {
        this.groups = result;
      }
    }, () => setTimeout(() => this.refreshGroups(), 5000));
  }

  refreshCountries(): void {
    if (this.isDestroyed) {
      return;
    }

    this.countries = [];

    this.table.getCountries().subscribe((result: ICountry[]) => {
      if (result) {
        this.countries = result;
      }
    }, () => setTimeout(() => this.refreshCountries(), 5000));
  }

  refreshRoles(): void {
    if (this.isDestroyed) {
      return;
    }

    this.roles = [];

    let code = this.ctl('parentGroupCode').value;

    // Special Case [Admin]
    if (this.isAdmin) {
      code = 'admin';
    }

    if (!code || code.length === 0) {
      return;
    }

    this.groupserv.getRoles(code).subscribe((result: IComboSystemRole[]) => {
      if (result) {
        this.roles = result;
      }

      this.syncRoles();
    }, (errors: DataServiceError[]) => {
      this.syncRoles();

      setTimeout(() => this.refreshRoles(), 5000);
    });
  }

  isGranted(role: IComboSystemRole): boolean {
    return /* this.isAdmin || */ -1 !== this.rolesGranted.indexOf(role.roleCode, 0);
  }

  private initUserGroup() {
    this.groupserv.getUserGroup(this.groupCode, this.processingEvent, this.resultEvent, this.errorsEvent);
  }

  private syncRoles(): void {
    this.rolesGranted = this.rolesGranted.filter((value: string) => {
      return this.roles.filter(role => {
        return role.roleCode === value;
      }).length > 0;
    });
  }
}
