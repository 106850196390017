import {EventEmitter, Injectable, Output} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {throwError} from 'rxjs';
import {catchError, retry} from 'rxjs/operators';
import {environment} from 'src/environments/environment';

export interface LoginResult {
  success: boolean;
  error?: string;
}

export interface UserSession {
  authenticated: boolean;
  authenticationData: any;
  username: string;
  fullName: string;
  functionalTitle: string;
  token: string;
  loginDomain: string;
  userAccesses: string[];
}

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private static userSession: UserSession;
  @Output() logInEvent: EventEmitter<LoginResult> = new EventEmitter();
  @Output() logOutEvent: EventEmitter<void> = new EventEmitter();

  constructor(private http: HttpClient) {
  }

  public authenticate(domain: string, username: string, password: string, processing: EventEmitter<boolean>): void {
    processing.emit(true);

    // FIXME
    // REMOVE THIS AFTER FINALIZING THE TEST AS IT MAYBE USED TO CHEAT THE AUTHENTICATION PROCESS BY THE DEVELOPERS THEMSELVES
    if (environment.production === false && environment.applicationTestUserName && environment.applicationTestUserName === username && '1' === password) {
      this.authenticateFake(domain, username, processing);

      return;
    }

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + btoa(username + ':' + password),
        'Authorization-Domain': domain
      }),
      withCredentials: false
    };

    this.http.post(environment.applicationServerURL + 'api/admin/auth/token', null, httpOptions)
      .pipe(
        retry(3),
        catchError(this.handleError)
      ).subscribe((result: any) => {
      processing.emit(false);

      if (result != null) {
        AuthenticationService.userSession = null;

        const tmpUserSession: UserSession = {
          authenticated: true,
          authenticationData: result.data,
          username: result.data.profile.username,
          fullName: result.data.profile.profile.firstName + ' ' + result.data.profile.profile.lastName,
          functionalTitle: result.data.profile.profile.functionalTitle,
          token: result.data.token,
          loginDomain: result.data.loginDomain,
          userAccesses: result.data.accesses
        };

        if (environment.production === false) {
          console.log(result.data);
        }

        const isAllowed: boolean = tmpUserSession.userAccesses
          && tmpUserSession.userAccesses.length > 0
          && tmpUserSession.userAccesses.includes('client')
          && tmpUserSession.authenticationData
          && tmpUserSession.authenticationData.profile
          && tmpUserSession.authenticationData.profile.active
          && tmpUserSession.authenticationData.profile.active === true
          && tmpUserSession.authenticationData.profile.clientAccess
          && tmpUserSession.authenticationData.profile.clientAccess === true;

        if (isAllowed) {
          AuthenticationService.userSession = tmpUserSession;

          this.logInEvent.emit({success: true});

          return true;
        } else {
          this.logInEvent.emit({success: false, error: 'User is inactive or is not authorized to access the application client'});

          return false;
        }
      }
    }, (error: any) => {
      processing.emit(false);

      this.logInEvent.emit({success: false, error: error});

      console.log(error);
    });
  }

  // FIXME
  // REMOVE THIS AFTER FINALIZING THE TEST AS IT MAYBE USED TO CHEAT THE AUTHENTICATION PROCESS BY THE DEVELOPERS THEMSELVES
  public authenticateFake(domain: string, username: string, processing: EventEmitter<boolean>): void {
    processing.emit(true);

    const result: any = environment.applicationTestUserData;

    if (result != null) {
      AuthenticationService.userSession = null;

      const tmpUserSession: UserSession = {
        authenticated: true,
        authenticationData: result.data,
        username: result.data.profile.username,
        fullName: result.data.profile.profile.firstName + ' ' + result.data.profile.profile.lastName,
        functionalTitle: result.data.profile.profile.functionalTitle,
        token: result.data.token,
        loginDomain: result.data.loginDomain,
        userAccesses: result.data.accesses
      };

      const isAllowed: boolean = tmpUserSession.userAccesses
        && tmpUserSession.userAccesses.length > 0
        && tmpUserSession.userAccesses.includes('client')
        && tmpUserSession.authenticationData
        && tmpUserSession.authenticationData.profile
        && tmpUserSession.authenticationData.profile.active
        && tmpUserSession.authenticationData.profile.active === true
        && tmpUserSession.authenticationData.profile.clientAccess
        && tmpUserSession.authenticationData.profile.clientAccess === true;

      if (isAllowed) {
        AuthenticationService.userSession = tmpUserSession;

        this.logInEvent.emit({success: true});
      } else {
        this.logInEvent.emit({success: false, error: 'User is inactive or is not authorized to access the application client'});
      }
    }

    processing.emit(false);
  }

  public logout(): void {
    AuthenticationService.userSession = null;

    this.logOutEvent.emit();
  }

  public isAuthenticated(): boolean {
    if (AuthenticationService.userSession) {
      return AuthenticationService.userSession.authenticated === true;
    }
  }

  public getAuthenticationData(): any {
    if (AuthenticationService.userSession) {
      return AuthenticationService.userSession.authenticationData;
    }
  }

  public getUsername(): string {
    if (AuthenticationService.userSession) {
      return AuthenticationService.userSession.username;
    }
  }

  public getFullName(): string {
    if (AuthenticationService.userSession) {
      return AuthenticationService.userSession.fullName;
    }
  }

  public getFunctionalTitle(): string {
    if (AuthenticationService.userSession) {
      return AuthenticationService.userSession.functionalTitle;
    }
  }

  public getToken(): string {
    if (AuthenticationService.userSession) {
      return AuthenticationService.userSession.token;
    }
  }

  public getLoginDomain(): string {
    if (AuthenticationService.userSession) {
      return AuthenticationService.userSession.loginDomain;
    }
  }

  public getUserAccesses(): string[] {
    if (AuthenticationService.userSession) {
      return AuthenticationService.userSession.userAccesses;
    }
  }

  public isDirectAccess(): boolean {
    if (AuthenticationService.userSession) {
      return 'EMMA' === this.getLoginDomain();
    }
  }

  public isUserInRole(accesses: string[], mode: 'all' | 'some' = 'some'): boolean {
    const userAccesses: string[] = this.getUserAccesses();

    if (this.isAuthenticated() && accesses && accesses.length > 0 && userAccesses && userAccesses.length > 0) {
      if (mode && mode === 'some') {
        return accesses.some(value => {
          return userAccesses.indexOf(value) > 0;
        });
      } else if (mode && mode === 'all') {
        return accesses.every(value => {
          return userAccesses.indexOf(value) > 0;
        });
      }
    }

    return false;
  }

  public isUserNotInRole(accesses: string[]): boolean {
    const userAccesses: string[] = this.getUserAccesses();

    if (this.isAuthenticated() && accesses && accesses.length > 0 && userAccesses && userAccesses.length > 0) {
      return accesses.every(value => {
        return userAccesses.indexOf(value) < 0;
      });
    }

    return false;
  }

  private handleError(err: HttpErrorResponse) {
    let message = 'Unable to login, please check your network settings!';

    console.log(err);

    if (err.error instanceof ErrorEvent) {
    } else {
      try {
        if (err.error.status.code !== 500) {
          message = err.error.status.errors[0].message;
        }
      } catch (e) {
      }
    }

    return throwError(message);
  }
}
