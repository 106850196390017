import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from './core/routing/app-routing.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {CommonModule} from '@angular/common';
import {AppComponent} from './app.component';
import {DesktopClientModule} from './core/desktop-client/desktop-client.module';
import {CoreCommonModule} from './core/common/core-common.module';
import {DesktopModule} from './modules/desktop-module/desktop.module';
import {AdminModule} from './modules/admin-module/admin.module';
import {ServiceWorkerModule} from '@angular/service-worker';
import {environment} from '../environments/environment';
import {TableModule} from './modules/table-module/table.module';
import {CargoModule} from './modules/cargo-module/cargo.module';
import {ReportingModule} from './modules/admin-module/reporting/reporting.module';
import {RiskModule} from './modules/risk-module/risk.module';
import {MAT_DATE_LOCALE} from '@angular/material';
import {RouteReuseStrategy} from '@angular/router';
import {AuthRouteReuseStrategy} from './core/routing/app-routing.strategy';
import {ChartsModule} from 'ng2-charts';
import {RouterModule } from '@angular/router';
   
@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    // Core Application Libraries
    CoreCommonModule,
    DesktopClientModule,
    // Application Modules-   DesktopModule,
    AdminModule,
    TableModule,
    CargoModule,
    ReportingModule,
    RiskModule,
    ChartsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production})
  ],
  declarations: [
    AppComponent
  ],
  providers: [{provide: MAT_DATE_LOCALE, useValue: 'en-BB'}, {
    provide: RouteReuseStrategy,
    useClass: AuthRouteReuseStrategy
  }],
  bootstrap: [AppComponent],
  exports: [ChartsModule]
})
export class AppModule {
}
