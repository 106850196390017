import { NgModule } from '@angular/core';
import { CoreCommonModule } from '../common/core-common.module';
import { UserInfoComponent } from './directives/user-info.component';
import { LogoutDirective } from './directives/logout.directive';
import { IsUserInRole } from './directives/is-user-in-role.directive';
import { LoginComponent } from './components/login/login.component';
import { IsUserNotInRole } from './directives/is-user-not-in-role.directive';

@NgModule({
  imports: [
    CoreCommonModule
  ],
  exports: [
    LoginComponent,
    UserInfoComponent,
    LogoutDirective,
    IsUserInRole,
    IsUserNotInRole
  ],
  declarations: [
    LoginComponent,
    UserInfoComponent,
    LogoutDirective,
    IsUserInRole,
    IsUserNotInRole
  ],
  providers: [

  ]
})
export class AuthenticationModule { }
