import { NavigationComponentType } from './tree.service';
import { DashboardMainComponent } from './modules/desktop-module/dashboard/dashboard-main/dashboard-main.component';
import { MyProfileComponent } from './modules/admin-module/profile/my-profile/my-profile.component';
import { FindUsersComponent } from './modules/admin-module/users/find-users/find-users.component';
import { FindAccessesComponent } from './modules/admin-module/accesses/find-accesses/find-accesses.component';
import { FindRolesComponent } from './modules/admin-module/roles/find-roles/find-roles.component';
import { FindGroupsComponent } from './modules/admin-module/groups/find-groups/find-groups.component';
import { AddUserComponent } from './modules/admin-module/users/add-user/add-user.component';
import { ViewUserComponent } from './modules/admin-module/users/view-user/view-user.component';
import { UpdateUserComponent } from './modules/admin-module/users/update-user/update-user.component';
import { AddGroupComponent } from './modules/admin-module/groups/add-group/add-group.component';
import { UpdateGroupComponent } from './modules/admin-module/groups/update-group/update-group.component';
import { ViewGroupComponent } from './modules/admin-module/groups/view-group/view-group.component';
import { AddRoleComponent } from './modules/admin-module/roles/add-role/add-role.component';
import { UpdateRoleComponent } from './modules/admin-module/roles/update-role/update-role.component';
import { ViewRoleComponent } from './modules/admin-module/roles/view-role/view-role.component';
import { ChangePasswordComponent } from './modules/admin-module/profile/change-password/change-password.component';
import { TimedTableComponent } from './modules/table-module/timed-table/timed-table.component';
import { DashboardComponent } from './modules/admin-module/reporting/dashboard/dashboard.component';
import { RiskalertsdispositionComponent } from './modules/admin-module/reporting/riskalertsdisposition/riskalertsdisposition.component';
import { FindalertsdispositionComponent } from './modules/admin-module/reporting/findalertsdisposition/findalertsdisposition.component';
import { PassengervettingComponent } from './modules/admin-module/reporting/passengervetting/passengervetting.component';
import { ContainervettingComponent } from './modules/admin-module/reporting/containervetting/containervetting.component';
import { ConveyancevettingComponent } from './modules/admin-module/reporting/conveyancevetting/conveyancevetting.component';
import { VehiclevettingComponent } from './modules/admin-module/reporting/vehiclevetting/vehiclevetting.component';
import { GoodsvettingComponent } from './modules/admin-module/reporting/goodsvetting/goodsvetting.component';
import { SeizureinformationComponent } from './modules/admin-module/reporting/seizureinformation/seizureinformation.component';
import { Referencetableshs6codesComponent } from './modules/admin-module/reporting/referencetableshs6codes/referencetableshs6codes.component';
import { ReferencetablescountriesComponent } from './modules/admin-module/reporting/referencetablescountries/referencetablescountries.component';
 
/* Manifest */
import { FindManifestsComponent } from './modules/cargo-module/manifest/find-manifests/find-manifests.component';
import { ViewManifestComponent } from './modules/cargo-module/manifest/view-manifest/view-manifest.component';
/* Waybill */
import { FindWaybillsComponent } from './modules/cargo-module/waybill/find-waybills/find-waybills.component';
import { ViewWaybillComponent } from './modules/cargo-module/waybill/view-waybill/view-waybill.component';
/* Container */
import { FindContainerComponent } from './modules/cargo-module/containers/find-container/find-container.component';
import { CargoContainerDetailsComponent } from './modules/cargo-module/containers/details-container/details-container.component';

/* XML Integration */
import { DegroupageIntegrationComponent } from './modules/cargo-module/fci/degroupage.component';
import { FullCargoIntegrationComponent } from './modules/cargo-module/fci/fci.component';
import { AppendWaybillIntegrationComponent } from './modules/cargo-module/fci/append-waybill.component'; 


/*Risk List*/
import { RiskListDetailsComponent } from './modules/risk-module/riskList/riskList-details/riskList-details.component';
import { FindRiskListComponent } from  './modules/risk-module/riskList/find-riskList/find-riskList.component';
/*Risk Report*/
import { FindRiskReportComponent } from './modules/risk-module/riskReport/find-riskReport/find-riskReport.component';
import { ViewRiskReportComponent } from './modules/risk-module/riskReport/view-riskReport/view-riskReport.component';

/*Risk Report Mailing List*/
import { FindReportRecipientComponent } from './modules/risk-module/riskMailingList/find-recipients/find-recipients.component';
import { RecipientDetailsComponent } from './modules/risk-module/riskMailingList/recipient-details/recipient-details.component';

/* Risk Criteria */
import { RiskCriteriaDetailsComponent } from './modules/risk-module/riskCriteria/details-riskCriteria/details-riskCriteria.component';
//import { ViewRiskCriteriaComponent } from './modules/risk-module/riskCriteria/new-riskCriteria/view-riskCriteria.component';
//import { EditRiskCriteriaComponent } from './modules/risk-module/riskCriteria/new-riskCriteria/edit-riskCriteria.component';
import { FindRiskCriteriaComponent } from './modules/risk-module/riskCriteria/find-riskCriteria/find-riskCriteria.component';

/* Receivers Config */
import { FindReceiversComponent } from './modules/admin-module/config/receivers/find-receivers/find-receivers.component';
import { ReceiverDetailsComponent } from './modules/admin-module/config/receivers/receiver-details/details-receivers.component';

export class TreeComponents {

    private constructor() {
    }

    private static instance: TreeComponents = new TreeComponents();

    private components: Map<String, NavigationComponentType> = new Map<String, NavigationComponentType>();

    public static defineFolder(linkName: string, icon: string = 'folder'): NavigationComponentType {
        return {
            component: null,
            linkName: linkName,
            tabTitle: '',
            windowTitle: '',
            windowClose: false,
            singleton: false,
            icon: icon,
            accesses: []
        };
    }

    public static defineUnknown(linkName: string, tabTitle: string, icon: string = 'folder'): NavigationComponentType {
        return {
            component: null,
            linkName: linkName,
            tabTitle: tabTitle,
            windowTitle: tabTitle,
            windowClose: true,
            singleton: false,
            icon: icon,
            accesses: ['client']
        };
    }

    public static getInstance(): TreeComponents {
        return TreeComponents.instance;
    }

    public forNavigation(): void {

        /* My Profile */
        this.components.set('Profile', MyProfileComponent.defineNavigation());
        this.components.set('Profile/ChangePassword', ChangePasswordComponent.defineNavigation());

        /* User Administration */
        this.components.set('Admin', TreeComponents.defineFolder('User Administration'));

        this.components.set('Admin/Management', TreeComponents.defineFolder('User Management', 'group'));

        this.components.set('Admin/Management/Group', TreeComponents.defineFolder('User Groups', 'description'));
        this.components.set('Admin/Management/Group/New', AddGroupComponent.defineNavigation());
        this.components.set('Admin/Management/Group/View', ViewGroupComponent.defineNavigation());
        this.components.set('Admin/Management/Group/Update', UpdateGroupComponent.defineNavigation());
        this.components.set('Admin/Management/Group/Find', FindGroupsComponent.defineNavigation());

        this.components.set('Admin/Management/User', TreeComponents.defineFolder('User Accounts', 'description'));
        this.components.set('Admin/Management/User/New', AddUserComponent.defineNavigation());
        this.components.set('Admin/Management/User/View', ViewUserComponent.defineNavigation());
        this.components.set('Admin/Management/User/Update', UpdateUserComponent.defineNavigation());
        this.components.set('Admin/Management/User/Find', FindUsersComponent.defineNavigation());

        this.components.set('Admin/Security', TreeComponents.defineFolder('Security', 'security'));

        this.components.set('Admin/Security/Role', TreeComponents.defineFolder('System Roles', 'description'));
        this.components.set('Admin/Security/Role/New', AddRoleComponent.defineNavigation());
        this.components.set('Admin/Security/Role/View', ViewRoleComponent.defineNavigation());
        this.components.set('Admin/Security/Role/Update', UpdateRoleComponent.defineNavigation());
        this.components.set('Admin/Security/Role/Find', FindRolesComponent.defineNavigation());

        this.components.set('Admin/Security/Access', TreeComponents.defineFolder('System Accesses', 'description'));
        this.components.set('Admin/Security/Access/Find', FindAccessesComponent.defineNavigation());

        /* Reporting */
        this.components.set('Reporting', TreeComponents.defineFolder('Reporting'));
        this.components.set('Reporting/Dashboard', DashboardComponent.defineNavigation());
        this.components.set('Reporting/RiskAlerts', TreeComponents.defineFolder('Risk Alerts'));
        this.components.set('Reporting/RiskAlerts/RiskAlertsDisposition', RiskalertsdispositionComponent.defineNavigation());
        this.components.set('Reporting/RiskAlerts/FindAlertsDisposition', FindalertsdispositionComponent.defineNavigation());
        this.components.set('Reporting/AcisVetting', TreeComponents.defineFolder('Acis Vetting'));
        this.components.set('Reporting/AcisVetting/ContainerVetting', ContainervettingComponent.defineNavigation());
        this.components.set('Reporting/AcisVetting/ConveyanceVetting', ConveyancevettingComponent.defineNavigation());
        this.components.set('Reporting/AcisVetting/PassengerVetting', PassengervettingComponent.defineNavigation());
        this.components.set('Reporting/AcisVetting/VehicleVetting', VehiclevettingComponent.defineNavigation());
        this.components.set('Reporting/AcisVetting/GoodsVetting', GoodsvettingComponent.defineNavigation());
        this.components.set('Reporting/SeizureInformation', SeizureinformationComponent.defineNavigation());
        this.components.set('Reporting/ReferenceTables', TreeComponents.defineFolder('Reference'));
        this.components.set('Reporting/ReferenceTables/HS6Codes', Referencetableshs6codesComponent.defineNavigation());
        this.components.set('Reporting/ReferenceTables/Countries', ReferencetablescountriesComponent.defineNavigation());

        /* Cargo Management */
        this.components.set('Cargo', TreeComponents.defineFolder('Cargo Management'));

        this.components.set('Cargo/Manifest', TreeComponents.defineFolder('Manifest', 'description'));
        this.components.set('Cargo/Manifest/Find', FindManifestsComponent.defineNavigation());
        this.components.set('Cargo/Manifest/View', ViewManifestComponent.defineNavigation());

        this.components.set('Cargo/Bill', TreeComponents.defineFolder('Bill of Lading', 'description'));
        this.components.set('Cargo/Bill/Find', FindWaybillsComponent.defineNavigation());
        this.components.set('Cargo/Bill/View', ViewWaybillComponent.defineNavigation());

        this.components.set('Cargo/Container', TreeComponents.defineFolder('Container', 'description'));
        this.components.set('Cargo/Container/Find', FindContainerComponent.defineNavigation());
        this.components.set('Cargo/Container/View', CargoContainerDetailsComponent.defineViewNavigation());
        
        this.components.set('Cargo/XML', TreeComponents.defineFolder('XML Integration', 'description'));
        this.components.set('Cargo/XML/FullCargo', FullCargoIntegrationComponent.defineNavigation());
        this.components.set('Cargo/XML/Degroupage', DegroupageIntegrationComponent.defineNavigation());
        this.components.set('Cargo/XML/AppendWaybill', AppendWaybillIntegrationComponent.defineNavigation());

        /* Risk Management */
        /* Risk List */
        this.components.set('Risk', TreeComponents.defineFolder('Risk Management'));
        this.components.set('Risk/List', TreeComponents.defineFolder('Watch Lists', 'description'));
        this.components.set('Risk/List/New', RiskListDetailsComponent.defineNewNavigation());
        this.components.set('Risk/List/Edit', RiskListDetailsComponent.defineEditNavigation());
        this.components.set('Risk/List/Find', FindRiskListComponent.defineNavigation());
        this.components.set('Risk/List/View', RiskListDetailsComponent.defineViewNavigation());
        /* Risk Report */
        this.components.set('Risk/Report', TreeComponents.defineFolder('Risk Report', 'description'));
        this.components.set('Risk/Report/Find', FindRiskReportComponent.defineNavigation());
        this.components.set('Risk/Report/View', ViewRiskReportComponent.defineNavigation());
        /* Risk Notifications / Mailing List */
        this.components.set('Risk/Notification', TreeComponents.defineFolder('Risk Mailing List', 'description'));
        this.components.set('Risk/Notification/New', RecipientDetailsComponent.defineNewNavigation());
        this.components.set('Risk/Notification/Find', FindReportRecipientComponent.defineNavigation());
        this.components.set('Risk/Notification/Edit', RecipientDetailsComponent.defineEditNavigation());
        this.components.set('Risk/Notification/View', RecipientDetailsComponent.defineViewNavigation());

        /* Risk Criteria */
        this.components.set('Risk/Criteria', TreeComponents.defineFolder('Risk Criteria'));
        this.components.set('Risk/Criteria', TreeComponents.defineFolder('Risk Criteria', 'description'));
        this.components.set('Risk/Criteria/New', RiskCriteriaDetailsComponent.defineNewNavigation());
        this.components.set('Risk/Criteria/View', RiskCriteriaDetailsComponent.defineViewNavigation());
        this.components.set('Risk/Criteria/Find', FindRiskCriteriaComponent.defineNavigation());
        this.components.set('Risk/Criteria/Edit', RiskCriteriaDetailsComponent.defineEditNavigation());

        this.components.set('Risk/RiskCategory', TreeComponents.defineFolder('Risk Category', 'description'));
        this.components.set('Risk/RiskCategory/View', TimedTableComponent.defineNavigationViewMode('Risk Category'));
        this.components.set('Risk/RiskCategory/Edit', TimedTableComponent.defineNavigationEditMode('Risk Category'));

        /* Reference Tables */
        this.components.set('Reference', TreeComponents.defineFolder('Reference Tables'));

        // Reference Folders
        this.components.set('Reference/National', TreeComponents.defineFolder('National Standards'));
        this.components.set('Reference/National/AttachedDocuments', TreeComponents.defineFolder('Attached documents'));
        this.components.set('Reference/National/CarrierAgents', TreeComponents.defineFolder('Carrier\'s Agents'));
        this.components.set('Reference/National/CustomsOffices', TreeComponents.defineFolder('Customs Offices'));
        this.components.set('Reference/National/NationalAirports', TreeComponents.defineFolder('IATA Codes for Customs Offices'));
        this.components.set('Reference/National/Traders', TreeComponents.defineFolder('Traders'));
        this.components.set('Reference/National/TransitSheds', TreeComponents.defineFolder('Transit Sheds'));

        this.components.set('Reference/International', TreeComponents.defineFolder('International Standards'));
        this.components.set('Reference/International/Carriers', TreeComponents.defineFolder('Carriers'));
        this.components.set('Reference/International/ContainerIndicators', TreeComponents.defineFolder('Container Indicators'));
        this.components.set('Reference/International/ContainerTypes', TreeComponents.defineFolder('Container Types'));
        this.components.set('Reference/International/Countries', TreeComponents.defineFolder('Countries'));
        this.components.set('Reference/International/Currencies', TreeComponents.defineFolder('Currencies'));
        this.components.set('Reference/International/DangerousGoods', TreeComponents.defineFolder('Dangerous Goods'));
        this.components.set('Reference/International/FreightCosts', TreeComponents.defineFolder('Freight Costs'));
        this.components.set('Reference/International/HS6Codes', TreeComponents.defineFolder('HS6 Codes'));
        this.components.set('Reference/International/IATAAirports', TreeComponents.defineFolder('IATA Codes for Airports'));
        this.components.set('Reference/International/PackageTypes', TreeComponents.defineFolder('Package Types'));
        this.components.set('Reference/International/SealOperators', TreeComponents.defineFolder('Seal Operators'));
        this.components.set('Reference/International/TransportDocuments', TreeComponents.defineFolder('Transport Documents'));
        this.components.set('Reference/International/TransportDocumentNatures', TreeComponents.defineFolder('Transport Document Natures'));
        this.components.set('Reference/International/TransportModes', TreeComponents.defineFolder('Transport Modes'));
        this.components.set('Reference/International/Unlocodes', TreeComponents.defineFolder('UN Locodes'));
        this.components.set('Reference/International/VehicleBrands', TreeComponents.defineFolder('Vehicle Brands'));
        this.components.set('Reference/International/VehicleDSP', TreeComponents.defineFolder('Vehicle Driving Seating Positions'));
        this.components.set('Reference/International/VehicleEnergyTypes', TreeComponents.defineFolder('Vehicle Energy Types'));
        this.components.set('Reference/International/VehicleModels', TreeComponents.defineFolder('Vehicle Models'));

        // View
        this.components.set('Reference/National/View/AttachedDocuments', TimedTableComponent.defineNavigationViewMode('Attached documents'));
        this.components.set('Reference/National/View/CarrierAgents', TimedTableComponent.defineNavigationViewMode('Carrier\'s Agents'));
        this.components.set('Reference/National/View/CustomsOffices', TimedTableComponent.defineNavigationViewMode('Customs Offices'));
        this.components.set('Reference/National/View/NationalAirports', TimedTableComponent.defineNavigationViewMode('IATA Codes for Customs Offices'));
        this.components.set('Reference/National/View/Traders', TimedTableComponent.defineNavigationViewMode('Traders'));
        this.components.set('Reference/National/View/TransitSheds', TimedTableComponent.defineNavigationViewMode('Transit Sheds'));

        this.components.set('Reference/International/View/Carriers', TimedTableComponent.defineNavigationViewMode('Carriers'));
        this.components.set('Reference/International/View/ContainerIndicators', TimedTableComponent.defineNavigationViewMode('Container Indicators'));
        this.components.set('Reference/International/View/ContainerTypes', TimedTableComponent.defineNavigationViewMode('Container Types'));
        this.components.set('Reference/International/View/Countries', TimedTableComponent.defineNavigationViewMode('Countries'));
        this.components.set('Reference/International/View/Currencies', TimedTableComponent.defineNavigationViewMode('Currencies'));
        this.components.set('Reference/International/View/DangerousGoods', TimedTableComponent.defineNavigationViewMode('Dangerous Goods'));
        this.components.set('Reference/International/View/FreightCosts', TimedTableComponent.defineNavigationViewMode('Freight Costs'));
        this.components.set('Reference/International/View/HS6Codes', TimedTableComponent.defineNavigationViewMode('HS6 Codes'));
        this.components.set('Reference/International/View/IATAAirports', TimedTableComponent.defineNavigationViewMode('IATA Codes for Airports'));
        this.components.set('Reference/International/View/PackageTypes', TimedTableComponent.defineNavigationViewMode('Package Types'));
        this.components.set('Reference/International/View/SealOperators', TimedTableComponent.defineNavigationViewMode('Seal Operators'));
        this.components.set('Reference/International/View/TransportDocuments', TimedTableComponent.defineNavigationViewMode('Transport Documents'));
        this.components.set('Reference/International/View/TransportDocumentNatures', TimedTableComponent.defineNavigationViewMode('Transport Document Natures'));
        this.components.set('Reference/International/View/TransportModes', TimedTableComponent.defineNavigationViewMode('Transport Modes'));
        this.components.set('Reference/International/View/Unlocodes', TimedTableComponent.defineNavigationViewMode('UN Locodes'));
        this.components.set('Reference/International/View/VehicleBrands', TimedTableComponent.defineNavigationViewMode('Vehicle Brands'));
        this.components.set('Reference/International/View/VehicleDSP', TimedTableComponent.defineNavigationViewMode('Vehicle Driving Seating Positions'));
        this.components.set('Reference/International/View/VehicleEnergyTypes', TimedTableComponent.defineNavigationViewMode('Vehicle Energy Types'));
        this.components.set('Reference/International/View/VehicleModels', TimedTableComponent.defineNavigationViewMode('Vehicle Models'));
        
        // Edit
        /** ----- Disable Edit for National Reference tables (Edited through integrations with Member states) ------
        this.components.set('Reference/Edit/AttachedDocuments', TimedTableComponent.defineNavigationEditMode('Attached documents'));
        this.components.set('Reference/Edit/CarrierAgents', TimedTableComponent.defineNavigationEditMode('Carrier\'s Agents'));
        this.components.set('Reference/Edit/CustomsOffices', TimedTableComponent.defineNavigationEditMode('Customs Offices'));
        this.components.set('Reference/Edit/NationalAirports', TimedTableComponent.defineNavigationEditMode('IATA Codes for Customs Offices'));
        this.components.set('Reference/Edit/Traders', TimedTableComponent.defineNavigationEditMode('Traders'));
        this.components.set('Reference/Edit/TransitSheds', TimedTableComponent.defineNavigationEditMode('Transit Sheds')); */
        this.components.set('Reference/International/Edit/Carriers', TimedTableComponent.defineNavigationEditMode('Carriers'));
        this.components.set('Reference/International/Edit/ContainerIndicators', TimedTableComponent.defineNavigationEditMode('Container Indicators'));
        this.components.set('Reference/International/Edit/ContainerTypes', TimedTableComponent.defineNavigationEditMode('Container Types'));
        this.components.set('Reference/International/Edit/Countries', TimedTableComponent.defineNavigationEditMode('Countries'));
        this.components.set('Reference/International/Edit/Currencies', TimedTableComponent.defineNavigationEditMode('Currencies'));
        this.components.set('Reference/International/Edit/DangerousGoods', TimedTableComponent.defineNavigationEditMode('Dangerous Goods'));
        this.components.set('Reference/International/Edit/FreightCosts', TimedTableComponent.defineNavigationEditMode('Freight Costs'));
        this.components.set('Reference/International/Edit/HS6Codes', TimedTableComponent.defineNavigationEditMode('HS6 Codes'));
        this.components.set('Reference/International/Edit/IATAAirports', TimedTableComponent.defineNavigationEditMode('IATA Codes for Airports'));
        this.components.set('Reference/International/Edit/PackageTypes', TimedTableComponent.defineNavigationEditMode('Package Types'));
        this.components.set('Reference/International/Edit/SealOperators', TimedTableComponent.defineNavigationEditMode('Seal Operators'));
        this.components.set('Reference/International/Edit/TransportDocuments', TimedTableComponent.defineNavigationEditMode('Transport Documents'));
        this.components.set('Reference/International/Edit/TransportDocumentNatures', TimedTableComponent.defineNavigationEditMode('Transport Document Natures'));
        this.components.set('Reference/International/Edit/TransportModes', TimedTableComponent.defineNavigationEditMode('Transport Modes'));
        this.components.set('Reference/International/Edit/Unlocodes', TimedTableComponent.defineNavigationEditMode('UN Locodes'));
        this.components.set('Reference/International/Edit/VehicleBrands', TimedTableComponent.defineNavigationEditMode('Vehicle Brands'));
        this.components.set('Reference/International/Edit/VehicleDSP', TimedTableComponent.defineNavigationEditMode('Vehicle Driving Seating Positions'));
        this.components.set('Reference/International/Edit/VehicleEnergyTypes', TimedTableComponent.defineNavigationEditMode('Vehicle Energy Types'));
        this.components.set('Reference/International/Edit/VehicleModels', TimedTableComponent.defineNavigationEditMode('Vehicle Models'));

        /* Config Management */
        this.components.set('Config', TreeComponents.defineFolder('System Configuration'));
        this.components.set('Config/Receivers', TreeComponents.defineFolder('Receivers', 'description'));
        this.components.set('Config/Receivers/New', ReceiverDetailsComponent.defineNewNavigation());
        this.components.set('Config/Receivers/Find', FindReceiversComponent.defineNavigation());
        this.components.set('Config/Receivers/View', ReceiverDetailsComponent.defineViewNavigation());
        this.components.set('Config/Receivers/Edit', ReceiverDetailsComponent.defineEditNavigation());
    }

    public getComponents(): Map<String, NavigationComponentType> {
        return this.components;
    }
}
