import { Injectable, EventEmitter } from '@angular/core';
import { AuthenticationService } from 'src/app/core/authentication/services/authentication.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpUtils} from '../../core/common/http/HttpUtils';
import { RiskTypes} from './shared/RiskTypes.model';
import { RiskList } from './shared/riskList.model';
import { RiskCriteria } from './shared/riskCriteria.model';
import { Recipient } from './riskMailingList/models/recipient.model';
import { DataServiceError, DataRequest } from 'src/app/core/common/http/HttpModel';
import { FindDataRequest } from 'src/app/core/common/model/FinderModel';
import { FieldNameMapper } from './shared/FieldNameMapper';
import { RiskReportView } from './shared/riskReportView.model';

@Injectable({
  providedIn: 'root'
})
export class RiskService {

  private API_PATH = 'api/risk';

  // default constructor.
  constructor(private auth: AuthenticationService, private http: HttpClient, 
    private httpUtils: HttpUtils) {
    
  }

  /**
   * handler api errors
   * @param err - HttpErrorResponse
   */ 
  private handleError(err: HttpErrorResponse) {

    console.log(err);

    const errors: DataServiceError[] = [];

    switch (err.status) {

      case 400:
      case 401:
        errors.push(...err.error.status.errors);
        break;

      case 403:
        errors.push({message: 'Authentication failed, session has expired!', path: '', value: ''});
        break;
      
      default:
        errors.push({message: 'Unexpected error, please contact your administrator', path: '', value: ''});
        break;
    }

    return throwError(errors);
  }

  public getRiskTypes(): Observable<RiskTypes[]> {
    const api = 'api/risk/types';


    return this.httpUtils.invokeGet3<any>(api).pipe(map(result => {
      return result.map(data => new RiskTypes(data.id, data.type)
      );
    }));

  }

  /**
   * Get the fields for the associated manifest section
   * @param entity - section of the manifest to retrieve fields for.
   * @return FieldNameMapper - list of fields for the section
   */
  public getFields(entity: string): Observable<FieldNameMapper[]> {

    const END_POINT = this.API_PATH + `/fields/${entity}`;

    return this.httpUtils.invokeGet3<any>(END_POINT).pipe(map(result => {
      return result.map(data => new FieldNameMapper(data.field, data.humanName));
    }));

  }

  public saveRiskList(body: RiskList, processingEvent: EventEmitter<boolean>, resultEvent: EventEmitter<any>, errorsEvent: EventEmitter<DataServiceError[]>): void {

    const api = 'api/risk/list';

    const request: DataRequest = new DataRequest();

    request.header = {
      id: this.auth.getUsername(),
    };

    request.data = body;
    this.httpUtils.invokePost(api, request, processingEvent, resultEvent, errorsEvent, this.handleError);
  }

  public updateRiskList(riskList: RiskList, processingEvent: EventEmitter<boolean>, resultEvent: EventEmitter<any>, errorsEvent: EventEmitter<DataServiceError[]>):void {

    // endpoint for this call
    const END_POINT = this.API_PATH + '/list';

    this.httpUtils.invokePut(END_POINT, {data:riskList}, processingEvent, resultEvent, errorsEvent, this.handleError);
  }

  public updateRiskCriteria(riskCriteria: RiskCriteria, processingEvent: EventEmitter<boolean>, resultEvent: EventEmitter<any>, errorsEvent: EventEmitter<DataServiceError[]>):void {

    // endpoint for this call
    const END_POINT = this.API_PATH + '/criteria';

    this.httpUtils.invokePut(END_POINT, {data:riskCriteria}, processingEvent, resultEvent, errorsEvent, this.handleError);
  }

  public getRiskListbyId(id: string, processingEvent: EventEmitter<boolean>, resultEvent: EventEmitter<RiskList>, errorsEvent: EventEmitter<DataServiceError[]>): void {
    const api: string = 'api/risk/list/' + id;

    this.httpUtils.invokeGet(api, processingEvent, resultEvent, errorsEvent, this.handleError);
  }

  public getRiskList(id: string, processingEvent: EventEmitter<boolean>, resultEvent: EventEmitter<RiskList>, errorsEvent: EventEmitter<DataServiceError[]>): void {
    const api: string = 'api/risk/list';

    this.httpUtils.invokeGet(api, processingEvent, resultEvent, errorsEvent, this.handleError);
  }


  public getRiskReportbyId(id: string, processingEvent: EventEmitter<boolean>, resultEvent: EventEmitter<RiskReportView>, errorsEvent: EventEmitter<DataServiceError[]>): void {
    const api: string = 'api/risk/report/id/' + id;

    this.httpUtils.invokeGet(api, processingEvent, resultEvent, errorsEvent, this.handleError);

  }


  public getSearchResultsForRiskList<R>(findDataRequest: FindDataRequest, processingEvent: EventEmitter<boolean>, resultEvent: EventEmitter<R>, errorsEvent: EventEmitter<any[]>): void {
    const api = 'api/risk/list/find';

    this.getSearchResults(api, findDataRequest, processingEvent, resultEvent, errorsEvent);
  }


  public getSearchResultsForRiskReport<R>(findDataRequest: FindDataRequest, processingEvent: EventEmitter<boolean>, resultEvent: EventEmitter<R>, errorsEvent: EventEmitter<any[]>): void {
    const api = 'api/risk/report/find';

    this.getSearchResults(api, findDataRequest, processingEvent, resultEvent, errorsEvent);
  }


  private getSearchResults<R>(api: string, findDataRequest: FindDataRequest, processingEvent: EventEmitter<boolean>,
                              resultEvent: EventEmitter<R>, errorsEvent: EventEmitter<any[]>): void {
    if (findDataRequest) {
      api = api + `?page=${findDataRequest.page}&pageSize=${findDataRequest.pageSize}`;

      if (!findDataRequest.isDefault) {
        api = api + `&sortColumn=${findDataRequest.sortColumn}&ascendingOrder=${findDataRequest.ascendingOrder}`;
      }
    }

    this.httpUtils.invokeGet<R, any>(api, processingEvent, resultEvent, errorsEvent, this.handleError);
  }

  /**
   * Send an api request to find Receivers
   * @param req - Find request criteria to search
   * @param processingEvent - Event to indicate the api is currently processing request
   * @param resultEvent - Event to notify when api results are available
   * @param errorsEvent - Event to notify when api errors occur
   */
  public findReports(req: FindDataRequest, processingEvent: EventEmitter<boolean>,
    resultEvent: EventEmitter<any>, errorsEvent: EventEmitter<any>): void {

    // endpoint for this call
    const END_POINT = this.API_PATH + '/report/find';

    this.httpUtils.get(END_POINT, this.getParams(req), processingEvent, resultEvent, errorsEvent, this.handleError);
  }

  /**
   * Send an api request to find risk lists
   * @param req - Find request criteria to search
   * @param processingEvent - Event to indicate the api is currently processing request
   * @param resultEvent - Event to notify when api results are available
   * @param errorsEvent - Event to notify when api errors occur
   */
  public findRiskLists(req: FindDataRequest, processingEvent: EventEmitter<boolean>,
    resultEvent: EventEmitter<any>, errorsEvent: EventEmitter<any>): void {

    // endpoint for this call
    const END_POINT = this.API_PATH + '/list/find';

    this.httpUtils.get(END_POINT, this.getParams(req), processingEvent, resultEvent, errorsEvent, this.handleError);
  }

  /**
   * Send an api request to find risk criterias
   * @param req - Find request criteria to search
   * @param processingEvent - Event to indicate the api is currently processing request
   * @param resultEvent - Event to notify when api results are available
   * @param errorsEvent - Event to notify when api errors occur
   */
  public findRiskCriteria(req: FindDataRequest, processingEvent: EventEmitter<boolean>,
    resultEvent: EventEmitter<any>, errorsEvent: EventEmitter<any>): void {

    // endpoint for this call
    const END_POINT = this.API_PATH + '/criteria/find';

    this.httpUtils.get(END_POINT, this.getParams(req), processingEvent, resultEvent, errorsEvent, this.handleError);
  }

  /**
   * Send an api request to find risk criterias
   * @param req - Find request criteria to search
   * @param processingEvent - Event to indicate the api is currently processing request
   * @param resultEvent - Event to notify when api results are available
   * @param errorsEvent - Event to notify when api errors occur
   */
  public saveRiskCriteria(crit: any, processingEvent: EventEmitter<boolean>,
    resultEvent: EventEmitter<any>, errorsEvent: EventEmitter<any>): void {

    // endpoint for this call
    const END_POINT = this.API_PATH + '/criteria';

    this.httpUtils.invokePost(END_POINT, crit, processingEvent, resultEvent, errorsEvent, this.handleError);
  }

  /**
   * Get a risk list by its type
   * @param type -Type of risk list to retrieve
   * @param processingEvent - Event to indicate the api is currently processing request
   * @param resultEvent - Event to notify when api results are available
   * @param errorsEvent - Event to notify when api errors occur
   */
  public getRiskListByType(type: string, processingEvent: EventEmitter<boolean>, resultEvent: EventEmitter<any>, errorsEvent: EventEmitter<any>): void {
    // endpoint for this call
    const END_POINT = this.API_PATH + `/list/type/${type}`;

    this.httpUtils.invokeGet(END_POINT, processingEvent, resultEvent, errorsEvent, this.handleError);
  }

  /**
   * Delete a risk criteria from the system
   * @param id - persistent id of the criteria to remove
   * @param processingEvent - Event to indicate the api is currently processing request
   * @param resultEvent - Event to notify when api results are available
   * @param errorsEvent - Event to notify when api errors occur
   */
  public deleteRiskCriteria(id: string, processingEvent: EventEmitter<boolean>, resultEvent: EventEmitter<any>, errorsEvent: EventEmitter<any>): void {
    // endpoint for this call
    const END_POINT = this.API_PATH + `/criteria/id/${id}`;

    processingEvent.emit(true);

    this.httpUtils.invokeDelete(END_POINT, this.handleError).subscribe((result) => {

      processingEvent.emit(false);
      resultEvent.emit(result);
    },
    (err) => {
      console.log(err);
      processingEvent.emit(false);
      errorsEvent.emit(err);
    });
  }

  /**
   * Delete a risk list from the system
   * @param id - persistent id of the list to remove
   * @param processingEvent - Event to indicate the api is currently processing request
   * @param resultEvent - Event to notify when api results are available
   * @param errorsEvent - Event to notify when api errors occur
   */
  public deleteRiskList(id: string, processingEvent: EventEmitter<boolean>, resultEvent: EventEmitter<any>, errorsEvent: EventEmitter<any>): void {
    // endpoint for this call
    const END_POINT = this.API_PATH + `/list/${id}`;

    processingEvent.emit(true);

    this.httpUtils.invokeDelete(END_POINT, this.handleError).subscribe((result) => {

      processingEvent.emit(false);
      resultEvent.emit(result);
    },
    (err) => {
      console.log(err);
      processingEvent.emit(false);
      errorsEvent.emit(err);
    });
  }

  /**
   * Process FindDataRequest parameters for search
   * @param req - Finder request
   */
  private getParams(req: FindDataRequest): any {
    const searchRequest: any = Object.assign({}, req);
    searchRequest.criteria = JSON.stringify(req.criteria);
    return searchRequest;
  }

  /**
   * Retrieve a list criteria by its id
   * @param id - persistent id of the criteria to retrieve
   * @param processingEvent - Event to indicate the api is currently processing request
   * @param resultEvent - Event to notify when api results are available
   * @param errorsEvent - Event to notify when api errors occur
   */
  getRiskCriteriaById(id: string, processingEvent: EventEmitter<boolean>, resultEvent: EventEmitter<any>, errorsEvent: EventEmitter<any>): void {
    const END_POINT = this.API_PATH + `/criteria/id/${id}`;
    this.httpUtils.invokeGet(END_POINT, processingEvent, resultEvent, errorsEvent, this.handleError);
  }


  /* ------------------------------- Recipients Endpoints ----------------------------------- */

  /**
   * Store a recipient in the system
   * @param recipient - Recipient model to create record
   * @param processingEvent - Event to indicate the api is currently processing request
   * @param resultEvent - Event to notify when api results are available
   * @param errorsEvent - Event to notify when api errors occur
   */
  public saveRecipient(recipient: Recipient, processingEvent: EventEmitter<boolean>,
    resultEvent: EventEmitter<any>, errorsEvent: EventEmitter<any>) {

    const END_POINT = this.API_PATH + '/dispatch';

    this.httpUtils.invokePost(END_POINT, {data:recipient}, processingEvent, resultEvent, errorsEvent, this.handleError);
  }

  /**
   * Update an existing recipient 
   * @param recipient - Recipient model to update
   * @param processingEvent - Event to indicate the api is currently processing request
   * @param resultEvent - Event to notify when api results are available
   * @param errorsEvent - Event to notify when api errors occur
   */
  public updateRecipient(recipient: Recipient, processingEvent: EventEmitter<boolean>,
    resultEvent: EventEmitter<any>, errorsEvent: EventEmitter<any>) {

    const END_POINT = this.API_PATH + '/dispatch';

    this.httpUtils.invokePut(END_POINT, {data:recipient}, processingEvent, resultEvent, errorsEvent, this.handleError);
  }

  /**
   * Remove a recipient from the system
   * @param receiverId - Persistent id of recipient to remove
   * @param processingEvent - Event to indicate the api is currently processing request
   * @param resultEvent - Event to notify when api results are available
   * @param errorsEvent - Event to notify when api errors occur
   */
  public deleteRecipient(recipientId: string, processingEvent: EventEmitter<boolean>,
    resultEvent: EventEmitter<any>, errorsEvent: EventEmitter<any>) {

    const END_POINT = this.API_PATH + `/dispatch/id/${recipientId}`;

    processingEvent.emit(true);

    this.httpUtils.invokeDelete(END_POINT, this.handleError).subscribe((result) => {

      processingEvent.emit(false);
      resultEvent.emit(result);
    },
    (err) => {
      console.log(err);
      processingEvent.emit(false);
      errorsEvent.emit(err);
    });
  }

  /**
   * Send an api request to find Recipients
   * @param req - Find request criteria to search
   * @param processingEvent - Event to indicate the api is currently processing request
   * @param resultEvent - Event to notify when api results are available
   * @param errorsEvent - Event to notify when api errors occur
   */
  public findRecipients(req: FindDataRequest, processingEvent: EventEmitter<boolean>,
    resultEvent: EventEmitter<any>, errorsEvent: EventEmitter<any>): void {

    // endpoint for this call
    const END_POINT = this.API_PATH + '/dispatch/find';

    // we must send the searchCriterions array as a string
    const searchRequest: any = Object.assign({}, req);
    searchRequest.criteria = JSON.stringify(req.criteria);

    this.httpUtils.get(END_POINT, searchRequest, processingEvent, resultEvent, errorsEvent, this.handleError);
  }

  /**
   * Get the details for a Recipient given its id
   * @param recipientId - peprsistent id of recipient 
   * @param processingEvent - Event to indicate the api is currently processing request
   * @param resultEvent - Event to notify when api results are available
   * @param errorsEvent - Event to notify when api errors occur
   */
  public getRecipient(recipientId: string, processing: EventEmitter<boolean>, result: EventEmitter<any[]>, error: EventEmitter<any>): void {

    const END_POINT = this.API_PATH + `/dispatch/id/${recipientId}`;

    processing.emit(true);

    this.httpUtils.invokeGet3(END_POINT).subscribe((data) => {

      processing.emit(false);

      result.emit(data as any[]);
    },
    (err) => {

      console.log(err);
      processing.emit(false);
      error.emit(err);
    });
  }

}


