import { Directive, HostListener, OnInit, ElementRef } from '@angular/core';
import { NavigationService } from 'src/app/core/navigation/services/navigation.service';
import { DialogService } from '../services/dialog.service';

@Directive({
  selector: '[awwCloseOpenTabs]'
})
export class CloseOpenTabsDirective implements OnInit {

  constructor(private nav: NavigationService, element: ElementRef, public dialog: DialogService) {
    element.nativeElement.style = 'cursor: pointer;';
  }

  ngOnInit() {

  }

  @HostListener('click', ['$event'])
  clickEvent(event) {
    this.dialog.ask('Close all open tabs?').subscribe(decision => {
      if (decision && decision === true) {
        this.nav.closeAll();
      }
    });
  }
}
