import { OnInit, Component, Input } from '@angular/core';
import { NavigationService } from 'src/app/core/navigation/services/navigation.service';


@Component({
  selector: 'awwCommonTitleBar',
  template: '<div class="title-bar gray"><h1>{{titleBar}}</h1><a awwTabSize><mat-icon color="primary">{{!this.isMaximized?"fullscreen":"fullscreen_exit"}}</mat-icon></a><a *ngIf="showClose" awwCloseTab><mat-icon color="primary">close</mat-icon></a></div>'
})
export class TitleBarComponent implements OnInit {

  @Input() showClose = true;

  @Input() titleBar = '';

  isMaximized = false;

  constructor(private nav: NavigationService) {
  }

  ngOnInit() {
    this.nav.toggleTabSizeEvent.subscribe(isMaximized => {
      this.isMaximized = isMaximized;
    });
  }
}
