import { Component, OnInit, EventEmitter, OnDestroy, ViewChild } from '@angular/core';
import { AuthenticationService } from 'src/app/core/authentication/services/authentication.service';
import { NavigationParams } from 'src/app/core/common/types/NavigationParams.type';
import { NavigationComponentType } from 'src/app/tree.service';
import { NavigationService } from 'src/app/core/navigation/services/navigation.service';
import { RiskService } from '../../risk.service';
import { DataServiceError } from 'src/app/core/common/http/HttpModel';
import { NodeTreeRiskReportModel } from '../../shared/nodetree.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { formatDate } from '@angular/common';
import { Countries } from 'src/app/core/common/model/lookups.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'awwRiskReportView',
  templateUrl: './view-riskReport.component.html',
  styleUrls: ['./view-riskReport.component.css']
})
export class ViewRiskReportComponent implements OnInit, OnDestroy {

  // component type reference.
  private type: NavigationComponentType = ViewRiskReportComponent.defineNavigation();

  // risk report reference
  public riskReport:any = {data:{}};

  // event references
  $processingEvent: EventEmitter<boolean> = new EventEmitter();
  $errorsEvent: EventEmitter<DataServiceError[]> = new EventEmitter();
  $resultEvent: EventEmitter<any> = new EventEmitter();

  // flags to indicate processing status
  isProcessed = true;
  isProcessing = false;
  private isDestroyed = false;

  // other references
  errors: string[] = [];
  manifestData: NodeTreeRiskReportModel;
  waybillData: NodeTreeRiskReportModel;
  riskReportId: string;

  // Tabs
  tab = 1;
  tabs: string[] = ['General', 'Manifest', 'Waybill'];

  // event handlers.
  private handlers:Subscription[] = [];

  @ViewChild('formOverlay') overlay:any;

  // default constructor
  constructor(
    private auth: AuthenticationService,
    private nav: NavigationService,
    private params: NavigationParams,
    private risk: RiskService,
    private fb: FormBuilder
  ) {

    if (false === this.auth.isUserInRole(this.type.accesses)) {
       throw new Error('Unauthorized navigation request.');
    }
  }

  public static defineNavigation(): NavigationComponentType {
    return {
      component: ViewRiskReportComponent,
      linkName: 'View',
      tabTitle: 'Risk Assessment Report',
      windowTitle: 'Risk Assessment Report > View',
      windowClose: true,
      singleton: false,
      icon: '',
      accesses: ['risk.report.view','risk.report.find']
    };
  }
  
  clickTab(tabRank: number) {
    this.tab = tabRank;
  }

  isActiveTab(tabRank: number): boolean {
    return this.tab === tabRank;
  }

  ngOnInit(): void {

    this.handlers['$processingEvent'] = this.$processingEvent.subscribe(isProcessing => this.overlay.isProcessing = isProcessing);

    this.handlers['$errorsEvent'] = this.$errorsEvent.subscribe(errors => {
      this.errors = [];
      this.overlay.errors = errors;

    });

    this.handlers['$resultEvent'] = this.$resultEvent.subscribe(result => {
      if (result) {
        this.isProcessed = true;

        this.riskReport = result;

        // map the country name by iso code.
        this.riskReport.data.country = Countries.find((item) => {return item.code === this.riskReport.data.country}).name;
       
       this.manifestData = result.data.manifest;
       this.waybillData = result.data.waybills;

       console.log(`manifest data: =>`, result.data.manifest);
       console.log(`waybill data: =>`, result.data.waybills);
      }

      this.errors = [];
    });

    this.riskReportId = this.params.params[0];

    this.initRiskReport();

    //this.riskReportForm.disable();

  }


  private initRiskReport() {
    this.risk.getRiskReportbyId(this.riskReportId, this.$processingEvent, this.$resultEvent, this.$errorsEvent);
  }

  transformDate(date) {
    return formatDate(date, 'yyyy-MM-dd', 'en-BB');
  }

  ngOnDestroy():void {
    this.handlers.forEach((handle) => handle.unsubscribe());
    this.handlers = null;
    this.isDestroyed = true;
  }

}



