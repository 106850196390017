import { Component, OnInit, EventEmitter, OnDestroy } from '@angular/core';
import { NavigationComponentType } from 'src/app/tree.service';
import { AuthenticationService } from 'src/app/core/authentication/services/authentication.service';
import { NavigationService } from 'src/app/core/navigation/services/navigation.service';
import { NavigationParams } from 'src/app/core/common/types/NavigationParams.type';
import { FormGroup, Validators, FormBuilder, AbstractControl } from '@angular/forms';
import { DialogService } from 'src/app/core/common/services/dialog.service';
import { ReferenceService } from '../../../reference.service';
import { IComboSystemAccess } from '../../admin-model';
import { AdminRolesService } from '../roles.service';
import { DataServiceError } from 'src/app/core/common/http/HttpModel';

@Component({
  selector: 'awwAdminViewRole',
  templateUrl: './view-role.component.html',
  styleUrls: ['./view-role.component.css']
})
export class ViewRoleComponent implements OnInit, OnDestroy {

  constructor(private auth: AuthenticationService,
    private nav: NavigationService,
    private params: NavigationParams,
    private roleserv: AdminRolesService,
    private table: ReferenceService,
    private dialog: DialogService,
    private fb: FormBuilder) {

    if (false === auth.isUserInRole(this.type.accesses)) {
      throw new Error('Unauthorized navigation request.');
    }
  }

  private type: NavigationComponentType = ViewRoleComponent.defineNavigation();

  processingEvent: EventEmitter<boolean> = new EventEmitter();

  errorsEvent: EventEmitter<DataServiceError[]> = new EventEmitter();

  resultEvent: EventEmitter<any> = new EventEmitter();

  isProcessed = false;

  isProcessing = false;

  roleCode: string;

  accesses: IComboSystemAccess[] = [];

  accessesGranted: string[] = [];

  errors: string[] = [];

  // Special Case [Admin]
  private isAdmin = false;

  private isDestroyed = false;

  systemRoleForm: FormGroup = this.fb.group({
    roleCode: ['', Validators.required],
    roleDescription: ['', Validators.required]
  });

  public static defineNavigation(): NavigationComponentType {
    return {
      component: ViewRoleComponent,
      linkName: 'View',
      tabTitle: 'View System Role',
      windowTitle: 'System Roles > View System Role',
      windowClose: true,
      singleton: false,
      icon: '',
      accesses: ['admin.role.view']
    };
  }

  ngOnInit() {
    this.processingEvent.subscribe(isProcessing => this.isProcessing = isProcessing);

    this.errorsEvent.subscribe(errors => {
      this.errors = [];

      errors.forEach(error => {
        const fcontrol: AbstractControl = this.systemRoleForm.get(error.path);

        if (fcontrol) {
          fcontrol.setErrors({ serverError: error.message });
        } else {
          this.errors.push(error.message);
        }

        if (this.errors.length === 0) {
          this.errors.push('Unable to view this user group, please try again.');
        }
      });
    });

    this.resultEvent.subscribe(result => {
      if (result && result.data) {
        this.isProcessed = true;

        this.systemRoleForm.disable();

        this.systemRoleForm.patchValue(result.data);

        this.nav.changeTitle('View System Role [' + result.data.roleCode + ']');

        // Special Case [Admin]
        if ('admin' === result.data.roleCode) {
          this.isAdmin = true;
        }

        this.accessesGranted = result.data.accesses;

        this.refreshAccesses();

        this.systemRoleForm.disable();
      }

      this.errors = [];
    });

    this.roleCode = this.params.params[0];

    this.initSystemRole();
  }

  ngOnDestroy() {
    this.isDestroyed = true;
  }

  private initSystemRole() {
    this.roleserv.getSystemRole(this.roleCode, this.processingEvent, this.resultEvent, this.errorsEvent);
  }

  ctl(path: string) { return this.systemRoleForm.get(path); }

  refreshAccesses(): void {
    if (this.isDestroyed) { return; }

    this.accesses = [];

    this.roleserv.getAccesses().subscribe((result: IComboSystemAccess[]) => {
      if (result) {
        this.accesses = result;
      }

      this.syncAccesses();
    }, (errors: DataServiceError[]) => {
      this.syncAccesses();

      setTimeout(() => this.refreshAccesses(), 5000);
    });
  }

  isGranted(access: IComboSystemAccess): boolean {
    return this.isAdmin || -1 !== this.accessesGranted.indexOf(access.accessCode, 0);
  }

  private syncAccesses(): void {
    this.accessesGranted = this.accessesGranted.filter((value: string) => {
      return this.accesses.filter(access => {
        return access.accessCode === value;
      }).length > 0;
    });
  }
}
