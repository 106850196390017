import {Component, EventEmitter, OnDestroy, OnInit} from '@angular/core';
import {AuthenticationService} from 'src/app/core/authentication/services/authentication.service';
import {NavigationService} from 'src/app/core/navigation/services/navigation.service';
import {NavigationParams} from 'src/app/core/common/types/NavigationParams.type';
import {NavigationComponentType} from 'src/app/tree.service';
import {ReferenceService} from 'src/app/modules/reference.service';
import {AbstractControl, FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AdminReportingService} from '../reporting.service';
import {IComboSystemRole, IComboUserGroup} from '../../admin-model';
import { DialogService } from 'src/app/core/common/services/dialog.service';
import {ICountry} from 'src/app/modules/reference-model';
import {DataServiceError} from 'src/app/core/common/http/HttpModel';
import {AfterViewInit,ElementRef} from '@angular/core';
import {ChartsModule} from 'ng2-charts';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import {HttpClient} from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'awwReportingDashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent {

  riskData = [
    {
      data: [0, 0, 0, 0, 0],
      backgroundColor: ['rgba(255, 0, 0, 0.4)', 'rgba(255, 127, 0, 0.4)', 'rgba(63, 127, 255, 0.4)', 'rgba(0, 255, 0, 0.4)', 'rgba(255, 192, 0, 0.4)'],
      borderColor: ['rgba(255, 0, 0, 0.8)', 'rgba(255, 127, 0, 0.8)', 'rgba(63, 127, 255, 0.8)', 'rgba(0, 255, 0, 0.8)', 'rgba(255, 192, 0, 0.8)'],
      hoverBackgroundColor: ['rgba(255, 0, 0, 0.2)', 'rgba(255, 127, 0, 0.2)', 'rgba(63, 127, 255, 0.2)', 'rgba(0, 255, 0, 0.2)', 'rgba(255, 192, 0, 0.2)'],
      hoverBorderColor: ['rgba(255, 0, 0, 0.4)', 'rgba(255, 127, 0, 0.4)', 'rgba(63, 127, 255, 0.4)', 'rgba(0, 255, 0, 0.4)', 'rgba(255, 192, 0, 0.4)'],
      label: '# Risk Alerts'
    }
  ];

  riskLabels = [
    'SEVERE',
    'HIGH',
    'ELEVATED',
    'GUARDED',
    'LOW'
  ];

  riskOptions = {
    responsive: true,
    scales: {
        yAxes: [{
            ticks: {
                beginAtZero: true
            }
        }]
    }    
  };

  alertData = [
    {
      data: [0, 0, 0, 0, 0, 0, 0 ,0],
      backgroundColor: ['rgba(255, 0, 0, 0.4)',
                        'rgba(255, 127, 0, 0.4)',
                        'rgba(63, 127, 255, 0.4)', 
                        'rgba(0, 255, 0, 0.4)', 
                        'rgba(255, 192, 0, 0.4)',
                        'rgba(128, 0, 255, 0.4)',
                        'rgba(64, 64, 64, 0.4)',
                        'rgba(192, 192, 192, 0.4)',
                        ],
      borderColor: ['rgba(255, 0, 0, 0.8)',
                        'rgba(255, 127, 0, 0.8)',
                        'rgba(63, 127, 255, 0.8)', 
                        'rgba(0, 255, 0, 0.8)', 
                        'rgba(255, 192, 0, 0.8)',
                        'rgba(128, 0, 255, 0.8)',
                        'rgba(64, 64, 64, 0.8)',
                        'rgba(192, 192, 192, 0.8)',
                        ],
      hoverBackgroundColor: ['rgba(255, 0, 0, 0.2)',
                        'rgba(255, 127, 0, 0.2)',
                        'rgba(63, 127, 255, 0.2)', 
                        'rgba(0, 255, 0, 0.2)', 
                        'rgba(255, 192, 0, 0.2)',
                        'rgba(128, 0, 255, 0.2)',
                        'rgba(64, 64, 64, 0.2)',
                        'rgba(192, 192, 192, 0.2)',
                        ],
      hoverBorderColor: ['rgba(255, 0, 0, 0.4)',
                        'rgba(255, 127, 0, 0.4)',
                        'rgba(63, 127, 255, 0.4)', 
                        'rgba(0, 255, 0, 0.4)', 
                        'rgba(255, 192, 0, 0.4)',
                        'rgba(128, 0, 255, 0.4)',
                        'rgba(64, 64, 64, 0.4)',
                        'rgba(192, 192, 192, 0.4)',
                        ],
      label: '# Alerts - Category'
    }
  ];

  alertLabels = [
    'DAN CHEM',
    'DAN CHEM SOU CTY',
    'GUNS AMMO SOU CTY',
    'MED SUP',
    'NAR SOU CTY CAR',
    'UNKNOWN DESC',
    'UNKNOWN EXP SHIP',
    'VES NAM'
  ];

  alertOptions = {
    responsive: true,
    showYAxis: false 
  };
  processingEvent: EventEmitter<boolean> = new EventEmitter();
  errorsEvent: EventEmitter<DataServiceError[]> = new EventEmitter();
  resultEvent: EventEmitter<any> = new EventEmitter();
  isDirectAccess = false;
  isProcessed = false;
  isProcessing = false;
  groups: IComboUserGroup[] = [];
  countries: ICountry[] = [];
  roles: IComboSystemRole[] = [];
  rolesGranted: string[] = [];
  errors: string[] = [];
  private type: NavigationComponentType = DashboardComponent.defineNavigation();
  private isDestroyed = false;
  private testid;
  private PendingAlerts;
  private InProgressAlerts;
  private PendingVetting;
  private InProgressVetting;
  private RiskAlertsData;
  private dataArr;

  constructor(
    private auth: AuthenticationService,
    private route: ActivatedRoute,
    private params: NavigationParams,
    private nav: NavigationService,
    private table: ReferenceService,
    private http : HttpClient,
    private reporting: AdminReportingService, private dialog: DialogService) {
    if (false === auth.isUserInRole(this.type.accesses)) {
      throw new Error('Unauthorized navigation request.');
    }


  }

  ngOnInit() {
    this.http.get(environment.applicationAPIURL + 'dashboardalertsvalues')
    .subscribe((Response) => {
      this.InProgressAlerts = Response[0].COUNT;
      this.PendingAlerts = Response[1].COUNT;
    });

    this.http.get(environment.applicationAPIURL + 'dashboardvettingvalues')
    .subscribe((Response) => {
      this.InProgressVetting = Response[0].COUNT;
      this.PendingVetting = Response[1].COUNT;
    });

    this.http.get(environment.applicationAPIURL + 'dashboardriskalertsdata')
    .subscribe((Response: any) => {
      var severe = 0;
      var high = 0;
      var elevated = 0;
      var guarded = 0;
      var low = 0;
      var result;
      for(result of Array.from(Response)) {
        if (result.RISKLEVEL == "SEVERE")   { severe   = result.RISKCOUNT; }
        if (result.RISKLEVEL == "HIGH")     { high     = result.RISKCOUNT; }
        if (result.RISKLEVEL == "ELEVATED") { elevated = result.RISKCOUNT; }
        if (result.RISKLEVEL == "GUARDED")  { guarded  = result.RISKCOUNT; }
        if (result.RISKLEVEL == "LOW")      { low      = result.RISKCOUNT; }
      }
      this.riskData[0].data = [severe, high, elevated, guarded, low];
    });

    this.http.get(environment.applicationAPIURL + 'dashboardalertsdata') 
    .subscribe((Response: any) => {
      var danchem = 0;
      var danchemsoucty = 0;
      var gunsammosoucty = 0;
      var medsup = 0;
      var narsouctycar = 0;
      var unknowndesc = 0;
      var unknownexpship = 0;
      var vesnam = 0;
      var result;
      for(result of Response) {
        if (result.CRITERIACODE== "DAN_CHEM")          { danchem         = result.CRITERIACOUNT; }
        if (result.CRITERIACODE== "DAN_CHEM_SOU_CTY")  { danchemsoucty   = result.CRITERIACOUNT; }
        if (result.CRITERIACODE== "GUNS_AMMO_SOU_CTY") { gunsammosoucty  = result.CRITERIACOUNT; }
        if (result.CRITERIACODE== "MED_SUP")           { medsup          = result.CRITERIACOUNT; }
        if (result.CRITERIACODE== "NAR_SOU_CTY_CAR")   { narsouctycar    = result.CRITERIACOUNT; }
        if (result.CRITERIACODE== "UNKNOWN_DESC")      { unknowndesc     = result.CRITERIACOUNT; }
        if (result.CRITERIACODE== "UNKNOWN_EXP_SHIP")  { unknownexpship  = result.CRITERIACOUNT; }
        if (result.CRITERIACODE== "VES_NAM")           { vesnam          = result.CRITERIACOUNT; }
      }
      this.alertData[0].data = [danchem, danchemsoucty, gunsammosoucty, medsup, narsouctycar, unknowndesc, unknownexpship, vesnam];
    });

    this.processingEvent.subscribe(isProcessing => this.isProcessing = isProcessing);

    this.errorsEvent.subscribe(errors => {
      this.errors = [];
    });

    this.resultEvent.subscribe(result => {
      if (result && result.data) {
        this.isProcessed = true;

        this.rolesGranted = result.data.roles;

        if (this.isDirectAccess === true) {
          this.refreshGroups();

          this.refreshCountries();

          this.refreshRoles();
        }

      }

      this.errors = [];
    });
  }

  public static defineNavigation(): NavigationComponentType {
    return {
      component: DashboardComponent,
      linkName: 'Dashboard',
      tabTitle: 'Reporting Dashboard',
      windowTitle: 'Home > Reporting > Dashboard',
      windowClose: true,
      singleton: false,
      icon: 'description',
      accesses: ['reporting']
    };
  }

  ngOnDestroy() {
    this.isDestroyed = true;
  }

  refreshGroups(): void {
    if (this.isDestroyed) {
      return;
    }

    this.groups = [];

    // this.profile.getGroups().subscribe((result: IComboUserGroup[]) => {
    //   if (result) {
    //     this.groups = result;
    //   }
    // }, () => setTimeout(() => this.refreshGroups(), 5000));
  }

  refreshCountries(): void {
    if (this.isDestroyed) {
      return;
    }

    this.countries = [];

    // this.table.getCountries().subscribe((result: ICountry[]) => {
    //   if (result) {
    //     this.countries = result;
    //   }
    // }, () => setTimeout(() => this.refreshCountries(), 5000));
  }

  refreshRoles(): void {
    if (this.isDestroyed) {
      return;
    }

    this.roles = [];

    // const code = this.ctl('groupCode').value;

    // if (!code || code.length === 0) {
    //   return;
    // }

    // this.profile.getRoles(code).subscribe((result: IComboSystemRole[]) => {
    //   if (result) {
    //     this.roles = result;
    //   }

    //   this.syncRoles();
    // }, (errors: DataServiceError[]) => {
    //   this.syncRoles();

    //   setTimeout(() => this.refreshRoles(), 5000);
    // });
  }

  isGranted(role: IComboSystemRole): boolean {
    return -1 !== this.rolesGranted.indexOf(role.roleCode, 0);
  }

  private syncRoles(): void {
    this.rolesGranted = this.rolesGranted.filter((value: string) => {
      return this.roles.filter(role => {
        return role.roleCode === value;
      }).length > 0;
    });
  }  



  ngAfterViewInit()
  {
    var s=document.createElement("script");
    s.type="text/javascript";
    // s.innerHTML="console.log('done');"; //inline script
    s.src="/assets/js/init.js"; //external script
  }

  riskalertsline:boolean=true;
  riskalertsbar:boolean=false;
  riskalertspie:boolean=false;

  riskalertslineFunction(){
    this.riskalertsline=true;
    this.riskalertsbar=false;
    this.riskalertspie=false
  }

  riskalertsbarFunction(){
    this.riskalertsbar=true;
    this.riskalertsline=false;
    this.riskalertspie=false
  }

  riskalertspieFunction(){
    this.riskalertspie=true;
    this.riskalertsbar=false;
    this.riskalertsline=false
  }

  alertsradar:boolean=true;
  alertspie:boolean=false;

  alertsradarFunction(){
    this.alertsradar=true;
    this.alertspie=false
  }

  alertspieFunction(){
    this.alertsradar=false;
    this.alertspie=true
  }

}

