import { Injectable, EventEmitter } from '@angular/core';
import { AuthenticationService } from 'src/app/core/authentication/services/authentication.service';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ICountry, ICustomsOffice, IUNLocode } from './reference-model';
import { HttpUtils } from '../core/common/http/HttpUtils';
import { TableService } from './table-module/table.service';
import { TimedTableResult } from './table-module/table-model';
import { FindDataRequest } from 'src/app/core/common/model/FinderModel';

@Injectable({
  providedIn: 'root'
})
export class ReferenceService {

  public getReferenceData: (tableName: string, findDataRequest: FindDataRequest,
    processingEvent: EventEmitter<boolean>, resultEvent: EventEmitter<any>,
    errorsEvent: EventEmitter<any[]>) => void;

  // default constuctor
  constructor(private auth: AuthenticationService, private httpUtils: HttpUtils,
    private tableService: TableService) {

    // map the time table get reference data method
    this.getReferenceData = tableService.getTableData;
  }

  public loadReferenceData(tableName: string):Observable<any> {

    const api = `api/timedTable/${tableName}`;

    return this.httpUtils.invokeGet3<any>(api);
           
  }

  /**
   * Filter Reference Data given a search criteria
   * @param tableName - Name of the reference table
   * @param searchCriteria - Search criteria to filter records.
   * @return any[] - List of reference records
   */
  public filterReferenceData(tableName: string, searchCriteria: FindDataRequest, 
    processingEvent: EventEmitter<boolean>, resultEvent: EventEmitter<any>, errorsEvent: EventEmitter<any[]>): void {

    const api = `api/timedTable/${tableName}/pagination`;
    this.httpUtils.get(api, this.getParams(searchCriteria), processingEvent, resultEvent, errorsEvent, null);
  }

  public getCustomsOffices(): Observable<ICustomsOffice[]> {
    const api = 'api/timedTable/CustomsOffices';

    return this.httpUtils.invokeGet3<any>(api).pipe(map(result => {
      return result.map(row => new ICustomsOffice(row.countryCode, row.code, row.name));
    }));
  }

  public getCountries(): Observable<ICountry[]> {
    const api = 'api/timedTable/Countries';

    return this.httpUtils.invokeGet3<any>(api).pipe(map(result => {
      return result.map(row => new ICountry(row.code, row.name));
    }));
  }

  public getUnlocodes(): Observable<IUNLocode[]> {
    const api = 'api/timedTable/Unlocodes';

    return this.httpUtils.invokeGet3<any>(api).pipe(map(result => {
      return result.map(row => new IUNLocode(row.code, row.description));
    }));
  }

  /**
   * Process FindDataRequest parameters for search
   * @param req - Finder request
   */
  private getParams(req: FindDataRequest): any {
    const searchRequest: any = Object.assign({}, req);
    searchRequest.criteria = JSON.stringify(req.criteria);
    return searchRequest;
  }
}
