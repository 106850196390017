import { Component, OnInit } from '@angular/core';
import { NavigationParams } from '../../../common/types/NavigationParams.type';

@Component({
  selector: 'awwDesktopError404',
  templateUrl: './error404.component.html',
  styleUrls: ['./error404.component.css']
})
export class Error404Component implements OnInit {

  parameters = '';

  constructor(private params: NavigationParams) { }

  ngOnInit() {
    if (this.params.params) {
      this.parameters = JSON.stringify(this.params.params);
    }
  }
}
