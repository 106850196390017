import { Injectable, Type } from '@angular/core';
import { Error404Component } from './core/desktop-client/components/error404/error404.component';
import { TREE_STRUCTURE } from './tree.structure';
import { TreeComponents } from './tree.components';

export interface TreeNode {
    navigation: string;
    children?: TreeNode[];
    params?: object;
}

export interface NavigationComponentType {
    component?: Type<{}>;
    linkName: string;
    tabTitle: string;
    windowTitle: string;
    windowClose: boolean;
    icon: string;
    accesses: string[];
    singleton: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class TreeService {
    private treeComponentsInstance: TreeComponents;

    private components: Map<String, NavigationComponentType>;

    constructor() {
        this.treeComponentsInstance = TreeComponents.getInstance();

        this.treeComponentsInstance.forNavigation();

        this.components = this.treeComponentsInstance.getComponents();
    }

    public getLinkName(navigationKey: string): string {
        const navigationType: NavigationComponentType = this.components.get(navigationKey);

        if (navigationType != null) {
            return navigationType.linkName;
        }

        return '';
    }

    public getTabTitle(navigationKey: string): string {
        const navigationType: NavigationComponentType = this.components.get(navigationKey);

        if (navigationType != null) {
            return navigationType.tabTitle;
        }

        return '';
    }

    public getWindowTitle(navigationKey: string): string {
        const navigationType: NavigationComponentType = this.components.get(navigationKey);

        if (navigationType != null) {
            return navigationType.windowTitle;
        }

        return '';
    }

    public getWindowClose(navigationKey: string): boolean {
        const navigationType: NavigationComponentType = this.components.get(navigationKey);

        if (navigationType != null) {
            return navigationType.windowClose;
        }

        return false;
    }

    public getIcon(navigationKey: string): string {
        const navigationType: NavigationComponentType = this.components.get(navigationKey);

        if (navigationType != null) {
            const icon: string = navigationType.icon;

            if (icon != null) {
                return icon;
            }
        }

        /* Default Icon */
        return 'description';
    }

    public getComponent(navigationKey: string): Type<{}> {
        const navigationType: NavigationComponentType = this.components.get(navigationKey);

        if (navigationType != null) {
            const component: Type<{}> = navigationType.component;

            if (component != null) {
                return component;
            }
        }

        /* Default Component (404) */
        return Error404Component;
    }

    public isSinglton(navigationKey: string): boolean {
        const navigationType: NavigationComponentType = this.components.get(navigationKey);

        if (navigationType != null) {
            const singlton: boolean = navigationType.singleton;

            if (singlton != null) {
                return singlton;
            }
        }

        return false;
    }

    public getNavigationAccesses(navigationKey: string): string[] {
        const navigationType: NavigationComponentType = this.components.get(navigationKey);

        if (navigationType != null) {
            const accesses: string[] = navigationType.accesses;

            if (accesses && accesses.length > 0) {
                return accesses;
            }
        }

        return [];
    }

    public getNavigationType(navigationKey: string): NavigationComponentType {
        return this.components.get(navigationKey);
    }

    private hasAccessOnTreeNode(node: TreeNode, userAccesses: string[]): boolean {
        if (userAccesses && userAccesses.length >= 0) {
            const accesses: string[] = this.getNavigationAccesses(node.navigation);

            if (!node.children && (!accesses || accesses.length === 0)) {
                return true;
            }

            if (node.children && node.children.length > 0) {
                node.children = node.children.filter((child: TreeNode) => {
                    return this.hasAccessOnTreeNode(child, userAccesses);
                });

                return node.children.length > 0;
            } else {
                for (let index = 0; index < accesses.length; index++) {
                    if (userAccesses.indexOf(accesses[index]) > 0) {
                        return true;
                    }
                }
            }
        }

        return false;
    }

    public getTreeNodes(userAccesses: string[]): TreeNode[] {
        if (userAccesses && userAccesses.length >= 0) {
            return TREE_STRUCTURE.filter((node: TreeNode) => {
                return this.hasAccessOnTreeNode(node, userAccesses);
            });
        }

        return null;
    }
}
