import { Component, OnInit, EventEmitter, OnDestroy } from '@angular/core';
import { NavigationComponentType } from 'src/app/tree.service';
import { AuthenticationService } from 'src/app/core/authentication/services/authentication.service';
import { NavigationService } from 'src/app/core/navigation/services/navigation.service';
import { NavigationParams } from 'src/app/core/common/types/NavigationParams.type';
import { FormGroup, Validators, FormBuilder, AbstractControl } from '@angular/forms';
import { DialogService } from 'src/app/core/common/services/dialog.service';
import { ReferenceService } from '../../../reference.service';
import { ISystemRole, IComboSystemAccess } from '../../admin-model';
import { AdminRolesService } from '../roles.service';
import { DataServiceError } from 'src/app/core/common/http/HttpModel';

@Component({
  selector: 'awwAdminAddRole',
  templateUrl: './add-role.component.html',
  styleUrls: ['./add-role.component.css']
})
export class AddRoleComponent implements OnInit, OnDestroy {

  constructor(private auth: AuthenticationService,
    private nav: NavigationService,
    private params: NavigationParams,
    private roleserv: AdminRolesService,
    private table: ReferenceService,
    private dialog: DialogService,
    private fb: FormBuilder) {

    if (false === auth.isUserInRole(this.type.accesses)) {
      throw new Error('Unauthorized navigation request.');
    }
  }

  private type: NavigationComponentType = AddRoleComponent.defineNavigation();

  processingEvent: EventEmitter<boolean> = new EventEmitter();

  errorsEvent: EventEmitter<DataServiceError[]> = new EventEmitter();

  resultEvent: EventEmitter<any> = new EventEmitter();

  isProcessed = false;

  isProcessing = false;

  accesses: IComboSystemAccess[] = [];

  accessesGranted: string[] = [];

  errors: string[] = [];

  private isDestroyed = false;

  private defaultFormValue: ISystemRole;

  systemRoleForm: FormGroup = this.fb.group({
    roleCode: ['', Validators.required],
    roleDescription: ['', Validators.required]
  });

  public static defineNavigation(): NavigationComponentType {
    return {
      component: AddRoleComponent,
      linkName: 'New',
      tabTitle: 'New System Role',
      windowTitle: 'System Roles > New System Role',
      windowClose: true,
      singleton: false,
      icon: 'add',
      accesses: ['admin.role.create']
    };
  }

  ngOnInit() {
    this.processingEvent.subscribe(isProcessing => this.isProcessing = isProcessing);

    this.errorsEvent.subscribe(errors => {
      this.errors = [];

      errors.forEach(error => {
        const fcontrol: AbstractControl = this.systemRoleForm.get(error.path);

        if (fcontrol) {
          fcontrol.setErrors({ serverError: error.message });
        } else {
          this.errors.push(error.message);
        }

        if (this.errors.length === 0) {
          this.errors.push('The document has errors, please check the highlighted document fields.');
        }
      });
    });

    this.resultEvent.subscribe(result => {
      this.onSubmitResult(result);

      this.errors = [];
    });

    this.defaultFormValue = this.getDefaultFormValue();

    this.resetForm(false);

    this.refreshAccesses();
  }

  ngOnDestroy() {
    this.isDestroyed = true;
  }

  private getDefaultFormValue(): ISystemRole {
    const value: ISystemRole = {
      roleCode: '',
      roleDescription: '',
      accesses: []
    };

    return value;
  }

  ctl(path: string) { return this.systemRoleForm.get(path); }

  err(path: string) {
    try {
      const errors: any = this.ctl(path).errors;

      if (errors) {
        if (errors.serverError) {
          return errors.serverError;
        }

        if (errors.required === true) {
          return 'Required';
        }

        if (errors.email) {
          return 'Not a valid e-mail address';
        }
      }
    } catch (e) {
    }

    return '';
  }

  iserr(path: string) {
    return this.ctl(path).invalid && (this.ctl(path).touched || this.ctl(path).dirty || this.ctl(path).errors.serverError);
  }

  private onSubmitResult(result: any): void {
    if (result.data.roleCode.length > 0) {
      this.isProcessed = true;
    } else {
      this.isProcessed = false;
    }
  }

  submitForm(): void {
    this.markFormGroupTouched(this.systemRoleForm);

    this.syncAccesses();

    if (!this.systemRoleForm.valid) {
      if (this.errors.length === 0) {
        this.errors.push('The document has errors, please check the highlighted document fields.');
      }

      return;
    }

    this.dialog.ask(
      'Do you want to verify and validate this form?',
      'Confirmation')
      .subscribe((decision: boolean) => {
        if (decision) {
          const systemRole: ISystemRole = this.prepareData(this.systemRoleForm.value);

          systemRole.accesses = this.accessesGranted;

          this.roleserv.createSystemRole(systemRole, this.processingEvent, this.resultEvent, this.errorsEvent);
        }
      });
  }

  resetForm(isShowDialog: boolean = true): void {
    if (isShowDialog) {
      this.dialog.ask(
        'Do you want to reset this form?',
        'Confirmation')
        .subscribe((decision: boolean) => {
          if (decision) {
            if (this.defaultFormValue) {
              this.systemRoleForm.reset(this.defaultFormValue);
            } else {
              this.systemRoleForm.reset();
            }

            this.resetFormStatus();
          }
        });
    } else {
      if (this.defaultFormValue) {
        this.systemRoleForm.reset(this.defaultFormValue);
      } else {
        this.systemRoleForm.reset();
      }

      this.resetFormStatus();
    }
  }

  private resetFormStatus(): void {
    this.systemRoleForm.markAsUntouched();

    this.systemRoleForm.markAsPristine();

    this.errors = [];

    this.syncAccesses();
  }

  private prepareData(data: any): any {
    const preparedData: any = Object.assign({}, data);

    Object.keys(preparedData).forEach(k => preparedData[k] = preparedData[k] === '' ? null : preparedData[k]);

    return preparedData;
  }

  private markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      if (control.controls) {
        this.markFormGroupTouched(control);
      } else {
        control.markAsTouched();
      }
    });
  }

  refreshAccesses(): void {
    if (this.isDestroyed) { return; }

    this.roleserv.getAccesses().subscribe((result: IComboSystemAccess[]) => {
      if (result) {
        this.accesses = result;
      }

      this.syncAccesses();
    }, (errors: DataServiceError[]) => {
      this.syncAccesses();

      setTimeout(() => this.refreshAccesses(), 5000);
    });
  }

  toggleAccess(access: IComboSystemAccess, remove: boolean): void {
    if (!remove) {
      this.accessesGranted.push(access.accessCode);
    } else {
      this.accessesGranted = this.accessesGranted.filter((value) => {
        return value !== access.accessCode;
      });
    }
  }

  isGranted(access: IComboSystemAccess): boolean {
    return -1 !== this.accessesGranted.indexOf(access.accessCode, 0);
  }

  private syncAccesses(): void {
    this.accessesGranted = this.accessesGranted.filter((value: string) => {
      return this.accesses.filter(access => {
        return access.accessCode === value;
      }).length > 0;
    });
  }
}
