
import { AuthenticationService } from 'src/app/core/authentication/services/authentication.service';

export enum FormMode {
	NEW = 'NEW',
	EDIT = 'EDIT',
	VIEW = 'VIEW'
}

export interface FormAccess {
	new:string;
	edit:string;
	view:string;
}

export abstract class UNBaseFormComponent {

	// mode of the form either New, Edit, or View mode
	private _mode:FormMode;

	// persistent id of of any record of Edit and View mode.
	private _id:string;

	/**
	 * Default constructor
	 * @param NavParams - navigation paramters passed.
	 */
	constructor(navParams:any, auth:AuthenticationService, access:FormAccess) {

		let hasPermissions:boolean = false;

		if(!navParams) {

			this._mode = FormMode.NEW;
		}
		else {

			this._mode = (navParams.mode == 'edit') ? FormMode.EDIT : FormMode.VIEW;
			this._id = navParams.id;
		}

		// process the user access
		switch (this.Mode) {

			case FormMode.NEW:
				hasPermissions = auth.isUserInRole([access.new]);
				break;

			case FormMode.EDIT:
				hasPermissions = auth.isUserInRole([access.edit]);
				break;

			case FormMode.VIEW:
				hasPermissions = auth.isUserInRole([access.view]);
				break;
			
			default:
				// code...
				break;
		}

		// if the user does not have the necessary access
		if(!hasPermissions)
			throw new Error('Unauthorized navigation request.');
	}

	/**
	 * Retrieve the mode of this form
	 * @return form mode
	 */
	public get Mode():string {
		return this._mode;
	}

	/**
	 * Persistent id of any record to be edited or viewed
	 * @return persistent id.
	 */
	public get RecordId():string {
		return this._id;
	}
}