import { Component, OnInit, ViewChild, EventEmitter } from '@angular/core';
import { NavigationComponentType } from 'src/app/tree.service';
import { AuthenticationService } from 'src/app/core/authentication/services/authentication.service';
import { FindDataRequest, FindResult } from 'src/app/core/common/model/FinderModel';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatTableDataSource, MatSort, MatPaginator } from '@angular/material';  
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-conveyancevetting',
  templateUrl: './conveyancevetting.component.html',
  styleUrls: ['./conveyancevetting.component.css']
})

@Injectable()
export class ConveyancevettingComponent implements OnInit {

  dataSource : any =[];

  displayedColumns: string[] = ['id', 'REQUEST_DATE', 'TRANSPORT_MODE', 'REGISTRATION_NUMBER', 'CONVEYANCE_NAME', 'NATIONALITY', 'QUERY_REQUESTED', 'RESPONSE'];  
  @ViewChild(DatatableComponent) table:  DatatableComponent;
  @ViewChild(MatPaginator) paginator: MatPaginator;  
  @ViewChild(MatSort) sort: MatSort;  
  items : any = [];




  constructor(private http : HttpClient){}


  ngOnInit(): void {
    this.http.get(environment.applicationAPIURL + 'acisvettingrequestconveyance')
    .subscribe((Response : {}[]) => {
      this.dataSource = new MatTableDataSource(Response);
      this.dataSource.paginator = this.paginator;  
      this.dataSource.sort = this.sort;
    });
  }


  applyFilter(filterValue: string) {  
  this.dataSource.filter = filterValue.trim().toLowerCase();  

  if (this.dataSource.paginator) {  
    this.dataSource.paginator.firstPage();  
  }  
  }  

  public static defineNavigation(): NavigationComponentType {
    return {
      component: ConveyancevettingComponent,
      linkName: 'Conveyance Vetting',
      tabTitle: 'Conveyance Vetting',
      windowTitle: 'Acis Vetting Request > Conveyance Vetting',
      windowClose: true,
      singleton: true,
      icon: 'description',
      accesses: ['risk.report.find']
    };
  }

}
