import { NgModule } from '@angular/core';
import { CoreCommonModule } from 'src/app/core/common/core-common.module';
import { AuthenticationModule } from 'src/app/core/authentication/authentication.module';
import { RiskListDetailsComponent } from './riskList-details/riskList-details.component';
import { FindRiskListComponent } from './find-riskList/find-riskList.component';
import { CriteriaRiskListComponent } from './criteria-riskList/criteria-riskList.component';

@NgModule({
  declarations: [
    RiskListDetailsComponent,
    FindRiskListComponent,
    CriteriaRiskListComponent
  ],
  imports: [
    CoreCommonModule,
    AuthenticationModule
  ],
  exports: [],
  entryComponents: [
    RiskListDetailsComponent,
    FindRiskListComponent,
    CriteriaRiskListComponent
  ]
})
export class RiskListModule {
}
