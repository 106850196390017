import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { AuthenticationService } from 'src/app/core/authentication/services/authentication.service';
import { DataServiceError } from 'src/app/core/common/http/HttpModel';
import { HttpUtils } from 'src/app/core/common/http/HttpUtils';
import { FindDataRequest } from 'src/app/core/common/model/FinderModel';
import { IManifest, IWayBill, IContainer } from './cargo-model';

@Injectable({
  providedIn: 'root'
})
export class CargoService {

  private API_PATH = 'api/cargo';

  // default constructor.
  constructor(private auth: AuthenticationService,
    private http: HttpClient, private httpUtils: HttpUtils) {
  }

  private handleError(err: HttpErrorResponse) {
    const errors: DataServiceError[] = [];

    console.log(err);

    if (err.error instanceof ErrorEvent) {
    } else {
      try {
        if (err.status === 403) {
          errors.push({ message: 'Authentication failed, session has expired!', path: '', value: '' });
        }

        if (err.error.status) {
          if (err.error.status.errors && err.error.status.errors.length > 0) {
            err.error.status.errors.forEach((error: DataServiceError) => {
              errors.push(error);
            });
          }
        }
      } catch (e) {
      }

      if (errors!.length === 0) {
        errors.push({ message: 'Unexpected error, please contact your administrator', path: '', value: '' });
      }
    }

    return throwError(errors);
  }

/*------------- API Requests -------------------------------*/

  private getSearchResults<R>(api: string, findDataRequest: FindDataRequest, processingEvent: EventEmitter<boolean>, resultEvent: EventEmitter<R>, errorsEvent: EventEmitter<any[]>): void {
    if (findDataRequest) {
      api = api + `?page=${findDataRequest.page}&pageSize=${findDataRequest.pageSize}`;

      if (!findDataRequest.isDefault) {
        api = api + `&sortColumn=${findDataRequest.sortColumn}&ascendingOrder=${findDataRequest.ascendingOrder}`;
      }
    }

    this.httpUtils.invokeGet<R, any>(api, processingEvent, resultEvent, errorsEvent, this.handleError);
  }

  public getSearchResultsForManifest<R>(findDataRequest: FindDataRequest, processingEvent: EventEmitter<boolean>, resultEvent: EventEmitter<R>, errorsEvent: EventEmitter<any[]>): void {

    // endpoint for this call
    const END_POINT = this.API_PATH + '/find/Manifest';

    this.httpUtils.get(END_POINT, this.getParams(findDataRequest), processingEvent, resultEvent, errorsEvent, this.handleError);

  }

  public findWaybills<R>(findDataRequest: FindDataRequest, processingEvent: EventEmitter<boolean>, resultEvent: EventEmitter<R>, errorsEvent: EventEmitter<any[]>): void {

    // endpoint for this call
    const END_POINT = this.API_PATH + '/find/Waybills';

    this.httpUtils.get(END_POINT, this.getParams(findDataRequest), processingEvent, resultEvent, errorsEvent, this.handleError);

  }


  public getSearchResultsForWaybill<R>(findDataRequest: FindDataRequest, processingEvent: EventEmitter<boolean>, resultEvent: EventEmitter<R>, errorsEvent: EventEmitter<any[]>): void {
    const api = 'api/cargo/find/Waybill';

    this.getSearchResults(api, findDataRequest, processingEvent, resultEvent, errorsEvent);
  }

  public getManifestById(id: string, processingEvent: EventEmitter<boolean>, resultEvent: EventEmitter<IManifest>, errorsEvent: EventEmitter<DataServiceError[]>): void {
    const api: string = 'api/cargo/find/Manifest/byId?fullLoad=true&id=' + id;

    this.httpUtils.invokeGet(api, processingEvent, resultEvent, errorsEvent, this.handleError);
  }

  public getWaybillById(id: string, processingEvent: EventEmitter<boolean>, resultEvent: EventEmitter<IManifest>, errorsEvent: EventEmitter<DataServiceError[]>): void {
    const api: string = 'api/cargo/find/Waybill/byId?id=' + id;

    this.httpUtils.invokeGet(api, processingEvent, resultEvent, errorsEvent, this.handleError);
  }



  /**
   * Send an api request to find Containers in the system
   * @param processingEvent - Event to indicate the api is currently processing request
   * @param resultEvent - Event to notify when api results are available
   * @param errorsEvent - Event to notify when api errors occur
   */
  public findContainers<R>(findDataRequest: FindDataRequest, processingEvent: EventEmitter<boolean>, resultEvent: EventEmitter<R>, errorsEvent: EventEmitter<any[]>): void {

    // endpoint for this call
    const END_POINT = this.API_PATH + '/find/Containers';

    this.httpUtils.get(END_POINT, this.getParams(findDataRequest), processingEvent, resultEvent, errorsEvent, this.handleError);

  }
  public getContainerById(id: string, processingEvent: EventEmitter<boolean>, resultEvent: EventEmitter<any>, errorsEvent: EventEmitter<DataServiceError[]>): void {
    
    const END_POINT = this.API_PATH + `/find/Container/byId?id=${id}`;

    this.httpUtils.invokeGet(END_POINT, processingEvent, resultEvent, errorsEvent, this.handleError);
  }
  /*------------- Lookup Methods -------------------------------*/

  /**
   * Process FindDataRequest parameters for search
   * @param req - Finder request
   */
  private getParams(req: FindDataRequest): any {
    const searchRequest: any = Object.assign({}, req);
    searchRequest.criteria = JSON.stringify(req.criteria);
    return searchRequest;
  }


}
