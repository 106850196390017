import { NgModule } from '@angular/core';
import { CoreCommonModule } from 'src/app/core/common/core-common.module';
import { FindGroupsComponent } from './find-groups/find-groups.component';
import { AuthenticationModule } from 'src/app/core/authentication/authentication.module';
import { AddGroupComponent } from './add-group/add-group.component';
import { UpdateGroupComponent } from './update-group/update-group.component';
import { ViewGroupComponent } from './view-group/view-group.component';

@NgModule({
  imports: [
    CoreCommonModule,
    AuthenticationModule
  ],
  declarations: [
    FindGroupsComponent,
    AddGroupComponent,
    UpdateGroupComponent,
    ViewGroupComponent
  ],
  entryComponents: [
    FindGroupsComponent,
    AddGroupComponent,
    UpdateGroupComponent,
    ViewGroupComponent
  ]
})
export class GroupsModule { }
