import { OnInit, Component } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';


@Component({
  selector: 'awwUserInfo',
  inputs: ['id'],
  template: '{{value}}'
})
export class UserInfoComponent implements OnInit {

  private id: string;

  value: string;

  constructor(private auth: AuthenticationService) {
  }

  ngOnInit() {
    switch (this.id) {
      case 'username':
        this.value = this.auth.getUsername();
        break;
      case 'name':
        this.value = this.auth.getFullName();
        break;
      case 'title':
        this.value = this.auth.getFunctionalTitle();
        break;
    }
  }
}
