import { NgModule } from '@angular/core';
import { CoreCommonModule } from 'src/app/core/common/core-common.module';
import { AuthenticationModule } from 'src/app/core/authentication/authentication.module';
import { FindWaybillsComponent } from './find-waybills/find-waybills.component';
import { ViewWaybillComponent } from './view-waybill/view-waybill.component';

@NgModule({
  declarations: [
    FindWaybillsComponent,
    ViewWaybillComponent
  ],
  imports: [
    CoreCommonModule,
    AuthenticationModule
  ],
  exports: [

  ],
  entryComponents: [
    FindWaybillsComponent,
    ViewWaybillComponent
  ]
})
export class WaybillModule { }
