import { Injectable } from '@angular/core';
import { DesktopDialogComponent } from '../components/desktop-dialog/desktop-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { DesktopAlertComponent } from '../components/desktop-dialog/desktop-alert.component';
import { DesktopSearchComponent } from '../components/desktop-dialog/desktop-search.component';
import { PasswordChangeDialogComponent } from 'src/app/core/common/components/desktop-dialog/password-change.component';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor(public dialog: MatDialog) {
  }

  public ask(message: string, title: string = 'Confirmation Required', width: number = 400): Observable<any> {
    const dialogInstance = this.dialog.open(DesktopDialogComponent, {
      width: width + 'px',
      data: { decision: true, title: title, message: message }
    });

    return dialogInstance.afterClosed();
  }

  public alert(message: string, title: string = 'Notification', width: number = 400): Observable<any> {
    const dialogInstance = this.dialog.open(DesktopAlertComponent, {
      width: width + 'px',
      data: { title: title, message: message }
    });

    return dialogInstance.afterClosed();
  }

  /**
   * Open a finder window to perform a search
   * @param title - Title of the search form
   * @param  searchFields - List of filter fields to be used for search
   * @param  width - width of the search dialog
   */
  public finder(title: string, searchFields: any[], width: number = 640): Observable<any> {

      const dialogInstance = this.dialog.open(DesktopSearchComponent, {
        width: width + 'px',
        data: { title: title, searchFields: searchFields}
      });

      return dialogInstance.afterClosed();
  }

  /**
   * Open a dialog to allow the user to reset a password
   * @param title - Title of the password form
   * @param  width - width of the search dialog
   */
  public passwordReset(title: string, width: number = 340): Observable<any> {

      const dialogInstance = this.dialog.open(PasswordChangeDialogComponent, {
        width: width + 'px',
        data: { title: title}
      });

      return dialogInstance.afterClosed();
  }
}
