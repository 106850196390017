export class RiskRule {
	// rank of the rule determins precedence
	ruleRank = 0;
	// indicates whether the rule is a LIST of a FIELD
	ruleType: string;
	// the section of the manifest to target with this rule
	ruleTarget: string;
	// the field of the rule target to query
	ruleElementCode: string;
	// the human readable name of the rule field
	field_HumanName: string;
	// the operator to apply to this rule e.g. LT <, LTE<=, GT > etc
	ruleOperator: string;
	// the value of this rule
	ruleFieldValue: string;
	// the applicable conjunction of this rule AND, OR, END etc.
	ruleConjunction: string;
}
