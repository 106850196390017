import { AuthenticationService } from '../../authentication/services/authentication.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { retry, catchError } from 'rxjs/operators';
import { EventEmitter, Injectable } from '@angular/core';

@Injectable()
export class HttpUtils {
  constructor(private auth: AuthenticationService, private http: HttpClient) { }

  private getHttpOptions() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.auth.getToken()
      }),
      withCredentials: false
    };
  }

  public invokePost<B, R, E>(api: string, body: B, processingEvent: EventEmitter<boolean>, resultEvent: EventEmitter<R>, errorsEvent: EventEmitter<E>, httpErrorHandler: any): void {
    processingEvent.emit(true);

    const httpOptions = this.getHttpOptions();

    this.http.post<R>(environment.applicationServerURL + api, body, httpOptions)
      .pipe(
        retry(3),
        catchError(httpErrorHandler)
      ).subscribe((result: R) => {
        processingEvent.emit(false);
        resultEvent.emit(result);
      }, (errors: E) => {
        processingEvent.emit(false);
        errorsEvent.emit(errors);
      });
  }

  public invokePut<B, R, E>(api: string, body: B, processingEvent: EventEmitter<boolean>, resultEvent: EventEmitter<R>, errorsEvent: EventEmitter<E>, httpErrorHandler: any): void {
    processingEvent.emit(true);

    const httpOptions = this.getHttpOptions();

    console.log(JSON.stringify(body));

    this.http.put<R>(environment.applicationServerURL + api, body, httpOptions)
      .pipe(
        retry(3),
        catchError(httpErrorHandler)
      ).subscribe((result: R) => {
        processingEvent.emit(false);
        resultEvent.emit(result);
      }, (errors: E) => {
        processingEvent.emit(false);
        errorsEvent.emit(errors);
      });
  }

  public invokeGet<R, E>(api: string, processingEvent: EventEmitter<boolean>, resultEvent: EventEmitter<R>, errorsEvent: EventEmitter<E>, httpErrorHandler: any): void {
    processingEvent.emit(true);

    const httpOptions = this.getHttpOptions();

    this.http.get<R>(environment.applicationServerURL + api, httpOptions)
      .pipe(
        retry(3),
        catchError(httpErrorHandler)
      ).subscribe((result: R) => {
        processingEvent.emit(false);
        resultEvent.emit(result);
      }, (errors: E) => {
        processingEvent.emit(false);
        errorsEvent.emit(errors);
      });
  }

  public invokeGet2<R, E>(api: string, httpErrorHandler: any): Observable<R> {
    const httpOptions = this.getHttpOptions();

    return this.http.get<R>(environment.applicationServerURL + api, httpOptions)
      .pipe(
        retry(3),
        catchError(httpErrorHandler)
      );
  }

  public invokeGet3<R>(api: string): Observable<R> {
    const httpOptions = this.getHttpOptions();

    return this.http.get<R>(environment.applicationServerURL + api, httpOptions)
      .pipe(
        retry(3)
      );
  }

  public get<R, E>(endpoint: string, params: any, processingEvent: EventEmitter<boolean>,
    resultEvent: EventEmitter<R>, errorsEvent: EventEmitter<any>, httpErrorHandler: any): void {

    const options = this.getHttpOptions();
    (options as any).params = {...params};

    processingEvent.emit(true);

    this.http.get<R>(environment.applicationServerURL + endpoint, options)
      .pipe(
        retry(3),
        catchError(httpErrorHandler)
      ).subscribe((result: R) => {
        processingEvent.emit(false);
        resultEvent.emit(result);
      }, (errors: E) => {
        processingEvent.emit(false);
        errorsEvent.emit(errors);
      });
  }

  public invokeDelete<R>(api: string, httpErrorHandler: any): Observable<R> {
    const httpOptions = this.getHttpOptions();

    return this.http.delete<R>(environment.applicationServerURL + api, httpOptions)
      .pipe(
        retry(3),
        catchError(httpErrorHandler)
      );
  }

}
