import { Component, Input } from '@angular/core';
import { NestedTreeControl } from '@angular/cdk/tree';
import { of } from 'rxjs';
import { NodeTreeRiskReportModel } from '../../../shared/nodetree.model';



@Component({
  selector: 'app-man-tree',
  templateUrl: './manifest-tree.component.html',
  styleUrls: ['./manifest-tree.component.css']
})
export class ManifestTreeComponent {
  @Input() nestedDataSource: NodeTreeRiskReportModel[];
  getChildren = (node: NodeTreeRiskReportModel) => of(node.node);
  nestedTreeControl = new NestedTreeControl(this.getChildren);
  hasChild(_: number, node: NodeTreeRiskReportModel) {
    
    return node.node != null && node.node.length > 0;
  }
}
