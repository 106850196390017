import { NgModule } from '@angular/core';
import { CoreCommonModule } from 'src/app/core/common/core-common.module';
import { FindContainerComponent } from './find-container/find-container.component';
import { CargoContainerDetailsComponent } from './details-container/details-container.component';
import { AuthenticationModule } from 'src/app/core/authentication/authentication.module';
import { FormUtils } from 'src/app/core/common/services/forms.service';

@NgModule({
  declarations: [
    FindContainerComponent,
    CargoContainerDetailsComponent
  ],
  imports: [
    CoreCommonModule,
    AuthenticationModule
  ],
  exports: [

  ],
  entryComponents: [
    FindContainerComponent,
    CargoContainerDetailsComponent
  ]
})
export class ContainerModule { }
