import { Directive, HostListener, OnInit, ElementRef } from '@angular/core';
import { NavigationService } from 'src/app/core/navigation/services/navigation.service';

@Directive({
  selector: '[awwTabSize]'
})
export class MaxMinTabDirective implements OnInit {

  constructor(private nav: NavigationService, element: ElementRef) {
    element.nativeElement.style = 'cursor: pointer;';
  }

  ngOnInit() {

  }

  @HostListener('click', ['$event'])
  clickEvent(event) {
    this.nav.toggleTabSize();
  }
}
