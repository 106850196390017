import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'awwDialogPasswordChange',
    templateUrl: 'dialog-password-change.component.html',
    styleUrls: ['./desktop-dialog.component.css', 'dialog-password-change.css']
})
export class PasswordChangeDialogComponent {

	// new password
	public newPassword:string = '';

	// password confirmation.
	public passwordConfirm:string;

    public enableActions:boolean;

	// default constructor.
    constructor(public dialogRef: MatDialogRef<PasswordChangeDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: { message: string, title: string }) {
    }

    /**
     * Perform the password change
     */
    public doPasswordChange():void {
        this.dialogRef.close(this.newPassword);
    }

    /**
     * Close Dialog
     */
    closeDialog(): void {
        this.dialogRef.close();
    }
}
