import { NgModule } from '@angular/core';
import { CoreCommonModule } from 'src/app/core/common/core-common.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ChartsModule } from 'ng2-charts';
import { Component,AfterViewInit,ElementRef } from '@angular/core';
import { RiskalertsdispositionComponent } from './riskalertsdisposition/riskalertsdisposition.component';
import { FindalertsdispositionComponent } from './findalertsdisposition/findalertsdisposition.component';
import { ContainervettingComponent } from './containervetting/containervetting.component';
import { ConveyancevettingComponent } from './conveyancevetting/conveyancevetting.component';
import { PassengervettingComponent } from './passengervetting/passengervetting.component'; 
import { VehiclevettingComponent } from './vehiclevetting/vehiclevetting.component';
import { GoodsvettingComponent } from './goodsvetting/goodsvetting.component';
import { SeizureinformationComponent } from './seizureinformation/seizureinformation.component';
import { FindseizureinformationComponent } from './findseizureinformation/findseizureinformation.component';
import { Referencetableshs6codesComponent } from './referencetableshs6codes/referencetableshs6codes.component';
import { ReferencetablescountriesComponent } from './referencetablescountries/referencetablescountries.component';

import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';


@NgModule({
  imports: [
    CoreCommonModule,
    ChartsModule,
    HttpClientModule,
    RouterModule,
  ],
  exports: [

  ],
  declarations: [
    DashboardComponent,
    RiskalertsdispositionComponent,
    FindalertsdispositionComponent,
    ContainervettingComponent,
    ConveyancevettingComponent,
    PassengervettingComponent,
    VehiclevettingComponent,
    GoodsvettingComponent,
    SeizureinformationComponent,
    FindseizureinformationComponent,
    Referencetableshs6codesComponent,
    ReferencetablescountriesComponent,
  ],
  entryComponents: [
    DashboardComponent,
    RiskalertsdispositionComponent,
    FindalertsdispositionComponent,
    PassengervettingComponent,
    ContainervettingComponent,
    ConveyancevettingComponent,
    VehiclevettingComponent,
    GoodsvettingComponent,
    SeizureinformationComponent,
    Referencetableshs6codesComponent,
    ReferencetablescountriesComponent,
  ]
})


export class ReportingModule {

}

