import { TreeNode } from './tree.service';

export const TREE_STARTUP: string[] = [
    // 'Cargo/Manifest/Find'
];

export const TREE_STRUCTURE: TreeNode[] = [
    { navigation: 'Profile' },
    {
        navigation: 'Cargo',
        children: [
            {
                navigation: 'Cargo/Manifest',
                children: [
                    { navigation: 'Cargo/Manifest/Find' }
                ]
            },
            {
                navigation: 'Cargo/Bill',
                children: [
                    { navigation: 'Cargo/Bill/Find' }
                ]
            },
            {
                navigation: 'Cargo/Container',
                children: [
                    { navigation: 'Cargo/Container/Find' }
                ]
            },
            {
                navigation: 'Cargo/XML',
                children: [
                    { navigation: 'Cargo/XML/FullCargo' },
                    { navigation: 'Cargo/XML/Degroupage' },
                    { navigation: 'Cargo/XML/AppendWaybill' }
                ]
            }
        ]
    },
    {
      navigation: 'Risk',
      children: [
          {
              navigation: 'Risk/List',
              children: [
                { navigation: 'Risk/List/New'},
                { navigation: 'Risk/List/Find'}
              ]
          },
          {
              navigation: 'Risk/Criteria',
              children: [
                { navigation: 'Risk/Criteria/New'},
                { navigation: 'Risk/Criteria/Find'}
              ]
          },
          {
              navigation: 'Risk/Report',
              children: [
                { navigation: 'Risk/Report/Find'}
                //{ navigation: 'Risk/Report/View'}
              ]
          },
          {
              navigation: 'Risk/Notification',
              children: [
                { navigation: 'Risk/Notification/New'},
                { navigation: 'Risk/Notification/Find'}
              ]
          },
          {
                navigation: 'Risk/RiskCategory',
                children: [
                    { navigation: 'Risk/RiskCategory/View', params: { name: 'RiskCategories', viewOnlyMode: true } },
                    { navigation: 'Risk/RiskCategory/Edit', params: { name: 'RiskCategories', viewOnlyMode: false } }
                ]
          }
      ]
  },
    {
        navigation: 'Reference',
        children: [
            {
                /** ----- Disable Edit for National Reference tables (Edited through integrations with Member states) ------ */
                navigation: 'Reference/National',
                children: [
                    {
                        navigation: 'Reference/National/AttachedDocuments',
                        children: [
                            { navigation: 'Reference/National/View/AttachedDocuments', params: { name: 'AttachedDocuments', viewOnlyMode: true } }/* ,
                            { navigation: 'Reference/National/Edit/AttachedDocuments', params: { name: 'AttachedDocuments', viewOnlyMode: false } } */
                        ]
                    },
                    {
                        navigation: 'Reference/National/CarrierAgents',
                        children: [
                            { navigation: 'Reference/National/View/CarrierAgents', params: { name: 'CarrierAgents', viewOnlyMode: true } }/* ,
                            { navigation: 'Reference/National/Edit/CarrierAgents', params: { name: 'CarrierAgents', viewOnlyMode: false } } */
                        ]
                    },
                    {
                        navigation: 'Reference/National/CustomsOffices',
                        children: [
                            { navigation: 'Reference/National/View/CustomsOffices', params: { name: 'CustomsOffices', viewOnlyMode: true } }/* ,
                            { navigation: 'Reference/National/Edit/CustomsOffices', params: { name: 'CustomsOffices', viewOnlyMode: false } } */
                        ]
                    },
                    {
                        navigation: 'Reference/National/NationalAirports',
                        children: [
                            { navigation: 'Reference/National/View/NationalAirports', params: { name: 'NationalAirports', viewOnlyMode: true } }/* ,
                            { navigation: 'Reference/National/Edit/NationalAirports', params: { name: 'NationalAirports', viewOnlyMode: false } } */
                        ]
                    },
                    {
                        navigation: 'Reference/National/Traders',
                        children: [
                            { navigation: 'Reference/National/View/Traders', params: { name: 'Traders', viewOnlyMode: true } }/* ,
                            { navigation: 'Reference/National/Edit/Traders', params: { name: 'Traders', viewOnlyMode: false } } */
                        ]
                    },
                    {
                        navigation: 'Reference/National/TransitSheds',
                        children: [
                            { navigation: 'Reference/National/View/TransitSheds', params: { name: 'TransitSheds', viewOnlyMode: true } }/* ,
                            { navigation: 'Reference/National/Edit/TransitSheds', params: { name: 'TransitSheds', viewOnlyMode: false } } */
                        ]
                    }
                ]
            },
            {
                navigation: 'Reference/International',
                children: [
                    {
                        navigation: 'Reference/International/Carriers',
                        children: [
                            { navigation: 'Reference/International/View/Carriers', params: { name: 'Carriers', viewOnlyMode: true } },
                            { navigation: 'Reference/International/Edit/Carriers', params: { name: 'Carriers', viewOnlyMode: false } }
                        ]
                    },
                    {
                        navigation: 'Reference/International/ContainerIndicators',
                        children: [
                            { navigation: 'Reference/International/View/ContainerIndicators', params: { name: 'ContainerIndicators', viewOnlyMode: true } },
                            { navigation: 'Reference/International/Edit/ContainerIndicators', params: { name: 'ContainerIndicators', viewOnlyMode: false } }
                        ]
                    },
                    {
                        navigation: 'Reference/International/ContainerTypes',
                        children: [
                            { navigation: 'Reference/International/View/ContainerTypes', params: { name: 'ContainerTypes', viewOnlyMode: true } },
                            { navigation: 'Reference/International/Edit/ContainerTypes', params: { name: 'ContainerTypes', viewOnlyMode: false } }
                        ]
                    },
                    {
                        navigation: 'Reference/International/Countries',
                        children: [
                            { navigation: 'Reference/International/View/Countries', params: { name: 'Countries', viewOnlyMode: true } },
                            { navigation: 'Reference/International/Edit/Countries', params: { name: 'Countries', viewOnlyMode: false } }
                        ]
                    },
                    {
                        navigation: 'Reference/International/Currencies',
                        children: [
                            { navigation: 'Reference/International/View/Currencies', params: { name: 'Currencies', viewOnlyMode: true } },
                            { navigation: 'Reference/International/Edit/Currencies', params: { name: 'Currencies', viewOnlyMode: false } }
                        ]
                    },
                    {
                        navigation: 'Reference/International/DangerousGoods',
                        children: [
                            { navigation: 'Reference/International/View/DangerousGoods', params: { name: 'DangerousGoods', viewOnlyMode: true } },
                            { navigation: 'Reference/International/Edit/DangerousGoods', params: { name: 'DangerousGoods', viewOnlyMode: false } }
                        ]
                    },
                    {
                        navigation: 'Reference/International/FreightCosts',
                        children: [
                            { navigation: 'Reference/International/View/FreightCosts', params: { name: 'FreightCosts', viewOnlyMode: true } },
                            { navigation: 'Reference/International/Edit/FreightCosts', params: { name: 'FreightCosts', viewOnlyMode: false } }
                        ]
                    },
                    {
                        navigation: 'Reference/International/HS6Codes',
                        children: [
                            { navigation: 'Reference/International/View/HS6Codes', params: { name: 'HS6Codes', viewOnlyMode: true } },
                            { navigation: 'Reference/International/Edit/HS6Codes', params: { name: 'HS6Codes', viewOnlyMode: false } }
                        ]
                    },
                    {
                        navigation: 'Reference/International/IATAAirports',
                        children: [
                            { navigation: 'Reference/International/View/IATAAirports', params: { name: 'IATAAirports', viewOnlyMode: true } },
                            { navigation: 'Reference/International/Edit/IATAAirports', params: { name: 'IATAAirports', viewOnlyMode: false } }
                        ]
                    },
                    {
                        navigation: 'Reference/International/PackageTypes',
                        children: [
                            { navigation: 'Reference/International/View/PackageTypes', params: { name: 'PackageTypes', viewOnlyMode: true } },
                            { navigation: 'Reference/International/Edit/PackageTypes', params: { name: 'PackageTypes', viewOnlyMode: false } }
                        ]
                    },
                    {
                        navigation: 'Reference/International/SealOperators',
                        children: [
                            { navigation: 'Reference/International/View/SealOperators', params: { name: 'SealOperators', viewOnlyMode: true } },
                            { navigation: 'Reference/International/Edit/SealOperators', params: { name: 'SealOperators', viewOnlyMode: false } }
                        ]
                    },
                    {
                        navigation: 'Reference/International/TransportDocuments',
                        children: [
                            { navigation: 'Reference/International/View/TransportDocuments', params: { name: 'TransportDocuments', viewOnlyMode: true } },
                            { navigation: 'Reference/International/Edit/TransportDocuments', params: { name: 'TransportDocuments', viewOnlyMode: false } }
                        ]
                    },
                    {
                        navigation: 'Reference/International/TransportDocumentNatures',
                        children: [
                            { navigation: 'Reference/International/View/TransportDocumentNatures', params: { name: 'TransportDocumentNatures', viewOnlyMode: true } },
                            { navigation: 'Reference/International/Edit/TransportDocumentNatures', params: { name: 'TransportDocumentNatures', viewOnlyMode: false } }
                        ]
                    },
                    {
                        navigation: 'Reference/International/TransportModes',
                        children: [
                            { navigation: 'Reference/International/View/TransportModes', params: { name: 'TransportModes', viewOnlyMode: true } },
                            { navigation: 'Reference/International/Edit/TransportModes', params: { name: 'TransportModes', viewOnlyMode: false } }
                        ]
                    },
                    {
                        navigation: 'Reference/International/Unlocodes',
                        children: [
                            { navigation: 'Reference/International/View/Unlocodes', params: { name: 'Unlocodes', viewOnlyMode: true } },
                            { navigation: 'Reference/International/Edit/Unlocodes', params: { name: 'Unlocodes', viewOnlyMode: false } }
                        ]
                    },
                    {
                        navigation: 'Reference/International/VehicleBrands',
                        children: [
                            { navigation: 'Reference/International/View/VehicleBrands', params: { name: 'VehicleBrands', viewOnlyMode: true } },
                            { navigation: 'Reference/International/Edit/VehicleBrands', params: { name: 'VehicleBrands', viewOnlyMode: false } }
                        ]
                    },
                    {
                        navigation: 'Reference/International/VehicleDSP',
                        children: [
                            { navigation: 'Reference/International/View/VehicleDSP', params: { name: 'VehicleDSP', viewOnlyMode: true } },
                            { navigation: 'Reference/International/Edit/VehicleDSP', params: { name: 'VehicleDSP', viewOnlyMode: false } }
                        ]
                    },
                    {
                        navigation: 'Reference/International/VehicleEnergyTypes',
                        children: [
                            { navigation: 'Reference/International/View/VehicleEnergyTypes', params: { name: 'VehicleEnergyTypes', viewOnlyMode: true } },
                            { navigation: 'Reference/International/Edit/VehicleEnergyTypes', params: { name: 'VehicleEnergyTypes', viewOnlyMode: false } }
                        ]
                    },
                    {
                        navigation: 'Reference/International/VehicleModels',
                        children: [
                            { navigation: 'Reference/International/View/VehicleModels', params: { name: 'VehicleModels', viewOnlyMode: true } },
                            { navigation: 'Reference/International/Edit/VehicleModels', params: { name: 'VehicleModels', viewOnlyMode: false } }
                        ]
                    }
                ]
            }
        ]
        

    },
    {
        navigation: 'Admin',
        children: [
            {
                navigation: 'Admin/Management',
                children: [
                    {
                        navigation: 'Admin/Management/Group',
                        children: [
                            { navigation: 'Admin/Management/Group/New' },
                            { navigation: 'Admin/Management/Group/Find' }
                        ]
                    },
                    {
                        navigation: 'Admin/Management/User',
                        children: [
                            { navigation: 'Admin/Management/User/New' },
                            { navigation: 'Admin/Management/User/Find' }
                        ]
                    }
                ]
            },
            {
                navigation: 'Admin/Security',
                children: [
                    {
                        navigation: 'Admin/Security/Access',
                        children: [
                            { navigation: 'Admin/Security/Access/Find' }
                        ]
                    },
                    {
                        navigation: 'Admin/Security/Role',
                        children: [
                            { navigation: 'Admin/Security/Role/New' },
                            { navigation: 'Admin/Security/Role/Find' }
                        ]
                    }
                ]
            }
        ]
    },
    {
        navigation: 'Reporting',
        children: [
            {
                navigation: 'Reporting/Dashboard' 
            },
            {   navigation: 'Reporting/RiskAlerts',
                children: [
                    { navigation: 'Reporting/RiskAlerts/RiskAlertsDisposition'},
                    { navigation: 'Reporting/RiskAlerts/FindAlertsDisposition'}
                ] 
            },
            {   navigation: 'Reporting/AcisVetting',
                children: [
                    { navigation: 'Reporting/AcisVetting/ContainerVetting'},
                    { navigation: 'Reporting/AcisVetting/PassengerVetting'},
                    { navigation: 'Reporting/AcisVetting/ConveyanceVetting'},
                    { navigation: 'Reporting/AcisVetting/VehicleVetting'},
                    { navigation: 'Reporting/AcisVetting/GoodsVetting'}
                ] 
            },
            { navigation: 'Reporting/SeizureInformation'},
            { navigation: 'Reporting/ReferenceTables',
                children: [
                    { navigation: 'Reporting/ReferenceTables/HS6Codes'},
                    { navigation: 'Reporting/ReferenceTables/Countries'},
                ] 
            }            
        ]
    },


    // config
    {
        navigation: 'Config',
        children: [
            {
                navigation: 'Config/Receivers',
                children: [
                    { navigation: 'Config/Receivers/New' },
                    { navigation: 'Config/Receivers/Find' }
                ]
            }
        ]
    }



];
