import { NgModule } from '@angular/core';
import { CoreCommonModule } from 'src/app/core/common/core-common.module';
import { AuthenticationModule } from 'src/app/core/authentication/authentication.module';
import { FindReportRecipientComponent } from './find-recipients/find-recipients.component';
import { RecipientDetailsComponent } from './recipient-details/recipient-details.component';

@NgModule({
  declarations: [
    FindReportRecipientComponent,
    RecipientDetailsComponent
  ],
  imports: [
    CoreCommonModule,
    AuthenticationModule
  ],
  exports: [],
  entryComponents: [
    FindReportRecipientComponent,
    RecipientDetailsComponent
  ]
})
export class RiskMailingListModule {
}