import { Component, OnInit, EventEmitter, OnDestroy } from '@angular/core';
import { NavigationComponentType } from 'src/app/tree.service';
import { AuthenticationService } from 'src/app/core/authentication/services/authentication.service';
import { NavigationService } from 'src/app/core/navigation/services/navigation.service';
import { NavigationParams } from 'src/app/core/common/types/NavigationParams.type';
import { FormGroup, Validators, FormBuilder, AbstractControl, FormArray } from '@angular/forms';
import { DialogService } from 'src/app/core/common/services/dialog.service';
import { ReferenceService } from '../../../reference.service';
import { ICountry } from 'src/app/modules/reference-model';
import { IComboUserGroup, IComboSystemRole, IUserAccount } from '../../admin-model';
import { AdminUsersService } from '../users.service';
import { DataServiceError } from 'src/app/core/common/http/HttpModel';

@Component({
  selector: 'awwAdminUpdateUser',
  templateUrl: './update-user.component.html',
  styleUrls: ['./update-user.component.css']
})
export class UpdateUserComponent implements OnInit, OnDestroy {

  constructor(
    private auth: AuthenticationService,
    private nav: NavigationService,
    private params: NavigationParams,
    private users: AdminUsersService,
    private table: ReferenceService,
    private dialog: DialogService,
    private fb: FormBuilder) {

    if (false === auth.isUserInRole(this.type.accesses)) {
      throw new Error('Unauthorized navigation request.');
    }
  }

  get properties() {
    return this.userAccountForm.get('properties') as FormArray;
  }

  private type: NavigationComponentType = UpdateUserComponent.defineNavigation();

  processingEvent: EventEmitter<boolean> = new EventEmitter();

  errorsEvent: EventEmitter<DataServiceError[]> = new EventEmitter();

  resultEvent: EventEmitter<any> = new EventEmitter();

  errors2Event: EventEmitter<DataServiceError[]> = new EventEmitter();

  result2Event: EventEmitter<any> = new EventEmitter();

  isProcessed = false;

  isProcessing = false;

  initialFormData: any;

  username: string;

  groups: IComboUserGroup[] = [];

  countries: ICountry[] = [];

  roles: IComboSystemRole[] = [];

  rolesGranted: string[] = [];

  errors: string[] = [];

  // Special Case [Admin]
  private isAdmin = false;

  private isDestroyed = false;

  userAccountForm: FormGroup = this.fb.group({
    username: ['', Validators.required],
    password: [''],
    passwordConfirmation: [''],
    profile: this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      functionalTitle: ['', Validators.required],
      mobile: [''],
      telephone: [''],
      telephoneExtension: [''],
      emailAddress: ['', Validators.email]
    }),
    address: this.fb.group({
      addressLine1: [''],
      addressLine2: [''],
      addressLine3: [''],
      building: [''],
      city: ['', Validators.required],
      countryCode: ['', Validators.required],
      region: [''],
      street: [''],
      unit: ['']
    }),
    groupCode: ['', Validators.required],
    properties: this.fb.array([]),
    active: [true, Validators.required],
    clientAccess: [true, Validators.required]
  });

  public static defineNavigation(): NavigationComponentType {
    return {
      component: UpdateUserComponent,
      linkName: 'Update',
      tabTitle: 'Update User Account',
      windowTitle: 'User Accounts > Update User Account',
      windowClose: true,
      singleton: false,
      icon: '',
      accesses: ['admin.user.update']
    };
  }

  ngOnInit() {
    this.processingEvent.subscribe(isProcessing => this.isProcessing = isProcessing);

    this.errorsEvent.subscribe(errors => {
      this.errors = [];

      errors.forEach(error => {
        const fcontrol: AbstractControl = this.userAccountForm.get(error.path);

        if (fcontrol) {
          fcontrol.setErrors({ serverError: error.message });
        } else {
          this.errors.push(error.message);
        }

        if (this.errors.length === 0) {
          this.errors.push('The document has errors, please check the highlighted document fields.');
        }
      });
    });

    this.resultEvent.subscribe(result => {
      this.onSubmitResult(result);

      this.errors = [];
    });

    this.errors2Event.subscribe(errors => {
      this.errors = [];

      errors.forEach(error => {
        const fcontrol: AbstractControl = this.userAccountForm.get(error.path);

        if (fcontrol) {
          fcontrol.setErrors({ serverError: error.message });
        } else {
          this.errors.push(error.message);
        }

        if (this.errors.length === 0) {
          this.errors.push('Unable to view this user account, please try again.');
        }
      });
    });

    this.result2Event.subscribe(result => {
      if (result && result.data) {
        result.data.password = '';

        this.initialFormData = result.data;

        this.nav.changeTitle('Update User Account [' + result.data.profile.firstName + ' ' + result.data.profile.lastName + ']');

        // Special Case [Admin]
        if ('admin' === result.data.username) {
          this.ctl('groupCode').disable();
          this.ctl('clientAccess').disable();
          this.ctl('active').disable();
          this.isAdmin = true;
        }

        this.setInitialFormData();
      }

      this.errors = [];
    });

    this.username = this.params.params[0];

    this.initUserAccount();
  }

  ngOnDestroy() {
    this.isDestroyed = true;
  }

  private initUserAccount() {
    this.users.getUserAccount(this.username, this.processingEvent, this.result2Event, this.errors2Event);
  }

  private setInitialFormData() {
    this.userAccountForm.patchValue(this.initialFormData);

    this.initialFormData.properties.forEach(p => {
      this.properties.push(this.fb.group({
        propertyCode: [p.propertyCode, Validators.required],
        propertyValue: [p.propertyValue, Validators.required]
      }));
    });

    this.rolesGranted = this.initialFormData.roles;

    this.refreshGroups();

    this.refreshCountries();

    this.refreshRoles();
  }

  ctl(path: string) { return this.userAccountForm.get(path); }

  err(path: string) {
    try {
      const errors: any = this.ctl(path).errors;

      if (errors) {
        if (errors.serverError) {
          return errors.serverError;
        }

        if (errors.required === true) {
          return 'Required';
        }

        if (errors.email) {
          return 'Not a valid e-mail address';
        }
      }
    } catch (e) {
    }

    return '';
  }

  iserr(path: string) {
    return this.ctl(path).invalid && (this.ctl(path).touched || this.ctl(path).dirty || this.ctl(path).errors.serverError);
  }

  private onSubmitResult(result: any): void {
    if (result.data.username.length > 0) {
      this.isProcessed = true;
    } else {
      this.isProcessed = false;
    }
  }

  submitForm(): void {
    this.markFormGroupTouched(this.userAccountForm);

    this.syncRoles();

    if (!this.userAccountForm.valid) {
      if (this.errors.length === 0) {
        this.errors.push('The document has errors, please check the highlighted document fields.');
      }

      return;
    }

    this.dialog.ask(
      'Do you want to verify and validate this form?',
      'Confirmation')
      .subscribe((decision: boolean) => {
        if (decision) {
          const userAccount: IUserAccount = this.prepareData(this.userAccountForm.getRawValue());

          userAccount.address = this.prepareData(userAccount.address);

          userAccount.profile = this.prepareData(userAccount.profile);

          userAccount.roles = this.rolesGranted;

          this.users.updateUserAccount(userAccount, this.processingEvent, this.resultEvent, this.errorsEvent);
        }
      });
  }

  resetForm(): void {
    this.dialog.ask(
      'Do you want to reset this form?',
      'Confirmation')
      .subscribe((decision: boolean) => {
        if (decision) {
          this.userAccountForm.reset();

          this.resetFormStatus();

          this.setInitialFormData();
        }
      });
  }

  private resetFormStatus(): void {
    this.userAccountForm.markAsUntouched();

    this.userAccountForm.markAsPristine();

    this.errors = [];

    this.roles = [];

    this.syncRoles();
  }

  private prepareData(data: any): any {
    data.username = this.username;

    const preparedData: any = Object.assign({}, data);

    Object.keys(preparedData).forEach(k => preparedData[k] = preparedData[k] === '' ? null : preparedData[k]);

    return preparedData;
  }

  private markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      if (control.controls) {
        this.markFormGroupTouched(control);
      } else {
        control.markAsTouched();
      }
    });
  }

  refreshGroups(): void {
    if (this.isDestroyed) { return; }

    this.groups = [];

    this.users.getGroups().subscribe((result: IComboUserGroup[]) => {
      if (result) {
        this.groups = result;
      }
    }, () => setTimeout(() => this.refreshGroups(), 5000));
  }

  refreshCountries(): void {
    if (this.isDestroyed) { return; }

    this.countries = [];

    this.table.getCountries().subscribe((result: ICountry[]) => {
      if (result) {
        this.countries = result;
      }
    }, () => setTimeout(() => this.refreshCountries(), 5000));
  }

  refreshRoles(): void {
    if (this.isDestroyed) { return; }

    this.roles = [];

    let code = this.ctl('groupCode').value;

    // Special Case [Admin]
    if (this.isAdmin) {
      code = 'admin';
    }

    if (!code || code.length === 0) {
      return;
    }

    this.users.getRoles(code).subscribe((result: IComboSystemRole[]) => {
      if (result) {
        this.roles = result;
      }

      this.syncRoles();
    }, (errors: DataServiceError[]) => {
      this.syncRoles();

      setTimeout(() => this.refreshRoles(), 5000);
    });
  }

  toggleRole(role: IComboSystemRole, remove: boolean): void {
    if (this.isAdmin) { return; }

    if (!remove) {
      this.rolesGranted.push(role.roleCode);
    } else {
      this.rolesGranted = this.rolesGranted.filter((value) => {
        return value !== role.roleCode;
      });
    }
  }

  isGranted(role: IComboSystemRole): boolean {
    return this.isAdmin || -1 !== this.rolesGranted.indexOf(role.roleCode, 0);
  }

  private syncRoles(): void {
    this.rolesGranted = this.rolesGranted.filter((value: string) => {
      return this.roles.filter(role => {
        return role.roleCode === value;
      }).length > 0;
    });
  }

  addProperty() {
    this.properties.push(this.fb.group({
      propertyCode: ['', Validators.required],
      propertyValue: ['', Validators.required]
    }));
  }

  deleteProperty(index: number) {
    this.properties.removeAt(index);
  }
}
