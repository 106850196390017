import { Injectable, Output, EventEmitter } from '@angular/core';

export interface NavigationItem {
  id: string;
  params?: object;
}

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  constructor() {
  }

  // Side Bar Visibility
  isSideVisible = true;

  @Output() toggleEvent: EventEmitter<boolean> = new EventEmitter();

  private isHide = false;
  // == Side Bar Visibility

  // Navigation
  @Output() navigation: EventEmitter<NavigationItem> = new EventEmitter();
  // == Navigation

  // Close Tab
  @Output() closeTabEvent: EventEmitter<void> = new EventEmitter();
  @Output() closeAllEvent: EventEmitter<boolean> = new EventEmitter();
  // == Close Tab

  // Maximize/Minimize Tab
  isMaximized = false;

  @Output() toggleTabSizeEvent: EventEmitter<boolean> = new EventEmitter();
  // == Maximize/Minimize Tab

  // Show/Hide Tabs Screen
  @Output() toggleTabScreenEvent: EventEmitter<boolean> = new EventEmitter();
  // == Show/Hide Tabs Screen

  // Change Tab Title
  @Output() changeTitleEventEvent: EventEmitter<string> = new EventEmitter();

  toggle() {
    this.isSideVisible = !this.isSideVisible;

    this.toggleEvent.emit(this.isSideVisible);
  }

  private hide(): void {
    this.isSideVisible = false;

    this.toggleEvent.emit(this.isSideVisible);
  }

  private show(): void {
    this.isSideVisible = true;

    this.toggleEvent.emit(this.isSideVisible);
  }

  enabledHideOnNewTab(isHide: boolean) {
    this.isHide = isHide;

    if (this.isHide) {
      this.hide();
    } else {
      this.show();
    }
  }

  navigate(item: NavigationItem) {
    this.navigation.emit(item);

    if (this.isHide) {
      this.hide();
    }
  }

  public closeTab() {
    this.closeTabEvent.emit();
  }

  public toggleTabSize() {
    this.isMaximized = !this.isMaximized;

    this.toggleTabSizeEvent.emit(this.isMaximized);
  }

  public closeAll():void {
    this.closeAllEvent.emit(true);
  }

  public changeTitle(title: string) {
    this.changeTitleEventEvent.emit(title);
  }
  // == Change Tab Title
}
