import { Directive, OnInit, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';


@Directive({
  selector: '[awwIsUserNotInRole]'
})
export class IsUserNotInRole implements OnInit {
  private hasView = false;

  @Input() set awwIsUserNotInRole(accesses: string[]) {
    let condition: boolean = this.auth.isUserNotInRole(accesses);

    if (condition && !this.hasView) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.hasView = true;
    } else if (!condition && this.hasView) {
      this.viewContainer.clear();
      this.hasView = false;
    }
  }

  constructor(
    private auth: AuthenticationService,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef) {
  }

  ngOnInit() {

  }
}
