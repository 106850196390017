import { Component, OnInit, ViewChild } from '@angular/core';
import { NavigationComponentType } from 'src/app/tree.service';
import { AuthenticationService } from 'src/app/core/authentication/services/authentication.service';
import { NavigationService } from 'src/app/core/navigation/services/navigation.service';
import { NavigationParams } from 'src/app/core/common/types/NavigationParams.type';
import { merge, Observable, of as observableOf } from 'rxjs';
import { startWith, switchMap, map, catchError } from 'rxjs/operators';
import { MatPaginator, MatSort } from '@angular/material';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { DialogService } from 'src/app/core/common/services/dialog.service';
import { AdminGroupsService } from '../groups.service';

@Component({
  selector: 'awwAdminFindGroups',
  templateUrl: './find-groups.component.html',
  styleUrls: ['./find-groups.component.css']
})
export class FindGroupsComponent implements OnInit {

  constructor(
    private auth: AuthenticationService,
    private nav: NavigationService,
    private params: NavigationParams,
    private groupserv: AdminGroupsService,
    private dialog: DialogService,
    private http: HttpClient) {
    if (false === auth.isUserInRole(this.type.accesses)) {
      throw new Error('Unauthorized navigation request.');
    }
  }

  private type: NavigationComponentType = FindGroupsComponent.defineNavigation();

  displayedColumns: string[] = ['groupCode', 'groupName', 'parentGroupCode', 'actions*'];

  remoteDatabase: RemoteListDataStore<RemoteListData> | null;

  pageSize = 20;

  result: ResultUserGroup[] = [];

  resultsLength = 0;

  isLoadingResults = true;

  isError = false;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  @ViewChild(MatSort) sort: MatSort;

  public static defineNavigation(): NavigationComponentType {
    return {
      component: FindGroupsComponent,
      linkName: 'Find',
      tabTitle: 'User Groups',
      windowTitle: 'User Groups > Find',
      windowClose: true,
      singleton: false,
      icon: 'search',
      accesses: ['admin.group.find']
    };
  }

  ngOnInit() {
    this.remoteDatabase = new RemoteListDataStore(this.http, this.auth, 'api/admin/group/finder');

    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;

          if (!this.sort.active) {
            this.sort.active = 'groupCode';
          }

          if (!this.sort.direction) {
            this.sort.direction = 'asc';
          }

          return this.remoteDatabase!.getResult(
            this.sort.active, this.sort.direction, this.paginator.pageIndex, this.pageSize);
        }),
        map(result => {
          this.isLoadingResults = false;
          this.isError = false;

          if (result.data && result.data.length >= this.pageSize) {
            this.resultsLength = (this.paginator.pageIndex + 1 + 1) * this.pageSize;
          } else {
            this.resultsLength = (this.paginator.pageIndex + 1) * this.pageSize;
          }

          return result.data;
        }),
        catchError(() => {
          this.isLoadingResults = false;
          this.isError = true;

          return observableOf([]);
        })
      ).subscribe(result => this.result = result);
  }

  delete(groupCode: string) {
    this.dialog
      .ask('Do you want to delete "' + groupCode + '"?', 'Deletion')
      .subscribe(decision => {
        if (decision) {
          this.groupserv.deleteUserGroup(groupCode).subscribe(result => {
            this.dialog.alert('User group has been deleted successfully.', 'Completed');

            this.sort.sortChange.emit();
          }, error => {
            this.dialog.alert('User group deletion failed, please try again.', 'Deletion Failed');
          });
        }
      });
  }
}

export interface RemoteListData {
  data: ResultUserGroup[];
}

export interface ResultUserGroup {
  groupCode: string;
  groupName: string;
  parentGroupCode: string;
}

export class RemoteListDataStore<T> {

  constructor(private http: HttpClient, private auth: AuthenticationService, private api: string) { }

  getResult(sort: string, order: string = 'asc', page: number, pageSize: number): Observable<T> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.auth.getToken()
      }),
      withCredentials: false
    };

    return this.http.get<T>(environment.applicationServerURL + `${this.api}/${sort}/${order}/${page + 1}/${pageSize}`, httpOptions);
  }
}
