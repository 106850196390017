import { LOCALE_ID, Component, OnInit, EventEmitter, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { AuthenticationService } from 'src/app/core/authentication/services/authentication.service';
import { NavigationParams } from 'src/app/core/common/types/NavigationParams.type';
import { NavigationComponentType } from 'src/app/tree.service';
import { FormBuilder, Validators, FormGroup, AbstractControl, FormArray } from '@angular/forms';
import { NavigationService } from 'src/app/core/navigation/services/navigation.service';
import { ReferenceService } from 'src/app/modules/reference.service';
import { RiskService } from '../../risk.service';
import { MatOption, MatSelectChange, MatTableDataSource } from '@angular/material';
import { RiskTypes } from '../../shared/RiskTypes.model';
import { DataServiceError } from 'src/app/core/common/http/HttpModel';
//import { Element } from '../../shared/element';
import { RiskList } from '../../shared/riskList.model';
import { RiskRule } from '../../shared/riskRule.model';
import { RiskCriteria } from '../../shared/riskCriteria.model';
import { formatDate } from '@angular/common';
import { Subscription } from 'rxjs';
import { DialogService } from '../../../../core/common/services/dialog.service';
import { FieldNameMapper } from '../../shared/FieldNameMapper';
import { FindDataRequest, FindResult } from 'src/app/core/common/model/FinderModel';
import { EQ, NEQ, START, ENDS, EMPTY, NOT_EMPTY, CONTAINS, GT, GTE, LT, LTE } from 'src/app/core/common/model/FinderModel';
import { UNBaseFormComponent, FormMode } from '../../../shared/base-form.component';

@Component({
  selector: 'awwRiskCriteriaDetails',
  templateUrl: './details-riskCriteria.component.html',
  styleUrls: ['./details-riskCriteria.component.css']
})
export class RiskCriteriaDetailsComponent extends UNBaseFormComponent implements OnInit, OnDestroy {

  @ViewChild('enableToggle') enableToggle: any;
  @ViewChild('formOverlay') overlay:any;

  public FormModes: any;

  // risk model references
  riskCriteria: RiskCriteria = new RiskCriteria;
  riskRule: RiskRule;

  // events
  $processingEvent: EventEmitter<boolean> = new EventEmitter();
  $errorsEvent: EventEmitter<DataServiceError[]> = new EventEmitter();
  $resultEvent: EventEmitter<RiskList> = new EventEmitter();

  $riskCategoriesResult: EventEmitter<any[]> = new EventEmitter();
  $riskListResult: EventEmitter<any[]> = new EventEmitter();
  $criteriaLoadResult: EventEmitter<any> = new EventEmitter();

  // subscriptions
  private subscriptions: Subscription[] = [];

  // compnent type reference
  //private type: NavigationComponentType = NewRiskCriteriaComponent.defineNavigation();

  // tab pages on this component
  tabs: string[] = ['General', 'Risk Rules'];
  tab = 1;

  // form flags..
  isProcessed = false;
  isProcessing = false;
  toggleAddRule = true;
  private enabledFlag: boolean;

  // list for risk categories reference data
  public riskCategories: any[] = [];
  public riskList: any[] = [];
  fields: FieldNameMapper[];
  types: RiskTypes[];
  listItems: string[] = [];

  // errors list.
  errors: string[] = [];

  // table columns
  resultColumns: string[] = ['type', 'name', 'field', 'operation', 'value', 'conjunction'];
  displayedColumns: string[] = ['critType', 'critName', 'critField', 'critOperator', 'critValue', 'critConjunction', 'actions*'];

  // Risk Criteria form.
  riskCriteriaForm: FormGroup = this.fb.group({
    criteriaCode: ['', Validators.required],
    categoryCode: ['', Validators.required],
    startDate: [new Date(), Validators.required],
    endDate: [''],
    riskTarget: ['', Validators.required],
    criteriaAdvisory: ['', Validators.required],
    criteriaDescription: ['', Validators.required],
    criteriaFlag: [true]
  });

  // Risk Criteria rule form.
  riskRuleForm: FormGroup = this.fb.group({
    ruleRank: 0,
    ruleType: ['', Validators.required],
    ruleTarget: ['', Validators.required],
    ruleElementCode: ['', Validators.required],
    ruleOperator: ['', Validators.required],
    ruleConjunction: ['', Validators.required],
    ruleFieldValue: ['', Validators.required],
    targetList: null,
    ruleField: ['', Validators.required]
  });

  // finder request.
  findDataRequest: FindDataRequest = {
    isDefault: true,
    page: 1,
    pageSize: 100,
    sortColumn: this.displayedColumns[1],
    ascendingOrder: true,
    viewColumn: this.resultColumns
  };

  // define navigation component
  public static defineNewNavigation(): NavigationComponentType {
    return {
      component: RiskCriteriaDetailsComponent,
      linkName: 'New',
      tabTitle: 'Risk Target Criteria',
      windowTitle: 'Risk Criteria > New',
      windowClose: true,
      singleton: false,
      icon: 'add',
      accesses: ['risk.criteria.create']
    };
  }

  public static defineEditNavigation(): NavigationComponentType {
    return {
      component: RiskCriteriaDetailsComponent,
      linkName: 'Edit',
      tabTitle: 'Risk Target Criteria',
      windowTitle: 'Risk Criteria > Edit',
      windowClose: true,
      singleton: false,
      icon: 'edit',
      accesses: ['risk.criteria.update']
    };
  }

  public static defineViewNavigation(): NavigationComponentType {
    return {
      component: RiskCriteriaDetailsComponent,
      linkName: 'View',
      tabTitle: 'Risk Target Criteria',
      windowTitle: 'Risk Criteria > View',
      windowClose: true,
      singleton: false,
      icon: 'view',
      accesses: ['risk.criteria.view']
    };
  }

  resultMsg: string;

  // default constructor
  constructor(
    private auth: AuthenticationService,
    private nav: NavigationService,
    private navParams: NavigationParams,
    private table: ReferenceService,
    private risk: RiskService,
    private dialog: DialogService,
    private fb: FormBuilder) {

    // call base constructor.
    super(navParams.params, auth,
      { new: 'risk.criteria.create', edit: 'risk.criteria.update', view: 'risk.criteria.view' });

    this.FormModes = FormMode;

    this.riskRule = new RiskRule;
    this.riskCriteria = new RiskCriteria;
  }


  fieldHuman: string;

  /* add risk list table */
  value1 = '';
  dataSource = new MatTableDataSource(this.riskCriteria.riskRules);

  private isDestroyed = false;

  // target types for criteria
  ruleTypes: string[] = ['', 'LIST', 'FIELD'];

  // operation list
  targetOperations: any[] = [{ name: 'EQUALS', value: EQ }, { name: 'NOT EQUAL', value: NEQ },
  { name: 'STARTS WITH', value: START }, { name: 'ENDS WITH', value: ENDS }, { name: 'IS EMPTY', value: EMPTY }, { name: 'IS NOT EMPTY', value: NOT_EMPTY },
  { name: 'LESS THAN', value: LT }, { name: 'LESS THAN OR EQUALS', value: LTE }, { name: 'BEFORE', value: LT }, { name: 'BEFORE OR EQUALS', value: LTE },
  { name: 'GREATER THAN', value: GT }, { name: 'GREATER THAN OR EQUALS', value: GTE }, { name: 'AFTER', value: GT }, { name: 'AFTER OR EQUALS', value: GTE },
  { name: 'CONTAINS SUBSTRING', value: CONTAINS }];

  // criteria conjunction options
  targetConjunctions: string[] = ['AND', 'OR', 'END'];

  targetNames: any[] = [];


  tableDataSource: RiskRule[] = [];

  showValueField = false;

  tmpType = '';



  clickTab(tabRank: number) {
    this.tab = tabRank;
  }

  isActiveTab(tabRank: number): boolean {
    return this.tab === tabRank;
  }

  transformDate(date) {
    return formatDate(date, 'yyyy-MM-dd', 'en_BB');
  }

  ngOnInit() {

    this.enabledFlag = true;

    this.subscriptions['$processing'] = this.$processingEvent.subscribe(isProcessing => this.overlay.isProcessing = isProcessing);

    // handle error for api calls
    this.subscriptions['$onError'] = this.$errorsEvent.subscribe(errors => {
      this.overlay.errors = errors;
      this.errors = [];

      errors.forEach(error => {
        const fcontrol: AbstractControl = this.riskCriteriaForm.get(error.path);

        if (fcontrol) {
          fcontrol.setErrors({ serverError: error.message });
        } else {
          this.errors.push(error.message);
        }

        if (this.errors.length === 0) {
          this.errors.push('The document has errors, please check the highlighted document fields.');
        }
      });
    });

    // process result for saving risk criteria
    this.subscriptions['$onResult'] = this.$resultEvent.subscribe(result => {

      // if no lists are return then
      // show a message and return
      if (result.status.code == 200) {
        this.isProcessed = true;
        //this.dialog.alert('Risk Criteria Saved Successfully.', 'Result');
        this.riskCriteriaForm.reset();
        this.riskRuleForm.reset();
        this.riskCriteria = new RiskCriteria;
        this.dataSource.data = [];

        if (this.Mode == FormMode.NEW)
          this.resultMsg = 'Risk Criteria Created Succesfully.';
        else if (this.Mode == FormMode.EDIT)
          this.resultMsg = 'Risk Criteria Updated Succesfully.';
      }

    });

    // handle the results returned from the risk category reference table call.
    this.subscriptions['$riskCategories'] = this.$riskCategoriesResult.subscribe((result) => {
      if (result.resultItems) {
        this.riskCategories = result.resultItems;
      }
    });

    // handle the results returned from the risk list api call
    this.subscriptions['$riskListResult'] = this.$riskListResult.subscribe((result) => {
      if (result.status.code == 200) {
        this.riskList = result.data;
      }
    });

    // process result for saving risk criteria
    this.subscriptions['$criteriaLoadResult'] = this.$criteriaLoadResult.subscribe(result => this.processLoadResult(result));

    /* get risk Types */
    this.refreshRiskTyps();

    // load the reference table for risk categories
    // we can improve on this later as we can anticipate dozens of categories
    this.table.getReferenceData('RiskCategories',
      { isDefault: true, page: 1, pageSize: 100, sortColumn: null, ascendingOrder: null },
      this.$processingEvent, this.$riskCategoriesResult, this.$errorsEvent);

    if (this.Mode == FormMode.EDIT || this.Mode == FormMode.VIEW) {
      // load the selected risk criteria
      this.risk.getRiskCriteriaById(this.navParams.params.recordId, this.$processingEvent,
        this.$criteriaLoadResult, this.$errorsEvent);
    }
  }

  onTargetTypeChange($event: any) {

    // toggle hidden fields
    this.showValueField = ($event.value == 'FIELD') ? true : false;
    this.tmpType = $event.value;

    switch ($event.value) {

      case 'LIST':
        // load the lists.
        // this.risk.getSearchResultsForRiskList(this.findDataRequest, this.$processingEvent, this.$resultEvent, this.$errorsEvent);
        break;

      case 'FIELD':
        // get the risk types...
        // this.refreshRiskTyps();
        break;

    }
  }

  private processLoadResult(result: any): void {
    // if the retrieve was sucessfull
    // display the result.
    if (result.status.code == 200) {

      this.riskCriteria = result.data;

      this.tableDataSource = this.riskCriteria.riskRules;
      this.dataSource = new MatTableDataSource(this.riskCriteria.riskRules);

      this.riskCriteriaForm.setValue({
        criteriaCode: this.riskCriteria.criteriaCode,
        categoryCode: this.riskCriteria.riskCategoryCode,
        riskTarget: this.riskCriteria.riskTarget,
        criteriaAdvisory: this.riskCriteria.criteriaAdvisory,
        criteriaDescription: this.riskCriteria.criteriaDescription,
        startDate: new Date(this.riskCriteria.beginDate + " EDT"), // fixes off by one day issue
        //flag : true,
        criteriaFlag: (this.riskCriteria as any).criteriaflag,
        endDate: (this.riskCriteria.endDate) ? this.riskCriteria.endDate : null
      });

      this.enableToggle.checked = (this.riskCriteria as any).criteriaflag;

      this.riskCriteriaForm.controls.startDate.disable();
      this.riskCriteriaForm.controls.criteriaCode.disable();

      if (this.Mode == FormMode.VIEW) {
        this.nav.changeTitle(`View Risk Criteria [ ${this.riskCriteria.criteriaCode} ] `);
        this.riskCriteriaForm.disable();
        this.riskRuleForm.disable();
      } else {
        this.nav.changeTitle(`Edit Risk Criteria [ ${this.riskCriteria.criteriaCode} ] `);
      }

    }
  }

  onTargetNameChange($event: any) {
    console.log($event);
    if (this.tmpType == 'LIST') {
      return;
    }
    this.refreshFieldHumanNames($event.value);
  }


  onRuleTargetChange(target: string): void {
    if (this.tmpType == 'LIST') {
      this.risk.getRiskListByType(target, this.$processingEvent, this.$riskListResult, this.$errorsEvent);
    } else {
      this.refreshFieldHumanNames(target);
    }
  }

  /**
   * Handle the change event for the criteria enabled toggle component
   * @param - $event toggle event
   */
  onEnableToggleChanged($event: any): void {
    this.enabledFlag = $event.checked;
  }


  onHumanNameChange(event: MatSelectChange) {
    const selectedData = {
      text: (event.source.selected as MatOption).viewValue,
      value: event.source.value
    };
    this.fieldHuman = selectedData.text;

  }

  refreshRiskTyps(): void {

    this.types = [];

    this.risk.getRiskTypes().subscribe((result: RiskTypes[]) => {

      this.types = result;

    }, () => setTimeout(() => this.refreshRiskTyps(), 5000));
  }


  refreshFieldHumanNames(entity: string): void {
    // if (this.isDestroyed) return;

    this.fields = [];

    this.risk.getFields(entity).subscribe((result: FieldNameMapper[]) => {

      this.fields = result;

    }, () => setTimeout(() => this.refreshFieldHumanNames(entity), 5000));

  }

  ctl(path: string) {
    return this.riskCriteriaForm.get(path);
  }

  err(path: string) {
    try {
      const errors: any = this.ctl(path).errors;

      if (errors) {
        if (errors.serverError) {
          return errors.serverError;
        }

        if (errors.required === true) {
          return 'Required';
        }
      }
    } catch (e) {
    }

    return '';
  }

  iserr(path: string) {
    return ''; // this.ctl(path).invalid && (this.ctl(path).touched || this.ctl(path).dirty || this.ctl(path).errors.serverError);
  }

  private onSubmitResult(result: any): void {
    if (result.status.code === 200) {
      this.isProcessed = true;
    } else {
      this.isProcessed = false;
    }
  }

  // Store the list
  submitForm() {

    this.markFormGroupTouched(this.riskCriteriaForm);

    if (!this.riskCriteriaForm.valid) {
      if (this.errors.length === 0) {
        this.errors.push('The document has errors, please check the highlighted document fields.');
      }

      return;
    }

    this.dialog.ask(
      'Save Risk Criteria?',
      'Confirmation')
      .subscribe((decision: boolean) => {
        if (decision) {

          // check to ensure there is an END conjunction..
          if (!this.riskCriteria.riskRules.find(x => x.ruleConjunction == 'END')) {
            this.dialog.alert("There MUST be a Rule which contains an 'END' conjunction!!", "Problem");
            return;
          }

          // check to ensure there is an END conjunction..
          if (!this.riskCriteria.riskRules.find(x => x.ruleTarget == this.riskCriteriaForm.controls.riskTarget.value)) {
            this.dialog.alert("There MUST at least one (1) rule matching the risk criteria target!!", "Problem");
            return;
          }

          // request reference to save risk Criteria
          const request = { data: null };

          this.riskCriteria.flag = true;
          this.riskCriteria.criteriaFlag = this.riskCriteriaForm.controls.criteriaFlag.value;
          this.riskCriteria.criteriaCode = this.riskCriteriaForm.controls.criteriaCode.value;
          this.riskCriteria.riskCategoryCode = this.riskCriteriaForm.controls.categoryCode.value;
          this.riskCriteria.criteriaAdvisory = this.riskCriteriaForm.controls.criteriaAdvisory.value;
          this.riskCriteria.criteriaDescription = this.riskCriteriaForm.controls.criteriaDescription.value;
          this.riskCriteria.riskTarget = this.riskCriteriaForm.controls.riskTarget.value;
          this.riskCriteria.beginDate = null;
          this.riskCriteria.endDate = null;
          this.riskCriteria.tmpBeginDate = this.transformDate(this.riskCriteriaForm.controls.startDate.value);

          // ensure that an end date is provided before parsing.
          if (this.riskCriteriaForm.controls.endDate.value)
            this.riskCriteria.tmpEndDate = this.transformDate(this.riskCriteriaForm.controls.endDate.value);

          // get the risk category code.
          const cat = this.riskCategories.find((item) => item.categoryCode == this.riskCriteria.riskCategoryCode);
          if (cat)
            this.riskCriteria.criteriRiskLevel = cat.riskLevel;

          request.data = this.riskCriteria;

          if (this.Mode == FormMode.NEW)
            this.risk.saveRiskCriteria(request, this.$processingEvent, this.$resultEvent, this.$errorsEvent);
          else if (this.Mode == FormMode.EDIT)
            this.risk.updateRiskCriteria(this.riskCriteria, this.$processingEvent, this.$resultEvent, this.$errorsEvent);
        }
      });
  }


  ngOnDestroy() {
    this.subscriptions.forEach((handle) => handle.unsubscribe());
    this.subscriptions = null;
    this.isDestroyed = true;
  }

  private markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      if (control.controls) {
        this.markFormGroupTouched(control);
      } else {
        control.markAsTouched();
      }
    });
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  /**
   * Add a rule to the criteria rules list
   */
  addRule(): void {

    // bind the rule..
    const rule = new RiskRule;
    rule.ruleRank = this.riskRuleForm.controls.ruleRank.value;
    rule.ruleType = this.riskRuleForm.controls.ruleType.value;
    rule.field_HumanName = this.riskRuleForm.controls.ruleType.value;
    rule.ruleTarget = this.riskRuleForm.controls.ruleTarget.value;
    rule.ruleOperator = this.riskRuleForm.controls.ruleOperator.value;
    rule.ruleFieldValue = this.riskRuleForm.controls.ruleFieldValue.value;
    rule.ruleConjunction = this.riskRuleForm.controls.ruleConjunction.value;

    // set human name
    if (this.riskRuleForm.controls.ruleField.value)
      rule.field_HumanName = this.fields.find((item) => item.field == this.riskRuleForm.controls.ruleField.value).humanName;

    // if the rule is a list..
    if (rule.ruleType == 'LIST') {
      rule.ruleElementCode = this.riskRuleForm.controls.targetList.value;
      rule.ruleFieldValue = null;
      rule.ruleOperator = null;
    } else {
      rule.ruleElementCode = this.riskRuleForm.controls.ruleField.value;
    }

    // check to see if the rule already exist in the list by checking the rank..
    // new rules rank will be 0, an edited rule rank will be greater than 0.
    const ruleIndex = this.riskCriteria.riskRules.findIndex(item => item.ruleRank == rule.ruleRank);
    if (ruleIndex != -1) {

      this.riskCriteria.riskRules[ruleIndex] = rule;
    } else {

      rule.ruleRank = this.riskCriteria.riskRules.length + 1;

      // clone the rule
      this.riskCriteria.riskRules.push(rule);
    }

    // toggle the add rule button
    // if the user has added an 'END' conjunction
    // we do not allow for more rules to be added.
    this.toggleAddRule = (this.riskCriteria.riskRules.find(x => x.ruleConjunction == 'END')) ? false : true;

    // update the data grid.
    this.dataSource.data = this.riskCriteria.riskRules;

    this.riskRuleForm.reset();
  }

  /**
   * Make changes to the selected rule
   * @param {number} idx - index of the rule in the riskCriteria rules list
   */
  editRule(rule: RiskRule): void {

    // assign the rule.
    const riskRule = JSON.parse(JSON.stringify(rule));

    this.showValueField = (riskRule.ruleType == 'FIELD') ? true : false;
    this.tmpType = riskRule.ruleType;

    this.onRuleTargetChange(riskRule.ruleTarget);

    if (riskRule.ruleType == 'LIST') {

      this.riskRuleForm.setValue({
        ruleRank: riskRule.ruleRank,
        ruleType: riskRule.ruleType,
        ruleTarget: riskRule.ruleTarget,
        targetList: riskRule.ruleElementCode,
        ruleElementCode: riskRule.ruleElementCode,
        ruleField: null,
        ruleOperator: null,
        ruleFieldValue: null,
        ruleConjunction: riskRule.ruleConjunction
      });

    }
    else if (riskRule.ruleType == 'FIELD') {

      this.riskRuleForm.setValue({
        ruleRank: riskRule.ruleRank,
        ruleType: riskRule.ruleType,
        ruleTarget: riskRule.ruleTarget,
        ruleElementCode: riskRule.ruleElementCode,
        ruleField: riskRule.ruleElementCode,
        ruleOperator: riskRule.ruleOperator,
        ruleFieldValue: riskRule.ruleFieldValue,
        ruleConjunction: riskRule.ruleConjunction,
        targetList: null,
      });
    }

    // enable add btn
    this.toggleAddRule = true;
  }

  /**
   * Remove the rule from the riskCriteria rules list.
   * @param  idx - index of the rule in the riskCriteria rules list
   */
  deleteRule(idx: number): void {

    this.riskCriteria.riskRules.splice(idx, 1);

    this.toggleAddRule = (this.riskCriteria.riskRules.find(x => x.ruleConjunction == 'END')) ? false : true;

    // reorder ranking
    this.riskCriteria.riskRules.forEach((item, idx, arr) => {
      item.ruleRank = ++idx;
    });

    // update the data grid.
    this.dataSource.data = this.riskCriteria.riskRules;
  }

  /**
   * Reset the form fields and data model
   */
  public resetForm(): void {
    this.riskRule = new RiskRule;
    this.riskCriteria = new RiskCriteria;
    this.riskCriteriaForm.reset();
    this.riskRuleForm.reset();
    this.isProcessed = false;
    this.clickTab(1);
  }

  /**
   * Close the current form
   */
  public closeForm(): void {
    this.nav.closeTab();
  }

}
