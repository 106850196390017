import { RiskRule } from './riskRule.model';

// risk criteria model
export class RiskCriteria {
	criteriaCode: string;
	// code of the risk category for this criteria
	riskCategoryCode: string;
	// target section for this risk (i.e Manifest, Container, Vehicle etc.)
	riskTarget: string;
	// date the risk is valid until
	beginDate: string;
	// date the risk is valid from
	endDate: string;
	// what the member state will be advised to do.
	criteriaAdvisory: string;
	// description of the criteria
	criteriaDescription: string;
	// the level of the risk criteria .. associated to the selected risk criteria
	criteriRiskLevel: number;
	// bool flag either (true or false) to indicate if this risk criteria is enabled.
	flag: boolean;
	criteriaFlag:boolean;
	// criteria begin date. (temp field)
	tmpBeginDate: string;
	// criteria end date. (temp field)
	tmpEndDate: string;
	// list of risk rules for this category
	riskRules: RiskRule[] = [];
}
