import { Directive, HostListener, OnInit, ElementRef, Input } from '@angular/core';
import { NavigationService, NavigationItem } from 'src/app/core/navigation/services/navigation.service';

@Directive({
  selector: '[awwNewTab]'
})
export class NewTabDirective implements OnInit {

  @Input('awwNewTab') item: NavigationItem;

  constructor(private nav: NavigationService, element: ElementRef) {
    element.nativeElement.style = 'cursor: pointer;';
  }

  ngOnInit() {

  }

  @HostListener('click', ['$event'])
  clickEvent(event) {
    this.nav.navigate(this.item);
  }
}
