export const EQ = 'EQ';
export const NEQ = 'NEQ';
export const START = 'STARTSWITH';
export const ENDS = 'ENDSWITH';
export const EMPTY = 'EMPTY';
export const NOT_EMPTY = 'NOT_EMPTY';
export const CONTAINS = 'CONTAINS';
export const GT = 'GT';
export const GTE = 'GTE';
export const LT = 'LT';
export const LTE = 'LTE';

export interface FindDataRequest {
    isDefault: boolean;
    page: number;
    pageSize: number;
    sortColumn: string;
    ascendingOrder: boolean;
    viewColumn?: string[];
    criteria?: SearchCriterion[];
}

export class SearchCriterion {
    fieldName: string;
    value: any;
    operator: string;
}

export class FindResult {
    page: number;
    pageSize: number;
    sortColumn: string;
    ascendingOrder: boolean;
    resultItems: {}[];
}

export const DateFinderOptions: any[] = [
    {name: '', operator: ''},
    {name: 'EQUALS', operator: EQ},
    {name: 'NOT EQUAL', operator: NEQ},
    {name: 'BEFORE', operator: LT},
    {name: 'BEFORE OR EQUALS', operator: LTE},
    {name: 'AFTER', operator: GT},
    {name: 'AFTER OR EQUALS', operator: GTE},
    {name: 'IS EMPTY', operator: EMPTY},
    {name: 'IS NOT EMPTY', operator: NOT_EMPTY}
];

export const StringFinderOptions: any[] = [
    {name: '', operator: ''},
    {name: 'EQUALS', operator: EQ},
    {name: 'NOT EQUAL', operator: NEQ},
    {name: 'CONTAINS SUBSTRING', operator: CONTAINS},
    {name: 'STARTS WITH', operator: START},
    {name: 'ENDS WITH', operator: ENDS},
    {name: 'EMPTY', operator: EMPTY},
    {name: 'NOT EMPTY', operator: NOT_EMPTY}
];

export const GeneralFinderOptions: any[] = [
    {name: '', operator: ''},
    {name: 'EQUALS', operator: EQ},
    {name: 'CONTAINS SUBSTRING', operator: CONTAINS},
    {name: 'STARTS WITH', operator: START},
    {name: 'ENDS WTH', operator: ENDS},
    {name: 'GREATER THAN', operator: GT},
    {name: 'GREATER THAN OR EQUALS', operator: GTE},
    {name: 'LESS THAN', operator: LT},
    {name: 'LESS THAN AND EQUALS', operator: LTE},
    {name: 'EMPTY', operator: EMPTY},
    {name: 'NOT EMPTY', operator: NOT_EMPTY}
];

export const NumberFinderOptions: any[] = [
    {name: '', operator: ''},
    {name: 'EQUAL', operator: EQ},
    {name: 'NOT EQUAL', operator: NEQ},
    {name: 'GREATER THAN', operator: GT},
    {name: 'GREATER THAN OR EQUAL', operator: GTE},
    {name: 'LESS THAN', operator: LT},
    {name: 'LESS THAN OR EQUAL', operator: LTE}
];

export const ScalarFinderOptions: any[] = [
    {name: '', operator: ''},
    {name: 'EQUALS', operator: EQ},
    {name: 'NOT EQUAL', operator: NEQ}
];
