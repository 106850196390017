import {NgModule} from '@angular/core';
import {CoreCommonModule} from 'src/app/core/common/core-common.module';
import {AuthenticationModule} from 'src/app/core/authentication/authentication.module';
import {RiskCriteriaDetailsComponent} from './details-riskCriteria/details-riskCriteria.component';
import {FindRiskCriteriaComponent} from './find-riskCriteria/find-riskCriteria.component';


@NgModule({
  declarations: [
    RiskCriteriaDetailsComponent,
    FindRiskCriteriaComponent
  ],
  imports: [
    CoreCommonModule,
    AuthenticationModule
  ],
  exports: [],
  entryComponents: [
    RiskCriteriaDetailsComponent,
    FindRiskCriteriaComponent
  ]
})
export class RiskCriteriaModule {
}
