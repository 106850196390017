import {Component, Inject} from '@angular/core';
import {MAT_BOTTOM_SHEET_DATA} from '@angular/material';

@Component({
  selector: 'awwFCIErrPanel',
  templateUrl: './error-panel.component.html',
  styleUrls: ['./error-panel.component.css'],
})
export class FCIErrPanel {
  constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data: any) { }
}