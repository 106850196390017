import { Component, OnInit, ViewChild, EventEmitter } from '@angular/core';
import { NavigationComponentType } from 'src/app/tree.service';
import { AuthenticationService } from 'src/app/core/authentication/services/authentication.service';
import { MatSort } from '@angular/material';
import { FindDataRequest, FindResult } from 'src/app/core/common/model/FinderModel';
import { RiskService } from '../../risk.service';
import { StringFinderOptions, GeneralFinderOptions, DateFinderOptions, NumberFinderOptions, ScalarFinderOptions } from 'src/app/core/common/model/FinderModel';
import { DialogService } from 'src/app/core/common/services/dialog.service';
import { countryName, CaricomCountries } from 'src/app/core/common/model/lookups.model';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'awwFindRiskReport',
  templateUrl: './find-riskReport.component.html',
  styleUrls: ['./find-riskReport.component.css']
})
export class FindRiskReportComponent implements OnInit {

  public apiErrors: any[] = []

  @ViewChild(MatPaginator) paginator: MatPaginator;

  tableDataSource = new MatTableDataSource<any>();

  // default constructor
  constructor(
    private auth: AuthenticationService,
    private risk: RiskService, private dialog: DialogService) {
    if (false === auth.isUserInRole(this.type.accesses)) {
      throw new Error('Unauthorized navigation request.');
    }

    // get list of country options to show in finder.
    this.countries = CaricomCountries.map((item) => {
      return {name:item.name, value:item.code};
    });
  }

  // navigation type component
  private type: NavigationComponentType = FindRiskReportComponent.defineNavigation();
  private countries:any[] = [];

  // events
  $processingEvent: EventEmitter<boolean> = new EventEmitter();
  $errorsEvent: EventEmitter<any[]> = new EventEmitter();
  $resultEvent: EventEmitter<FindResult> = new EventEmitter();


  resultColumns: string[] = ['id', 'country', 'destinationOffice', 'manifestRegistrationYear', 'manifestRegistrationNumber', 'reportCreateDate'];
  displayedColumns: string[] = ['country', 'destinationOffice', 'manifestRegistrationYear', 'manifestRegistrationNumber', 'reportCreateDate', 'actions*'];

  // finder request reference
  findDataRequest: FindDataRequest = {
    isDefault: true,
    page: 1,
    pageSize: 100,
    sortColumn: 'id',
    ascendingOrder: true,
    viewColumn: this.resultColumns
  };

  

  isProcessing = false;
  isError = false;

  @ViewChild(MatSort) sort: MatSort;

  // binder definition
  public static defineNavigation(): NavigationComponentType {
    return {
      component: FindRiskReportComponent,
      linkName: 'Find',
      tabTitle: 'Risk Assessment Reports',
      windowTitle: 'Risk Assessment Report > Find',
      windowClose: true,
      singleton: true,
      icon: 'search',
      accesses: ['risk.report.find']
    };
  }

  // open finder window
  openFinder(): void {

    // fields to build search form.
    const searchFields: any[] = [
      { title: 'REGISTRATION YEAR', field: 'manifestRegistrationYear', options: NumberFinderOptions, type: 'number' },
      { title: 'REGISTRATION NUMBER', field: 'manifestRegistrationNumber', options: NumberFinderOptions, type: 'number' },
      { title: 'OFFICE CODE', field: 'destinationOffice', options: StringFinderOptions, type: 'string' },
      { title: 'COUNTRY CODE', field: 'country', options: ScalarFinderOptions, type:'lookup', list:this.countries },
      { title: 'CREATION DATE', field: 'reportCreateDate', options: DateFinderOptions, type: 'date' }
    ];

    // delay the display of search dialog.
    // prevents issue where error is thrown because component is not created yet.
    // https://github.com/angular/material2/issues/10705
    setTimeout(() => {

      this.dialog.finder('Find Risk Reports', searchFields).subscribe((criteria) => {
        // if the criteria is passed we assume the user chose to submit the search
        if (criteria) {
          criteria.forEach((crit) => {
            if (crit.fieldName == 'manifestRegistrationYear' || crit.fieldName == 'manifestRegistrationNumber') {
              if (crit.value) {
                crit.value = parseInt(crit.value);
              }
            }
          });
          this.findDataRequest.viewColumn = this.resultColumns;
          this.findDataRequest.criteria = criteria;
          this.risk.findReports(this.findDataRequest, this.$processingEvent, this.$resultEvent, this.$errorsEvent);
        }

      });
    });

  }



  ngOnInit() {

    this.$processingEvent.subscribe(isProcessing => this.isProcessing = isProcessing);

    this.$errorsEvent.subscribe(errors => {
      this.isError = true;
      this.apiErrors = errors;
    });

    this.$resultEvent.subscribe(result => {
      this.isError = false;

      if (result) {
        let findResult: FindResult = result;

        if (result.resultItems) {
          // map the country names
          result.resultItems.forEach((item) => {
            item.country = countryName(item.country);
          });
        }

        if (findResult) {
          this.findDataRequest = {
            isDefault: false,
            page: findResult.page,
            pageSize: findResult.pageSize,
            sortColumn: findResult.sortColumn,
            ascendingOrder: findResult.ascendingOrder,
            criteria: this.findDataRequest.criteria
          };

          // clear out previous list
          this.tableDataSource.data = [];

          if (findResult.resultItems && findResult.resultItems.length > 0) {
           
            this.tableDataSource.data = findResult.resultItems;
          }
        }
      }
    });
    // this.refreshTableData(this.findDataRequest, true);

    this.openFinder();
  }

  ngAfterViewInit() {    
    this.tableDataSource.sort = this.sort;
    this.tableDataSource.paginator = this.paginator;
  }

  refreshTableData(findDataRequest: FindDataRequest, reset: boolean) {
    if (reset === true) {
      this.tableDataSource.data = [];
    }

    this.risk.getSearchResultsForRiskReport(findDataRequest, this.$processingEvent, this.$resultEvent, this.$errorsEvent);
  }

  /**
   * Close error overlay
   */
  closeErr(): void {
    this.isError = false;
    this.apiErrors = [];
  }
}
