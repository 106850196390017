import { OnInit, Component, Input, Type, Injector, ReflectiveInjector } from '@angular/core';
import { AuthenticationService } from '../../../authentication/services/authentication.service';
import { NavigationParams } from '../../types/NavigationParams.type';

@Component({
  selector: 'awwCommonWindow',
  templateUrl: './window.component.html'
})
export class WindowComponent implements OnInit {

  @Input() component: Type<{}>;

  @Input() params: object;

  @Input() windowTitle: string;

  @Input() windowClose: boolean;

  paramInjector: Injector;

  constructor(private injector: Injector, private auth: AuthenticationService) {
  }

  ngOnInit() {
    this.paramInjector = ReflectiveInjector.resolveAndCreate([{ provide: NavigationParams, useValue: { params: this.params } }], this.injector);
  }
}
