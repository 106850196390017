import { NgModule } from '@angular/core';
import { ManifestModule } from './manifest/manifest.module';
import { WaybillModule } from './waybill/waybill.module';
import { ContainerModule } from './containers/container.module';
import { FCIModule } from './fci/fci.module';

@NgModule({
  imports: [
    ManifestModule,
    WaybillModule,
    FCIModule,
    ContainerModule
  ],
  exports: [
    ManifestModule,
    WaybillModule,
    FCIModule,
    ContainerModule
  ]
})
export class CargoModule { }
