import { LOCALE_ID, Component, OnInit, EventEmitter, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { AuthenticationService } from 'src/app/core/authentication/services/authentication.service';
import { NavigationParams } from 'src/app/core/common/types/NavigationParams.type';
import { NavigationComponentType } from 'src/app/tree.service';
import { FormBuilder, Validators, FormGroup, AbstractControl, FormArray } from '@angular/forms';
import { NavigationService } from 'src/app/core/navigation/services/navigation.service';
import { ReferenceService } from 'src/app/modules/reference.service';
import { MatOption, MatSelectChange, MatTableDataSource } from '@angular/material';
import { DataServiceError } from 'src/app/core/common/http/HttpModel';
import { formatDate } from '@angular/common';
import { DialogService } from 'src/app/core/common/services/dialog.service';
import { FindDataRequest, FindResult } from 'src/app/core/common/model/FinderModel';
import { CaricomCountries } from 'src/app/core/common/model/lookups.model';
import { ReceiversService } from '../receivers.service';
import { Subscription } from 'rxjs';
import { Receiver } from '../models/receiver.model';
import { UNBaseFormComponent, FormMode } from 'src/app/modules/shared/base-form.component';

@Component({
  selector: 'awwReceiverDetails',
  templateUrl: './details-receivers.component.html',
  styleUrls: ['./details-receivers.component.css'],
  providers:[ReceiversService]
})
export class ReceiverDetailsComponent extends UNBaseFormComponent implements OnInit, OnDestroy {

  private syncValues:Set<number>;

  @ViewChild('formOverlay') overlay:any;

  statusMsg = 'Receiver Configuration Created Sucessfully.';

  // default constructor
  constructor(
    private auth: AuthenticationService,
    public rs: ReceiversService,
    private nav: NavigationService,
    private navParams: NavigationParams,
    private table: ReferenceService,
    private dialog: DialogService,
    private fb: FormBuilder) {

    // call base constructor.
    super(navParams.params, auth, 
      {new:'awsync.receiver.create', edit:'awsync.receiver.update', view:'awsync.receiver.get'});

    this.receiver = new Receiver;

    this.syncValues = new Set<number>();

    this.rs.loadSyncServices();

    // bind handlers for api services
    // this.bindServiceHandlers();
  }

  // subcriptions
  private syncServicesData: Subscription;

  // flag to indicate if action button is shown
  public showAction = true;

  // action events
  $processingEvent: EventEmitter<boolean> = new EventEmitter();
  $errorsEvent: EventEmitter<DataServiceError[]> = new EventEmitter();
  $saveResultEvent: EventEmitter<any> = new EventEmitter();
  $lookUpResultEvent: EventEmitter<any[]> = new EventEmitter();
  $recordRetrieveEvent: EventEmitter<any[]> = new EventEmitter();

  private receiver: Receiver;

  // lists
  caricomCountries: any[] = [];
  errors: string[] = [];
  listItems: string[] = [];
  public syncOptions:any[] = [];

  // flags
  isProcessed = false;
  isProcessing = false;
  toggleAddRule = true;
  private isDestroyed = false;
  showValueField = false;
  fieldHuman: string;
  tmpType = '';
  enabledChecks = true;

  // Receivers form.
  ReceiverForm: FormGroup = this.fb.group({
    id:[''],
    code: ['', Validators.required],
    description: ['', Validators.required],
    countryCode: ['', Validators.required],
    wsdlLocation: ['', Validators.required],
    username: ['', Validators.required],
    password: ['', Validators.required],
    enableAll: [true, null]
  });

  dataSource = new MatTableDataSource();
  
  // Binder definition
  public static defineNewNavigation(): NavigationComponentType {
    return {
      component: ReceiverDetailsComponent,
      linkName: 'New',
      tabTitle: 'Receiver Details',
      windowTitle: 'Receiver > New',
      windowClose: true,
      singleton: false,
      icon: 'add',
      accesses: ['awsync.receiver.create']
    };
  }

  public static defineEditNavigation(): NavigationComponentType {
    return {
      component: ReceiverDetailsComponent,
      linkName: 'Edit',
      tabTitle: 'Receiver Details',
      windowTitle: 'Receiver > Edit',
      windowClose: true,
      singleton: false,
      icon: 'edit',
      accesses: ['awsync.receiver.update']
    };
  }

  public static defineViewNavigation(): NavigationComponentType {
    return {
      component: ReceiverDetailsComponent,
      linkName: 'View',
      tabTitle: 'Receiver Details',
      windowTitle: 'Receiver > View Receiver Details',
      windowClose: true,
      singleton: false,
      icon: '',
      accesses: ['awsync.receiver.get']
    };
  }

  transformDate(date) {
    return formatDate(date, 'yyyy-MM-dd', 'en-BB');
  }

  ngOnInit() {

    if(this.Mode == FormMode.VIEW) {
      this.ReceiverForm.disable();
      this.enabledChecks = false;
      this.isProcessed = false;
      this.showAction = false;
    }

    // the user may chose not to change the password.
    if(this.Mode == FormMode.EDIT)
      this.ReceiverForm.controls['password'].clearValidators();

    this.rs.getSyncServices(this.$processingEvent, this.$lookUpResultEvent, this.$errorsEvent);



    this.caricomCountries = CaricomCountries;

    this.$processingEvent.subscribe(isProcessing => this.overlay.isProcessing = isProcessing);

    this.$errorsEvent.subscribe(errors => {
      this.errors = [];
      this.overlay.errors = errors;

      errors.forEach(error => {
        const fcontrol: AbstractControl = this.ReceiverForm.get(error.path);

        if (fcontrol) {
          fcontrol.setErrors({serverError: error.message});
        } else {
          this.errors.push(error.message);
        }

        if (this.errors.length === 0) {
          this.errors.push('The document has errors, please check the highlighted document fields.');
        }
      });
    });

    this.$lookUpResultEvent.subscribe((result:any) => {

      // add checked field to each syncOption
      result.forEach((item) => item.checked = false);
      this.syncOptions = result;
    });

    // handle save result result event.
    this.$saveResultEvent.subscribe(result => {

      if(this.Mode == FormMode.NEW)
        this.statusMsg = 'Receiver Details Saved Sucessfully.';
      else
        this.statusMsg = 'Receiver Details Updated Sucessfully.';

      this.isProcessed = true;
      this.rs.toggleAllWatchindicators(false);
      
    });

    // handle retrieve event.
    this.$recordRetrieveEvent.subscribe((record) => {
      if (record) {
        this.receiver = record;
        console.log(this.receiver);
        this.ReceiverForm.controls.code.setValue(record.code);
        this.ReceiverForm.controls.description.setValue(record.description);
        this.ReceiverForm.controls.countryCode.setValue(record.countryCode);
        this.ReceiverForm.controls.wsdlLocation.setValue(record.wsdlLocation);
        this.ReceiverForm.controls.username.setValue(record.wsdlUsername);
        // this.ReceiverForm.controls.password.

        // if all fields need to be enabled
        if(this.receiver.watcherIndicator == 32767)
          this.rs.toggleAllWatchindicators(true);
      }
      this.nav.changeTitle(`Edit Receiver Details [ ${this.navParams.params.recordId} ] `);

    });

    // load the recipient data if the form is in EDIT or VIEW mode.
    if(this.Mode == FormMode.EDIT || this.Mode == FormMode.VIEW) {
      this.rs.getReceiver(this.navParams.params.recordId, 
        this.$processingEvent, this.$recordRetrieveEvent, this.$errorsEvent);
    }

  }

  ctl(path: string) {
    return this.ReceiverForm.get(path);
  }

  err(path: string) {
    try {
      const errors: any = this.ctl(path).errors;

      if (errors) {
        if (errors.serverError) {
          return errors.serverError;
        }

        if (errors.required === true) {
          return 'Required';
        }
      }
    } catch (e) {
    }

    return '';
  }

  iserr(path: string) {
    return ''; // this.ctl(path).invalid && (this.ctl(path).touched || this.ctl(path).dirty || this.ctl(path).errors.serverError);
  }

  private onSubmitResult(result: any): void {
    if (result.status.code === 200) {
      this.isProcessed = true;
      this.rs.toggleAllWatchindicators(false);
    } else {
      this.isProcessed = false;
    }
  }

  // Store the list
  submitForm() {
    this.markFormGroupTouched(this.ReceiverForm);

    if (!this.ReceiverForm.valid) {
      if (this.errors.length === 0) {
        this.errors.push('The document has errors, please check the highlighted document fields.');
      }

      return;
    }

    this.dialog.ask(
      'Save Receiver Data?',
      'Confirmation')
      .subscribe((decision: boolean) => {
        if (decision) {

          // map fields.
          this.receiver.code = this.ReceiverForm.controls.code.value;
          this.receiver.description = this.ReceiverForm.controls.description.value;
          this.receiver.countryCode = this.ReceiverForm.controls.countryCode.value;
          this.receiver.wsdlLocation = this.ReceiverForm.controls.wsdlLocation.value;
          this.receiver.wsdlUsername = this.ReceiverForm.controls.username.value;
          this.receiver.clientPassword = this.ReceiverForm.controls.password.value;

          // enable all services must be updated in client.
          //this.receiver.watcherIndicator = 24575;
          this.receiver.watcherIndicator = this.rs.watchIndicator;

          if(this.Mode == FormMode.NEW)
            // send api call
            this.rs.saveReceiver(this.receiver, this.$processingEvent, this.$saveResultEvent, this.$errorsEvent);
          else
            this.rs.updateReceiver(this.receiver, this.$processingEvent, this.$saveResultEvent, this.$errorsEvent);
        }
      });
  }


  ngOnDestroy() {
    // remove all observers
    // this.syncServicesData.unsubscribe();
  }

  private markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      if (control.controls) {
        this.markFormGroupTouched(control);
      } else {
        control.markAsTouched();
      }
    });
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  private bindServiceHandlers(): void {

    this.syncServicesData = this.rs.listSyncServicesData$.subscribe((data) => {

    });
  }

  processWatchIndicator(watchIndicator:number):void {

    for(let idx = this.syncOptions.length - 1;  idx >= 0; idx--) {

      console.log('bit position', this.syncOptions[idx].bitPosition);

      watchIndicator -= this.syncOptions[idx].bitPosition;

      if(watchIndicator < 0) {
        console.log('before', watchIndicator);
        watchIndicator += this.syncOptions[idx].bitPosition;
        console.log('after', watchIndicator);
        continue;
      }

      this.syncOptions[idx].checked = true;

    }
  }

  closeForm():void {
    this.nav.closeTab();
  }

  resetForm():void {
    this.ReceiverForm.reset();
    this.isProcessed = false;
  }

}
