import { NgModule } from '@angular/core';
import { CoreCommonModule } from 'src/app/core/common/core-common.module';
import { FindManifestsComponent } from './find-manifests/find-manifests.component';
import { AuthenticationModule } from 'src/app/core/authentication/authentication.module';
import { ViewManifestComponent } from './view-manifest/view-manifest.component';
import { FormUtils } from 'src/app/core/common/services/forms.service';

@NgModule({
  declarations: [
    FindManifestsComponent,
    ViewManifestComponent
  ],
  imports: [
    CoreCommonModule,
    AuthenticationModule
  ],
  exports: [

  ],
  entryComponents: [
    FindManifestsComponent,
    ViewManifestComponent
  ]
})
export class ManifestModule { }
