// @ts-nocheck
import { Component, OnInit, ViewChild, EventEmitter } from "@angular/core";
import { NavigationComponentType } from "src/app/tree.service";
import { AuthenticationService } from "src/app/core/authentication/services/authentication.service";
import {
  FindDataRequest,
  FindResult,
} from "src/app/core/common/model/FinderModel";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { MatTableDataSource, MatSort, MatPaginator } from "@angular/material";
import { DatatableComponent } from "@swimlane/ngx-datatable";
import { environment } from 'src/environments/environment';

@Component({
  selector: "app-findalertsdisposition",
  templateUrl: "./findalertsdisposition.component.html",
  styleUrls: ["./findalertsdisposition.component.css"],
})
@Injectable()
export class FindalertsdispositionComponent implements OnInit {
  dataSource: any = [];

  displayedColumns: string[] = [
    "id",
    "MANIFEST_REG_DATE",
    "EMMA_CREATE_DATE",
    "CREATE_DATE",
    "RISK_CRITERIA_DESCRIPITON",
    "RISK_LEVEL",
    "OFFICE_CODE",
    "MANIFEST_REG_YEAR",
    "MANIFEST_REG_NBR",
    "SEGMENT_TYPE",
    "SEGMENT_REFERENCE",
    "STATUS",
    "ACTIONS",
  ];
  @ViewChild(DatatableComponent) table: DatatableComponent;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  items: any = [];

  constructor(private http: HttpClient) {}

  ngOnInit(): void {
    this.http
      .get(environment.applicationAPIURL + "riskalertsdispositioncompleted")
      .subscribe((Response: {}[]) => {
        this.dataSource = new MatTableDataSource(Response);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      });

    let styles = document.createElement("link");
    styles.rel = "stylesheet";
    styles.href = "https://code.jquery.com/ui/1.13.2/themes/base/jquery-ui.css";
    document.body.appendChild(styles);

    let newScript = document.createElement("script");
    newScript.src = "https://code.jquery.com/jquery-3.6.0.js";
    document.body.appendChild(newScript);

    let newScript2 = document.createElement("script");
    newScript2.src = "https://code.jquery.com/ui/1.13.2/jquery-ui.js";

    newScript2.onload = function () {
      $(function () {
        (<any>$("#dialog")).dialog({ minWidth: 800, minHeight: 600 });
        (<any>$("#dialog")).dialog("close");
        (<any>$("body")).on("click", "*:not( #dialog )", function () {
          (<any>$("#dialog")).dialog("close");
        });
      });
    };

    document.body.appendChild(newScript2);

    var div = document.createElement("div");
    div.innerHTML = `
    <div id="dialog" title="View Risk Alert" style="min-width:800px">
      <div class="row">
        <div class="col-4">
          <p><strong>Manifest - Dates information</strong></p>
          <p><strong>Manifest Reg Date</strong> : <span id="manifestregdate"></span></p>
          <p><strong>Emma Create Date</strong> : <span id="emmacreatedate"></span></p>
        </div>
        <div class="col-4">
          <p><strong>Manifest</strong> - other information</p>
          <p><strong>Office Code</strong> : <span id="officecode"></span></p>
          <p><strong>Manifest Reg. Year / Nbr</strong> : <span id="manifestregyear"></span></p>
          <p><strong>Segment Type / Reference</strong> : <span id="segmenttypereference"></span></p>
        </div>
        <div class="col-4">
          <p><strong>Risk information</strong></p>
          <p><strong>Risk Criteria Description</strong>: <span id="criteriadescription"></span></p>
          <p><strong>Risk Level</strong>v: <span id="risklevel"></span></p>
        </div>
      </div
      <div class="row">
        <div class="col-12">
          <p><strong>Alert Disposition Information</strong></p>
      </div>
      <div class="row">
        <div class="col-3">
          <strong>Disposition</strong>
        </div>
        <div class="col-3">
          <strong>Remarks</strong>
        </div>
        <div class="col-2">
          <strong>Date</strong>
        </div>
        <div class="col-2">
          <strong>User</strong>
        </div>
        <div class="col-2">
          <strong>Status</strong>
        </div>
      </div>
      <div id="data_rows">
      </div>
    </div>
    `;
    document.body.appendChild(div);
  }

  showthemodal(description) {
    $(function () {
      (<any>$("#dialog")).dialog("close");
      (<any>$("#data_rows")).html("");
    });
    this.http
      .get(environment.applicationAPIURL + "riskalertsdisposition/" + description)
      .subscribe((Response: {}[]) => {
        $("#manifestregdate").html((<any>Response[0]).MANIFEST_REG_DATE);
        $("#emmacreatedate").html((<any>Response[0]).EMMA_CREATE_DATE);
        $("#officecode").html((<any>Response[0]).OFFICE_CODE);
        $("#manifestregyear").html((<any>Response[0]).MANIFEST_REG_YEAR);
        $("#segmenttypereference").html(
          (<any>Response[0]).SEGMENT_TYPE + " | " + (<any>Response[0]).SEGMENT_REFERENCE
        );
        $("#criteriadescription").html((<any>Response[0]).RISK_CRITERIA_DESCRIPITON);
        $("#risklevel").html((<any>Response[0]).RISK_LEVEL);
        this.http
          .get(
            environment.applicationAPIURL + "riskalertsdispositionrows/" +
              description
          )
          .subscribe((Response: {}[]) => {
            for (let result of Response) {
              $("#data_rows").append(
                '<div class="row" style="border-bottom:1px solid #D0D0D0; padding-bottom:5px; padding-top:5px;"><div class="col-3">' +
                  (<any>result).DISPOSITION +
                  '</div><div class="col-3">' +
                  (<any>result).REMARKS +
                  '</div><div class="col-2">' +
                  (<any>result).DATETIME +
                  '</div><div class="col-2">' +
                  (<any>result).USER_NAME +
                  '</div><div class="col-2">' +
                  (<any>result).STATUS +
                  "</div></div>"
              );
            }
          });
        $(function () {
          (<any>$("#dialog"))
            .dialog("open")
            .bind("clickoutside", function (e) {
              (<any>$("#dialog")).dialog("close");
            });
        });
      });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  public static defineNavigation(): NavigationComponentType {
    return {
      component: FindalertsdispositionComponent,
      linkName: "Find Alerts Disposition",
      tabTitle: "Find Alerts Disposition",
      windowTitle: "Reporting > Risk Alerts -> Find Alerts Disposition ->  Completed",
      windowClose: true,
      singleton: true,
      icon: "description",
      accesses: ["risk.report.find"],
    };
  }
}

