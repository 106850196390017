import { Component, OnInit, Type } from '@angular/core';
import { NavigationService, NavigationItem } from 'src/app/core/navigation/services/navigation.service';
import { TreeService } from 'src/app/tree.service';
import { TreeComponents } from 'src/app/tree.components';
import { AuthenticationService } from 'src/app/core/authentication/services/authentication.service';
import { Error401Component } from '../error401/error401.component';
import { Error404Component } from '../error404/error404.component';

interface TabItem {
  id: string;
  linkName: string;
  tabTitle: string;
  windowTitle: string;
  windowClose: boolean;
  params: object;
  singlton: boolean;
  suffixId: number; // WILL BE USED LATER TO IDENTIFY A WINDOW AND MANIPULATE IT
  component: Type<{}>;
  icon: string;
}

@Component({
  selector: 'awwDesktopNavigationContainer',
  templateUrl: './navtabs.component.html',
  styleUrls: ['./navtabs.component.css']
})
export class NavtabsComponent implements OnInit {

  constructor(private nav: NavigationService, private tree: TreeService, private auth: AuthenticationService) {
  }

  private static suffixIndex = 0;

  isMaximized = false;

  navigationTabs: TabItem[] = [];

  selected = 0;

  private singltonTabs: Set<string> = new Set<string>();

  newSuffixNumber(): number {
    return NavtabsComponent.suffixIndex++;
  }

  ngOnInit() {
    this.nav.navigation.subscribe((item: NavigationItem) => {
      let tabItem: TabItem = {
        id: item.id,
        linkName: this.tree.getLinkName(item.id),
        tabTitle: this.tree.getTabTitle(item.id),
        windowTitle: this.tree.getWindowTitle(item.id),
        windowClose: this.tree.getWindowClose(item.id),
        params: item.params,
        singlton: this.tree.isSinglton(item.id),
        suffixId: this.newSuffixNumber(),
        component: this.tree.getComponent(item.id),
        icon: this.tree.getIcon(item.id)
      };

      if (!this.isAuthorizedNavigation(tabItem.id, this.auth.getUserAccesses())) {
        tabItem.component = Error401Component;
        tabItem.windowTitle = 'Access Denied (Unauthorized 401)';
      }

      if (Error404Component === tabItem.component) {
        tabItem.windowTitle = 'Navigation Error (PageNotFound 404)';
      }

      this.addTab(tabItem);
    });

    this.nav.toggleTabSizeEvent.subscribe(isMaximized => {
      this.isMaximized = isMaximized;
    });

    this.nav.closeTabEvent.subscribe(() => {
      this.removeTab(this.selected);
    });

    this.nav.changeTitleEventEvent.subscribe(title => {
      this.setTitle(title);
    });

    this.nav.closeAllEvent.subscribe((signal) => {

      this.singltonTabs.clear();
      this.navigationTabs.splice(0, this.navigationTabs.length);
      this.nav.toggleTabScreenEvent.emit(this.navigationTabs.length > 0);
    });
  }

  private addTab(item: TabItem) {
    let exists: boolean = this.singltonTabs.has(item.id);

    if (exists) {
      for (let index = 0; index < this.navigationTabs.length; index++) {
        if (item.id == this.navigationTabs[index].id) {
          this.setSelected(index);

          break;
        }
      }
    } else {
      let singlton: boolean = item.singlton;

      if (singlton) {
        this.singltonTabs.add(item.id);
      }

      this.navigationTabs.push(item);

      this.setSelected(this.navigationTabs.length - 1);
    }

    this.nav.toggleTabScreenEvent.emit(this.navigationTabs.length > 0);
  }

  private removeTab(index: number) {
    if (index < this.navigationTabs.length) {
      let item: TabItem = this.navigationTabs[index];

      if (this.singltonTabs.has(item.id)) {
        this.singltonTabs.delete(item.id);
      }

      this.navigationTabs.splice(index, 1);
    }

    this.nav.toggleTabScreenEvent.emit(this.navigationTabs.length > 0);
  }

  private isAuthorizedNavigation(id: string, userAccesses: string[]) {
    let accesses: string[] = [];

    try {
      accesses = TreeComponents.getInstance().getComponents().get(id).accesses;
    } catch (e) {
    }

    if (accesses && accesses.length > 0 && userAccesses && userAccesses.length >= 0) {
      for (let index = 0; index < accesses.length; index++) {
        console.log(userAccesses.indexOf(accesses[index]));
        if (userAccesses.indexOf(accesses[index]) > 0) {
          return true;
        }
      }
    }

    return false;
  }

  setSelected(index: number) {
    this.selected = index;
  }

  private setTitle(title: string) {
    this.navigationTabs[this.selected].tabTitle = title;
  }
}
