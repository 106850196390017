import { NgModule } from '@angular/core';
import { CoreCommonModule } from '../common/core-common.module';
import { ToolbarComponent } from './components/toolbar/toolbar.component';
import { NavtreeComponent } from './components/navtree/navtree.component';
import { NavtabsComponent } from './components/navtabs/navtabs.component';
import { Error404Component } from './components/error404/error404.component';
import { DesktopMainComponent } from './components/desktop-main/desktop-main.component';
import { AuthenticationModule } from '../authentication/authentication.module';
import { Error401Component } from './components/error401/error401.component';

@NgModule({
  imports: [
    CoreCommonModule,
    AuthenticationModule
  ],
  exports: [
    DesktopMainComponent,
  ],
  declarations: [
    DesktopMainComponent,
    ToolbarComponent,
    NavtreeComponent,
    NavtabsComponent,
    Error404Component,
    Error401Component
  ],
  entryComponents: [
    Error404Component,
    Error401Component
  ]
})
export class DesktopClientModule { }
