import { Component, OnInit, Input, OnDestroy } from '@angular/core';

@Component({
  selector: 'un-form-overlay',
  templateUrl: './form-overlay.component.html',
  styleUrls: ['./form-overlay.component.css']
})
export class FormOverlayComponent implements OnInit, OnDestroy {

  // flags
  @Input() isProcessing:boolean;
  @Input() isError:boolean;

  // lists
  @Input() errors:any[] = [];

  // default constructor.
  constructor() { }

  // create event
  ngOnInit() {}


  // destroy event
  ngOnDestroy() {
  	this.errors = null;
  }

  // close shade if in error mode.
  closeErr():void {
  	this.isError = false;
  }

}
