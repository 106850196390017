import { NgModule } from '@angular/core';
import { RiskListModule } from './riskList/riskList.module';
import { RiskCriteriaModule } from './riskCriteria/riskCriteria.module';
import { RiskReportModule } from './riskReport/riskReport.module';
import { RiskMailingListModule } from './riskMailingList/riskMailingList.module';

@NgModule({
  imports: [
    RiskListModule,
    RiskReportModule,
    RiskCriteriaModule,
    RiskMailingListModule
  ],
  exports: [
    RiskListModule,
    RiskReportModule,
    RiskCriteriaModule,
    RiskMailingListModule
  ]
})
export class RiskModule { }
