import { NgModule } from '@angular/core';
import { CoreCommonModule } from 'src/app/core/common/core-common.module';
import { FindUsersComponent } from './find-users/find-users.component';
import { AddUserComponent } from './add-user/add-user.component';
import { ViewUserComponent } from './view-user/view-user.component';
import { UpdateUserComponent } from './update-user/update-user.component';
import { AuthenticationModule } from 'src/app/core/authentication/authentication.module';

@NgModule({
  imports: [
    CoreCommonModule,
    AuthenticationModule
  ],
  exports: [

  ],
  declarations: [
    FindUsersComponent,
    AddUserComponent,
    ViewUserComponent,
    UpdateUserComponent
  ],
  entryComponents: [
    FindUsersComponent,
    AddUserComponent,
    ViewUserComponent,
    UpdateUserComponent
  ]
})
export class UsersModule { }
