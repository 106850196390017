import { Component, OnInit, ViewChild, EventEmitter, OnDestroy } from '@angular/core';
import { NavigationComponentType } from 'src/app/tree.service';
import { AuthenticationService } from 'src/app/core/authentication/services/authentication.service';
import { MatSort } from '@angular/material';
import { FindDataRequest, FindResult } from 'src/app/core/common/model/FinderModel';
import { RiskService } from '../../risk.service';
import { DialogService } from 'src/app/core/common/services/dialog.service';
import { StringFinderOptions, GeneralFinderOptions } from 'src/app/core/common/model/FinderModel';

@Component({
  selector: 'awwFindRiskCriteria',
  templateUrl: './find-riskCriteria.component.html',
  styleUrls: ['./find-riskCriteria.component.css']
})
export class FindRiskCriteriaComponent implements OnInit {

  @ViewChild('formOverlay') overlay:any;

  constructor(
    private auth: AuthenticationService,
    private risk: RiskService, private dialog: DialogService) {
    if (false === auth.isUserInRole(this.type.accesses)) {
      throw new Error('Unauthorized navigation request.');
    }
  }

  // component type reference
  private type: NavigationComponentType = FindRiskCriteriaComponent.defineNavigation();

  resultColumns: string[] = ['id', 'criteriaCode', 'riskCategoryCode', 'riskTarget', 'beginDate', 'endDate'];

  displayedColumns: string[] = [ 'criteriaCode', 'riskCategoryCode',  'riskTarget', 'valid_From', 'valid_To', 'actions*'];

  tableDataSource: {}[] = [];

  $processingEvent: EventEmitter<boolean> = new EventEmitter();
  $errorsEvent: EventEmitter<any> = new EventEmitter();
  $resultEvent: EventEmitter<FindResult> = new EventEmitter();
  $deleteResultEvent: EventEmitter<any> = new EventEmitter();

  isProcessing = false;

  isShowLoadMore = false;

  isError = false;

  findDataRequest: FindDataRequest = {
    isDefault: true,
    page: 1,
    pageSize: 100,
    sortColumn: this.displayedColumns[1],
    ascendingOrder: true,
    viewColumn: this.resultColumns
  };

  @ViewChild(MatSort) sort: MatSort;

  // define component
  public static defineNavigation(): NavigationComponentType {
    return {
      component: FindRiskCriteriaComponent,
      linkName: 'Find',
      tabTitle: 'Risk Criteria',
      windowTitle: 'Risk Criteria > Find',
      windowClose: true,
      singleton: true,
      icon: 'search',
      accesses: ['risk.list.find']
    };
  }

  openFinder(): void {

    // fields to build search form.
    const searchFields: any[] = [
      {title: 'CRITERIA CODE', field: 'criteriaCode', options: StringFinderOptions, type:'string'},
      {title: 'CATEGORY CODE', field: 'riskCategoryCode', options: StringFinderOptions, type:'string'},
      {title: 'RISK TARGET', field: 'riskTarget', options: StringFinderOptions, type:'string'},
      {title: 'VALID FROM', field: 'valid_From', options: GeneralFinderOptions, type:'date'},
      {title: 'VALID UNTIL', field: 'valid_To', options: GeneralFinderOptions, type:'date'}
    ];

    // delay the display of search dialog.
    // prevents issue where error is thrown because component is not created yet.
    // https://github.com/angular/material2/issues/10705
    setTimeout(() => {
      this.dialog.finder('Find Risk Criteria', searchFields).subscribe((criteria) => {

        // if the criteria is passed we assume the user chose to submit the search
        if (criteria) {
           this.findDataRequest.criteria = criteria;
           this.risk.findRiskCriteria(this.findDataRequest, this.$processingEvent, this.$resultEvent, this.$errorsEvent);
        }

      });
    });

  }

  ngOnInit() {

    this.$processingEvent.subscribe(isProcessing => this.overlay.isProcessing = isProcessing);

    this.$errorsEvent.subscribe(errors => {
      this.overlay.errors = errors;
      this.overlay.isError = true;

      //this.dialog.alert('An error occured while trying to delete risk criteria', 'Problem');
    });

    this.$resultEvent.subscribe(result => {
       this.isError = false;
      if (result) {
        let findResult: FindResult = result;
        if (findResult) {
          this.findDataRequest = {
            isDefault: false,
            page: findResult.page,
            pageSize: findResult.pageSize,
            sortColumn: findResult.sortColumn,
            ascendingOrder: findResult.ascendingOrder,
            criteria: this.findDataRequest.criteria
          };
          try {
            this.isShowLoadMore = findResult.resultItems && findResult.resultItems.length === this.findDataRequest.pageSize;
          } catch (e) {
          }

          // clear results list
          this.tableDataSource = [];

          if (findResult.resultItems && findResult.resultItems.length > 0) {

            let tmpResult: {}[] = [];

            this.tableDataSource.forEach(row => {
              tmpResult.push(row);
            });

            findResult.resultItems.forEach(row => {

              tmpResult.push(row);
            });

            this.tableDataSource = tmpResult;
          }
        }
      } else {
        this.isShowLoadMore = false;
      }
    });

    this.sort.sortChange.subscribe(() => {
      if (!this.sort.active) {
        this.sort.active = this.findDataRequest.sortColumn;
      }

      this.findDataRequest.sortColumn = this.sort.active;

      if (!this.sort.direction) {
        this.sort.direction = 'asc';
      }

      if (this.sort.direction === 'asc') {
        this.findDataRequest.ascendingOrder = true;
      } else {
        this.findDataRequest.ascendingOrder = false;
      }

      this.refreshTableData(this.findDataRequest, true);
    });

    this.$deleteResultEvent.subscribe((result) => {
      
      if(result.code == 200) {
        this.dialog.alert('Selected Risk Criteria Deleted', 'Result');
        this.refreshTableData(this.findDataRequest, true);
      }
    });

    // open the finder dialog.
    this.openFinder();

  }

  refreshTableData(findDataRequest: FindDataRequest, reset: boolean) {
    if (reset === true) {
      this.tableDataSource = [];
    }

    this.risk.getSearchResultsForRiskList(findDataRequest, this.$processingEvent, this.$resultEvent, this.$errorsEvent);
  }

  loadMoreData() {
    let findDataRequest: FindDataRequest = {
      isDefault: false,
      page: this.findDataRequest.page, // + 1,
      pageSize: this.findDataRequest.pageSize,
      sortColumn: this.findDataRequest.sortColumn,
      ascendingOrder: this.findDataRequest.ascendingOrder
    };

    this.refreshTableData(findDataRequest, false);
  }

  /**
   * Remove a Risk Criteria from the system
   * @param criteriaId - persistent id of the risk criteria to remove
   */
  delete(criteriaId: string): void {
    this.dialog.ask(
      'Delete Selected Criteria?',
      'Confirmation')
      .subscribe((decision: boolean) => {
        if (decision) {

          this.risk.deleteRiskCriteria(criteriaId, this.$processingEvent, this.$deleteResultEvent, this.$errorsEvent);
        }
    });
  }
}
