import { Component, OnInit, ViewChild, EventEmitter, OnDestroy } from '@angular/core';
import { NavigationComponentType } from 'src/app/tree.service';
import { AuthenticationService } from 'src/app/core/authentication/services/authentication.service';
import { MatSort } from '@angular/material';
import { FindDataRequest, FindResult } from 'src/app/core/common/model/FinderModel';
import { DialogService } from 'src/app/core/common/services/dialog.service';
import { StringFinderOptions, GeneralFinderOptions } from 'src/app/core/common/model/FinderModel';
import { ReceiversService } from '../receivers.service';
import { Subscription } from 'rxjs';
import { countryName } from 'src/app/core/common/model/lookups.model';

@Component({
  selector: 'awwFindReceivers',
  templateUrl: './find-receivers.component.html',
  styleUrls: ['./find-receivers.component.css'],
  providers: [ReceiversService]
})
export class FindReceiversComponent implements OnInit, OnDestroy {

  // navigation type
  private type: NavigationComponentType = FindReceiversComponent.defineNavigation();

  // events
  $processingEvent: EventEmitter<boolean> = new EventEmitter();
  $errorsEvent: EventEmitter<any[]> = new EventEmitter();
  $resultEvent: EventEmitter<FindResult> = new EventEmitter();
  $deleteResultEvent: EventEmitter<any> = new EventEmitter();

  // form flags.
  isProcessing = false;
  isShowLoadMore = false;
  isError = false;

  // table references
  resultColumns: string[] = ['code', 'countryCode', 'description'];
  displayedColumns: string[] = ['receiverCode', 'receiverCountry', 'actions*'];
  tableDataSource: {}[] = [];

  // finder request reference
  findDataRequest: FindDataRequest = {
    isDefault: true,
    page: 1,
    pageSize: 100,
    sortColumn: 'code',
    ascendingOrder: true,
    viewColumn: this.resultColumns
  };

  // form element references
  @ViewChild(MatSort) sort: MatSort;

  // default constructor
  constructor(
    private auth: AuthenticationService,
    private rs: ReceiversService, private dialog: DialogService) {

    // check the permission of the user..
    if (false === auth.isUserInRole(this.type.accesses)) {
      throw new Error('Unauthorized navigation request.');
    }
  }

  // binder definition
  public static defineNavigation(): NavigationComponentType {
    return {
      component: FindReceiversComponent,
      linkName: 'Find',
      tabTitle: 'Receivers Listing',
      windowTitle: 'Receivers > Find',
      windowClose: true,
      singleton: true,
      icon: 'search',
      accesses: ['awsync.receiver.find']
    };
  }

  // open finder window
  openFinder(): void {

    // fields to build search form.
    const searchFields: any[] = [
      {title: 'CODE', field: 'code', options: StringFinderOptions, type:'string'},
      {title: 'DESCRIPTION', field: 'description', options: StringFinderOptions, type:'string'},
      {title: 'COUNTRY CODE', field: 'countryCode', options: StringFinderOptions, type:'string'}
    ];

    // delay the display of search dialog.
    // prevents issue where error is thrown because component is not created yet.
    // https://github.com/angular/material2/issues/10705
    setTimeout(() => {

      this.dialog.finder('Find Receivers', searchFields).subscribe((criteria) => {

        // if the criteria is passed we assume the user chose to submit the search
        if (criteria) {
           this.findDataRequest.criteria = criteria;
           this.rs.find(this.findDataRequest, this.$processingEvent, this.$resultEvent, this.$errorsEvent);
        }

      });
    });

  }

  // component initialization handler.
  ngOnInit() {

    this.$processingEvent.subscribe(isProcessing => this.isProcessing = isProcessing);

    this.$errorsEvent.subscribe(errors => {
      this.isError = true;
    });

    this.$resultEvent.subscribe(result => {
       this.isError = false;
      if (result) {
        let findResult: FindResult = result;
        if (findResult) {
          this.findDataRequest = {
            isDefault: false,
            page: findResult.page,
            pageSize: findResult.pageSize,
            sortColumn: findResult.sortColumn,
            ascendingOrder: findResult.ascendingOrder,
            criteria: this.findDataRequest.criteria
          };
          try {
            this.isShowLoadMore = findResult.resultItems && findResult.resultItems.length === this.findDataRequest.pageSize;
          } catch (e) {
          }

          // clear out previous list
          this.tableDataSource = [];

          if (findResult.resultItems && findResult.resultItems.length > 0) {

            // map the country code field to the full name of the country
            findResult.resultItems.forEach((item:any, idx, arr) => {
              item.countryCode = countryName(item.countryCode);
            })

            let tmpResult: {}[] = [];

            this.tableDataSource.forEach(row => {
              tmpResult.push(row);
            });

            findResult.resultItems.forEach(row => {

              tmpResult.push(row);
            });

            this.tableDataSource = tmpResult;
          } else {
            this.tableDataSource = [];
          }
        }
      } else {
        this.isShowLoadMore = false;
      }
    });

    this.sort.sortChange.subscribe(() => {
      if (!this.sort.active) {
        this.sort.active = this.findDataRequest.sortColumn;
      }

      this.findDataRequest.sortColumn = this.sort.active;

      if (!this.sort.direction) {
        this.sort.direction = 'asc';
      }

      if (this.sort.direction === 'asc') {
        this.findDataRequest.ascendingOrder = true;
      } else {
        this.findDataRequest.ascendingOrder = false;
      }

      this.refreshTableData(this.findDataRequest, true);
    });

    // handle the delete success result
    this.$deleteResultEvent.subscribe(() => {
      this.dialog.alert('Selected record deleted.', 'Result');
      // reload the grid with the last request
      this.rs.find(this.findDataRequest, this.$processingEvent, this.$resultEvent, this.$errorsEvent);
    });

    // open the finder dialog.
    this.openFinder();

  }

  // clean up on component destroy
  ngOnDestroy(): void {}

  refreshTableData(findDataRequest: FindDataRequest, reset: boolean) {
    if (reset === true) {
      this.tableDataSource = [];
    }

    // this.risk.getSearchResultsForRiskList(findDataRequest, this.processingEvent, this.resultEvent, this.errorsEvent);
  }

  loadMoreData() {
    let findDataRequest: FindDataRequest = {
      isDefault: false,
      page: this.findDataRequest.page, // + 1,
      pageSize: this.findDataRequest.pageSize,
      sortColumn: this.findDataRequest.sortColumn,
      ascendingOrder: this.findDataRequest.ascendingOrder
    };

    this.refreshTableData(findDataRequest, false);
  }

  /**
   * Delete the selected
   * @param Id - id of item to delete
   */
  delete(Id: any): void {

    this.dialog.ask(
      'Delete Selected Item?',
      'Confirmation')
      .subscribe((decision: boolean) => {
        if (decision) {

          this.rs.deleteReceiver(Id, this.$processingEvent, this.$deleteResultEvent, this.$errorsEvent);
        }
    });
  }
}
