import { Component, Input } from '@angular/core';
import { NestedTreeControl } from '@angular/cdk/tree';
import { of } from 'rxjs';
import { NodeTreeRiskReportModel } from '../../../shared/nodetree.model';


@Component({
  selector: 'app-waybill-tree',
  templateUrl: './waybill-tree.component.html',
  styleUrls: ['./waybill-tree.component.css']
})
export class WaybillTreeComponent {
  @Input() nestedDataSource: NodeTreeRiskReportModel[];
  getChildren = (node: NodeTreeRiskReportModel) => of(node.node);
  nestedTreeControl = new NestedTreeControl(this.getChildren);
  hasChild(_: number, node: NodeTreeRiskReportModel) {
   
    return node.node != null && node.node.length > 0;
  }
}
