import { Injectable, EventEmitter } from '@angular/core';
import { AuthenticationService } from 'src/app/core/authentication/services/authentication.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { throwError, Observable } from 'rxjs';
import { IUserGroup, IComboSystemRole, IComboUserGroup } from '../admin-model';
import { HttpUtils } from 'src/app/core/common/http/HttpUtils';
import { DataServiceError, DataRequest } from 'src/app/core/common/http/HttpModel';

@Injectable({
  providedIn: 'root'
})
export class AdminGroupsService {

  private httpUtils: HttpUtils;

  constructor(private auth: AuthenticationService, private http: HttpClient) {
    this.httpUtils = new HttpUtils(auth, http);
  }

  private handleError(err: HttpErrorResponse) {
    const errors: DataServiceError[] = [];

    console.log(err);

    if (err.error instanceof ErrorEvent) {
    } else {
      try {
        if (err.status === 403) {
          errors.push({ message: 'Authentication failed, session has expired!', path: '', value: '' });
        }

        if (err.error.status) {
          if (err.error.status.errors && err.error.status.errors.length > 0) {
            err.error.status.errors.forEach((error: DataServiceError) => {
              errors.push(error);
            });
          }
        }
      } catch (e) {
      }

      if (errors!.length === 0) {
        errors.push({ message: 'Unexpected error, please contact your administrator', path: '', value: '' });
      }
    }

    return throwError(errors);
  }

  public createUserGroup(body: IUserGroup, processingEvent: EventEmitter<boolean>, resultEvent: EventEmitter<any>, errorsEvent: EventEmitter<DataServiceError[]>): void {
    const api = 'api/admin/group';

    const request: DataRequest = new DataRequest();

    request.header = {
      id: this.auth.getUsername(),
    };

    request.data = body;

    this.httpUtils.invokePost(api, request, processingEvent, resultEvent, errorsEvent, this.handleError);
  }

  public updateUserGroup(body: IUserGroup, processingEvent: EventEmitter<boolean>, resultEvent: EventEmitter<any>, errorsEvent: EventEmitter<DataServiceError[]>): void {
    const api = 'api/admin/group';

    const request: DataRequest = new DataRequest();

    request.header = {
      id: this.auth.getUsername(),
    };

    request.data = body;

    this.httpUtils.invokePut(api, request, processingEvent, resultEvent, errorsEvent, this.handleError);
  }

  public getUserGroup(groupCode: string, processingEvent: EventEmitter<boolean>, resultEvent: EventEmitter<any>, errorsEvent: EventEmitter<DataServiceError[]>): void {
    const api: string = 'api/admin/group/' + groupCode;

    const request: DataRequest = new DataRequest();

    request.header = {
      id: this.auth.getUsername(),
    };

    this.httpUtils.invokeGet(api, processingEvent, resultEvent, errorsEvent, this.handleError);
  }

  public deleteUserGroup(groupCode: string): Observable<any> {
    const api: string = 'api/admin/group/' + groupCode;

    return this.httpUtils.invokeDelete(api, this.handleError);
  }

  public getRoles(groupCode: string): Observable<IComboSystemRole[]> {
    const api: string = 'api/admin/finder/list/roles/bygroup/' + groupCode;

    return this.httpUtils.invokeGet2<any, DataServiceError>(api, this.handleError).pipe(map(result => {
      return result.data;
    }));
  }

  public getGroups(): Observable<IComboUserGroup[]> {
    const api = 'api/admin/finder/list/groups/all';

    return this.httpUtils.invokeGet2<any, DataServiceError>(api, this.handleError).pipe(map(result => {
      return result.data;
    }));
  }
}
