export class ICountry {
    constructor(public countryCode: string, public countryName: string) { }
}

export class ICustomsOffice {
    constructor(public countryCode: string, public code: string, public name: string) { }
}

export class IUNLocode {
    constructor(public code: string, public description: string) { }
}