import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DesktopMainComponent } from '../desktop-client/components/desktop-main/desktop-main.component';
import { RoutingGuard } from './app-routing.guard';
import { LoginComponent } from '../authentication/components/login/login.component';
import { DesktopClientModule } from '../desktop-client/desktop-client.module';
import { AuthenticationModule } from '../authentication/authentication.module';

const routes: Routes = [
  { path: '', component: LoginComponent, pathMatch: 'full' },
  { path: 'home', component: DesktopMainComponent, pathMatch: 'full', canActivate: [RoutingGuard], canDeactivate: [RoutingGuard] },
  { path: 'logout', redirectTo: '', pathMatch: 'full' },
  { path: '**', redirectTo: '', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true }), DesktopClientModule, AuthenticationModule],
  exports: [RouterModule]
})
export class AppRoutingModule { }
