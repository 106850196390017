import { Component, OnInit, EventEmitter } from '@angular/core';
import { AuthenticationService } from 'src/app/core/authentication/services/authentication.service';
import { NavigationService } from 'src/app/core/navigation/services/navigation.service';
import { NavigationParams } from 'src/app/core/common/types/NavigationParams.type';
import { NavigationComponentType } from 'src/app/tree.service';
import { FormBuilder, Validators, FormGroup, AbstractControl } from '@angular/forms';
import { AdminProfileService } from '../profile.service';
import { DialogService } from 'src/app/core/common/services/dialog.service';
import { DataServiceError } from 'src/app/core/common/http/HttpModel';

@Component({
  selector: 'awwAdminChangePassword',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {

  constructor(
    private auth: AuthenticationService,
    private params: NavigationParams,
    private profile: AdminProfileService,
    private nav: NavigationService,
    private dialog: DialogService,
    private fb: FormBuilder) {

    if (false === this.auth.isUserInRole(this.type.accesses)) {
      throw new Error('Unauthorized navigation request.');
    }
  }

  private type: NavigationComponentType = ChangePasswordComponent.defineNavigation();

  processingEvent: EventEmitter<boolean> = new EventEmitter();

  errorsEvent: EventEmitter<DataServiceError[]> = new EventEmitter();

  resultEvent: EventEmitter<any> = new EventEmitter();

  isProcessed = false;

  isProcessing = false;

  errors: string[] = [];

  profileForm: FormGroup = this.fb.group({
    password: ['', Validators.required],
    passwordConfirmation: ['', Validators.required]
  });

  public static defineNavigation(): NavigationComponentType {
    return {
      component: ChangePasswordComponent,
      linkName: 'Change Password',
      tabTitle: 'Change Password',
      windowTitle: 'Home > My Profile > Change Password',
      windowClose: true,
      singleton: true,
      icon: 'account_box',
      accesses: ['profile.password.change']
    };
  }

  ngOnInit() {
    this.processingEvent.subscribe(isProcessing => this.isProcessing = isProcessing);

    this.errorsEvent.subscribe(errors => {
      this.errors = [];

      errors.forEach(error => {
        const fcontrol: AbstractControl = this.profileForm.get(error.path);

        if (fcontrol) {
          fcontrol.setErrors({ serverError: error.message });
        } else {
          this.errors.push(error.message);
        }

        if (this.errors.length === 0) {
          this.errors.push('Unable to change password, please try again.');
        }
      });
    });

    this.resultEvent.subscribe(result => {
      this.onSubmitResult(result);

      this.errors = [];
    });


  }

  ctl(path: string) { return this.profileForm.get(path); }

  err(path: string) {
    try {
      const errors: any = this.ctl(path).errors;

      if (errors) {
        if (errors.serverError) {
          return errors.serverError;
        }

        if (errors.required === true) {
          return 'Required';
        }
      }
    } catch (e) {
    }

    return '';
  }

  iserr(path: string) {
    return this.ctl(path).invalid && (this.ctl(path).touched || this.ctl(path).dirty || this.ctl(path).errors.serverError);
  }

  private markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      if (control.controls) {
        this.markFormGroupTouched(control);
      } else {
        control.markAsTouched();
      }
    });
  }

  private onSubmitResult(result: any): void {
    if (result.data && result.data === 'Y') {
      this.isProcessed = true;
    } else {
      this.isProcessed = false;
    }
  }

  submitForm(): void {
    this.markFormGroupTouched(this.profileForm);

    if (!this.profileForm.valid) {
      if (this.errors.length === 0) {
        this.errors.push('The document has errors, please check the highlighted document fields.');
      }

      return;
    }

    this.dialog.ask(
      'Do you want to change your password?',
      'Confirmation')
      .subscribe((decision: boolean) => {
        if (decision) {
          this.profile.changePassword(this.profileForm.value, this.processingEvent, this.resultEvent, this.errorsEvent);
        }
      });
  }
}
