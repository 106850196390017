export const environment = {
  production: false,
  applicationName: 'EMMA CARICOM-IMPACS (LOCAL-PHASE 1)',
  // applicationServerURL: 'http://172.20.20.19:8080/emma/',
  applicationServerURL: 'https://acistest.caricomimpacs.org/emma/',
  applicationTestUserName: 'test',
  applicationAPIURL: 'https://acistest.caricomimpacs.org/emma/api/',
  applicationTestUserData: {
    'data': {
      'accesses': [
        'server',
        'admin.group.create',
        'admin.role.view',
        'admin.role.create',
        'admin.group.find',
        'admin.user.delete',
        'tr.view',
        'admin.access.delete',
        'admin.access.find',
        'client',
        'admin.role.delete',
        'admin.role.update',
        'admin.group.view',
        'admin.role.find',
        'admin.user.create',
        'admin.access.create',
        'admin.user.view',
        'profile',
        'tr.edit',
        'admin.access.view',
        'admin.access.update',
        'admin.user.update',
        'admin.group.delete',
        'admin.group.update',
        'admin.user.find'
      ],
      'profile': {
        'active': true,
        'address': {
          'addressLine1': 'In front of the Ethiopian resto',
          'addressLine2': 'The black gate',
          'addressLine3': '3rd floor',
          'building': 'Rubangura',
          'city': 'Kigali',
          'countryCode': 'RW',
          'countryName': 'Rwanda',
          'region': 'Gasabo',
          'street': 'KG 668',
          'unit': '314'
        },
        'clientAccess': true,
        'groupCode': 'admin',
        'password': '2c593b6cfc2db8f9cb991b2b2d56907c6be5fe391e6bd0300ea4e7d885444644',
        'passwordPolicy': {
          'failedAttempts': 0,
          'forcePasswordChange': false,
          'passwordForceChangeDate': '2019-05-09T04:09:46.068',
          'passwordWarningDate': '2019-03-10T04:09:46.068'
        },
        'profile': {
          'emailAddress': 'tamer.qasim@un.org',
          'firstName': 'Tamer',
          'functionalTitle': 'ASYCUDA Technical Expert',
          'lastName': 'Qasim',
          'mobile': '+250786700390',
          'telephone': '+972598966432',
          'telephoneExtension': '1082'
        },
        'properties': [],
        'roles': [
          'admin.access',
          'admin.group',
          'admin.role',
          'profile',
          'tt.edit',
          'admin',
          'admin.user',
          'tt.view'
        ],
        'username': 'test'
      },
    'token': 'eyJ0eXAiOiJqd3QiLCJhbGciOiJSUzI1NiJ9.eyJ0aWQiOiI0OGZlMmU0Yy1kODBkLTQwMzYtODI2Yi1mNmYxZGNlMTk5NGYiLCJzdWIiOiJhZG1pbiIsImlzcyI6ImVtbWEtc3lzdGVtIiwiYXVkIjoiZW1tYS11c2VycyIsImdyb3VwcyI6WyJzZXJ2ZXIiLCJhZG1pbi5ncm91cC5jcmVhdGUiLCJhZG1pbi5yb2xlLnZpZXciLCJhZG1pbi5yb2xlLmNyZWF0ZSIsImFkbWluLmdyb3VwLmZpbmQiLCJhZG1pbi51c2VyLmRlbGV0ZSIsInRyLnZpZXciLCJhZG1pbi5hY2Nlc3MuZGVsZXRlIiwiYWRtaW4uYWNjZXNzLmZpbmQiLCJjbGllbnQiLCJhZG1pbi5yb2xlLmRlbGV0ZSIsImFkbWluLnJvbGUudXBkYXRlIiwiYWRtaW4uZ3JvdXAudmlldyIsImFkbWluLnJvbGUuZmluZCIsImFkbWluLnVzZXIuY3JlYXRlIiwiYWRtaW4uYWNjZXNzLmNyZWF0ZSIsImFkbWluLnVzZXIudmlldyIsInByb2ZpbGUiLCJ0ci5lZGl0IiwiYWRtaW4uYWNjZXNzLnZpZXciLCJhZG1pbi5hY2Nlc3MudXBkYXRlIiwiYWRtaW4udXNlci51cGRhdGUiLCJhZG1pbi5ncm91cC5kZWxldGUiLCJhZG1pbi5ncm91cC51cGRhdGUiLCJhZG1pbi51c2VyLmZpbmQiXSwiZXhwIjoxNTQxODUyOTA1fQ.FpAj6tRauNRA-FPTrocQJRcu1Wij0A-Ctf6cjf-Jj7iONQGgfeh-rKSMv3cDg-b4VufOf5UD-nEGps2xA6_xwPq7sGjLKO9aikbxn3rfMOox73ntxJ5FXj6kTsONG_w98yXht5pgipf4Z1263wIytdqqUVsvdnLjgoqfY7RF4tnbYCD2CTS6cjzbahrOvbm0KmoBpi1Km5KpyYi0jQqG_-evw6sR-3NoiThd4DE6OSzMtcz3k7ytDH24uFfdl-tvyKhRNUZYFKwOLyIiu0P6T6bIKye2jhFEvY4STQPIft5YjBYjPcPb7HytNTryO2myQUzdEWr3F2lS4rcxoejswQ',
      'tokenId': '48fe2e4c-d80d-4036-826b-f6f1dce1994f',
      'loginDomain': 'EMMA'
    },
    'header': {
      'date': '2018-11-10T04:28:25.513',
      'id': 'a0d5a9ed-e73a-44c5-8f52-cc59fa86c25d',
      'uri': 'http://127.0.0.1:8080/emma/api/admin/auth/token'
    },
    'status': {
      'code': 200,
      'message': 'OK'
    }
  }
};
