import { Component, OnInit, EventEmitter, OnDestroy } from '@angular/core';
import { NavigationComponentType } from 'src/app/tree.service';
import { AuthenticationService } from 'src/app/core/authentication/services/authentication.service';
import { NavigationParams } from 'src/app/core/common/types/NavigationParams.type';
import { FormGroup, Validators, FormBuilder, AbstractControl, FormArray } from '@angular/forms';
import { ReferenceService } from '../../../reference.service';
import { NavigationService } from 'src/app/core/navigation/services/navigation.service';
import { ICountry } from 'src/app/modules/reference-model';
import { IComboUserGroup, IComboSystemRole } from '../../admin-model';
import { AdminUsersService } from '../users.service';
import { DataServiceError } from 'src/app/core/common/http/HttpModel';

@Component({
  selector: 'awwAdminViewUser',
  templateUrl: './view-user.component.html',
  styleUrls: ['./view-user.component.css']
})
export class ViewUserComponent implements OnInit, OnDestroy {

  constructor(
    private auth: AuthenticationService,
    private params: NavigationParams,
    private users: AdminUsersService,
    private nav: NavigationService,
    private table: ReferenceService,
    private fb: FormBuilder) {

    if (false === this.auth.isUserInRole(this.type.accesses)) {
      throw new Error('Unauthorized navigation request.');
    }
  }

  get properties() {
    return this.userAccountForm.get('properties') as FormArray;
  }

  private type: NavigationComponentType = ViewUserComponent.defineNavigation();

  processingEvent: EventEmitter<boolean> = new EventEmitter();

  errorsEvent: EventEmitter<DataServiceError[]> = new EventEmitter();

  resultEvent: EventEmitter<any> = new EventEmitter();

  isProcessed = false;

  isProcessing = false;

  username: string;

  groups: IComboUserGroup[] = [];

  countries: ICountry[] = [];

  roles: IComboSystemRole[] = [];

  rolesGranted: string[] = [];

  errors: string[] = [];

  // Special Case [Admin]
  private isAdmin = false;

  private isDestroyed = false;

  userAccountForm: FormGroup = this.fb.group({
    username: ['', Validators.required],
    profile: this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      functionalTitle: ['', Validators.required],
      mobile: [''],
      telephone: [''],
      telephoneExtension: [''],
      emailAddress: ['', Validators.email]
    }),
    address: this.fb.group({
      addressLine1: [''],
      addressLine2: [''],
      addressLine3: [''],
      building: [''],
      city: ['', Validators.required],
      countryCode: ['', Validators.required],
      region: [''],
      street: [''],
      unit: ['']
    }),
    groupCode: ['', Validators.required],
    properties: this.fb.array([]),
    active: [true, Validators.required],
    clientAccess: [true, Validators.required]
  });

  public static defineNavigation(): NavigationComponentType {
    return {
      component: ViewUserComponent,
      linkName: 'View',
      tabTitle: 'View User Account',
      windowTitle: 'User Accounts > View User Account',
      windowClose: true,
      singleton: false,
      icon: '',
      accesses: ['admin.user.view']
    };
  }

  ngOnInit() {
    this.processingEvent.subscribe(isProcessing => this.isProcessing = isProcessing);

    this.errorsEvent.subscribe(errors => {
      this.errors = [];

      errors.forEach(error => {
        const fcontrol: AbstractControl = this.userAccountForm.get(error.path);

        if (fcontrol) {
          fcontrol.setErrors({ serverError: error.message });
        } else {
          this.errors.push(error.message);
        }

        if (this.errors.length === 0) {
          this.errors.push('Unable to view this user account, please try again.');
        }
      });
    });

    this.resultEvent.subscribe(result => {
      if (result && result.data) {
        this.isProcessed = true;

        this.userAccountForm.disable();

        this.userAccountForm.patchValue(result.data);

        this.nav.changeTitle('View User Account [' + result.data.profile.firstName + ' ' + result.data.profile.lastName + ']');

        result.data.properties.forEach(p => {
          this.properties.push(this.fb.group({
            propertyCode: [p.propertyCode, Validators.required],
            propertyValue: [p.propertyValue, Validators.required]
          }));
        });

        // Special Case [Admin]
        if ('admin' === result.data.username) {
          this.ctl('groupCode').disable();
          this.isAdmin = true;
        }

        this.rolesGranted = result.data.roles;

        this.refreshGroups();

        this.refreshCountries();

        this.refreshRoles();

        this.userAccountForm.disable();
      }

      this.errors = [];
    });

    this.username = this.params.params[0];

    this.initUserAccount();
  }

  ngOnDestroy() {
    this.isDestroyed = true;
  }

  private initUserAccount() {
    this.users.getUserAccount(this.username, this.processingEvent, this.resultEvent, this.errorsEvent);
  }

  ctl(path: string) { return this.userAccountForm.get(path); }

  refreshGroups(): void {
    if (this.isDestroyed) { return; }

    this.groups = [];

    this.users.getGroups().subscribe((result: IComboUserGroup[]) => {
      if (result) {
        this.groups = result;
      }
    }, () => setTimeout(() => this.refreshGroups(), 5000));
  }

  refreshCountries(): void {
    if (this.isDestroyed) { return; }

    this.countries = [];

    this.table.getCountries().subscribe((result: ICountry[]) => {
      if (result) {
        this.countries = result;
      }
    }, () => setTimeout(() => this.refreshCountries(), 5000));
  }

  refreshRoles(): void {
    if (this.isDestroyed) { return; }

    this.roles = [];

    let code = this.ctl('groupCode').value;

    // Special Case [Admin]
    if (this.isAdmin) {
      code = 'admin';
    }

    if (!code || code.length === 0) {
      return;
    }

    this.users.getRoles(code).subscribe((result: IComboSystemRole[]) => {
      if (result) {
        this.roles = result;
      }

      this.syncRoles();
    }, (errors: DataServiceError[]) => {
      this.syncRoles();

      setTimeout(() => this.refreshRoles(), 5000);
    });
  }

  isGranted(role: IComboSystemRole): boolean {
    return this.isAdmin || -1 !== this.rolesGranted.indexOf(role.roleCode, 0);
  }

  private syncRoles(): void {
    this.rolesGranted = this.rolesGranted.filter((value: string) => {
      return this.roles.filter(role => {
        return role.roleCode === value;
      }).length > 0;
    });
  }
}
