import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'awwCommonDialogAlert',
    templateUrl: 'desktop-alert.component.html',
    styleUrls: ['./desktop-dialog.component.css']
})
export class DesktopAlertComponent {

    constructor(public dialogRef: MatDialogRef<DesktopAlertComponent>, @Inject(MAT_DIALOG_DATA) public data: { message: string, title: string }) {
    }

    closeDialog(): void {
        this.dialogRef.close();
    }
}
