import { Directive, HostListener, OnInit, ElementRef } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';
import { DialogService } from '../../common/services/dialog.service';

@Directive({
  selector: '[awwLogout]'
})
export class LogoutDirective implements OnInit {

  constructor(private auth: AuthenticationService, element: ElementRef, public dialog: DialogService) {
    element.nativeElement.style = 'cursor: pointer;';
  }

  ngOnInit() {

  }

  @HostListener('click', ['$event'])
  clickEvent(event) {
    this.dialog.ask('Do you want to logout?').subscribe(decision => {
      if (decision && decision === true) {
        this.auth.logout();
      }
    });
  }
}
