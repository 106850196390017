import { NgModule } from '@angular/core';
import { CoreCommonModule } from 'src/app/core/common/core-common.module';
import { FindAccessesComponent } from './find-accesses/find-accesses.component';

@NgModule({
  imports: [
    CoreCommonModule
  ],
  declarations: [
    FindAccessesComponent
  ],
  entryComponents: [
    FindAccessesComponent
  ]
})
export class AccessesModule { }
