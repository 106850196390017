import { Injectable, EventEmitter } from '@angular/core';
import { AuthenticationService } from 'src/app/core/authentication/services/authentication.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { TimedTableDefinition, TimedTableResult, TimedTableUpdate } from './table-model';
import { HttpUtils } from 'src/app/core/common/http/HttpUtils';
import { FindDataRequest } from 'src/app/core/common/model/FinderModel';

@Injectable({
  providedIn: 'root'
})
export class TableService {

  private httpUtils: HttpUtils;

  constructor(private auth: AuthenticationService, private http: HttpClient) {
    this.httpUtils = new HttpUtils(auth, http);
  }

  private handleError(err: HttpErrorResponse) {
    const errors: any[] = [];

    console.log(err);

    if (err.error instanceof ErrorEvent) {
    } else {
      try {
        if (err.status === 403) {
          errors.push({ message: 'Authentication failed, session has expired!', path: '', value: '' });
        }
      } catch (e) {
      }

      if (errors!.length === 0) {
        errors.push({ message: 'Unexpected error, please contact your administrator', path: '', value: '' });
      }
    }

    return throwError(errors);
  }

  public getTableDefinition(tableName: string, processingEvent: EventEmitter<boolean>, resultEvent: EventEmitter<TimedTableDefinition>, errorsEvent: EventEmitter<any[]>): void {
    const api: string = 'api/timedTable/' + tableName + '/describe';

    this.httpUtils.invokeGet<TimedTableDefinition, any>(api, processingEvent, resultEvent, errorsEvent, this.handleError);
  }

  public getTableData(tableName: string, findDataRequest: FindDataRequest, processingEvent: EventEmitter<boolean>, resultEvent: EventEmitter<TimedTableResult>, errorsEvent: EventEmitter<any[]>): void {
    let api: string = 'api/timedTable/' + tableName + '/pagination';

    if (findDataRequest) {
      api = api + `?page=${findDataRequest.page}&pageSize=${findDataRequest.pageSize}`;

      if (!findDataRequest.isDefault) {
        api = api + `&sortColumn=${findDataRequest.sortColumn}&ascendingOrder=${findDataRequest.ascendingOrder}`;
      }
    }

    this.httpUtils.invokeGet<TimedTableResult, any>(api, processingEvent, resultEvent, errorsEvent, this.handleError);
  }

  public saveTableData(tableName: string, timedTableUpdate: TimedTableUpdate, processingEvent: EventEmitter<boolean>, resultEvent: EventEmitter<TimedTableResult>, errorsEvent: EventEmitter<any[]>): void {
    const api: string = 'api/timedTable/' + tableName + '/update';

    this.httpUtils.invokePut<TimedTableUpdate, any, any>(api, timedTableUpdate, processingEvent, resultEvent, errorsEvent, this.handleError);
  }
}
