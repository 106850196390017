import {LOCALE_ID, Component, OnInit, EventEmitter, OnDestroy, ElementRef} from '@angular/core';
import {AuthenticationService} from 'src/app/core/authentication/services/authentication.service';
import {NavigationParams} from 'src/app/core/common/types/NavigationParams.type';
import {NavigationComponentType} from 'src/app/tree.service';
import {FormBuilder, Validators, FormGroup, AbstractControl, FormArray} from '@angular/forms';
import {NavigationService} from 'src/app/core/navigation/services/navigation.service';
import {ReferenceService} from 'src/app/modules/reference.service';
import {RiskService} from '../../risk.service';
import {MatOption, MatSelectChange, MatTableDataSource} from '@angular/material';
import {RiskTypes} from '../../shared/RiskTypes.model';
import {DataServiceError} from 'src/app/core/common/http/HttpModel';
import {Element} from '../../shared/element';
import {RiskList} from '../../shared/riskList.model';
import {formatDate} from '@angular/common';
import {DialogService} from '../../../../core/common/services/dialog.service';
import {FieldNameMapper} from '../../shared/FieldNameMapper';


@Component({
  selector: 'awwRiskListCriteria',
  templateUrl: './criteria-riskList.component.html',
  styleUrls: ['./criteria-riskList.component.css']
})
export class CriteriaRiskListComponent implements OnInit, OnDestroy {

  constructor(
    private auth: AuthenticationService,
    private nav: NavigationService,
    private params: NavigationParams,
    private table: ReferenceService,
    private risk: RiskService,
    private dialog: DialogService,
    private fb: FormBuilder) {

    if (false === this.auth.isUserInRole(this.type.accesses)) {
      throw new Error('Unauthorized navigation request.');
    }
  }

  private type: NavigationComponentType = CriteriaRiskListComponent.defineNavigation();

  processingEvent: EventEmitter<boolean> = new EventEmitter();

  errorsEvent: EventEmitter<DataServiceError[]> = new EventEmitter();

  resultEvent: EventEmitter<RiskList> = new EventEmitter();

  isProcessed = false;

  isProcessing = false;

  errors: string[] = [];

  fields: FieldNameMapper[];

  types: RiskTypes[];

  ELEMENT_DATA: Element[] = [];

  listItems: string[] = [];

  fieldHuman: string;

  risklisttosend: RiskList = null;

  /* add risk list table */
  value1 = '';
  displayedColumns = ['name', 'actions*'];
  dataSource = new MatTableDataSource(this.ELEMENT_DATA);


  private isDestroyed = false;
  // Tabs
  tab = 1;

  tabs: string[] = ['General', 'Items', 'Criteria'];

  // ==Tabs
  riskListForm: FormGroup = this.fb.group({
    listCode: ['', Validators.required],
    listName: ['', Validators.required],
    listType: ['', Validators.required],
    listField: ['', Validators.required],
    BeginDate: ['', Validators.required],
    EndDate: [''],
    listFlag: ['']
  });

  public static defineNavigation(): NavigationComponentType {
    return {
      component: CriteriaRiskListComponent,
      linkName: 'Criteria',
      tabTitle: 'Risk List Criteria',
      windowTitle: 'Risk List > Criteria',
      windowClose: true,
      singleton: false,
      icon: 'add',
      accesses: ['risk.list.create']
    };
  }

  clickTab(tabRank: number) {
    this.tab = tabRank;
  }

  isActiveTab(tabRank: number): boolean {
    return this.tab === tabRank;
  }

  transformDate(date) {
    return formatDate(date, 'yyyy-MM-dd', 'en-JM');
  }

  ngOnInit() {

    this.processingEvent.subscribe(isProcessing => this.isProcessing = isProcessing);

    this.errorsEvent.subscribe(errors => {
      this.errors = [];

      errors.forEach(error => {
        const fcontrol: AbstractControl = this.riskListForm.get(error.path);

        if (fcontrol) {
          fcontrol.setErrors({serverError: error.message});
        } else {
          this.errors.push(error.message);
        }

        if (this.errors.length === 0) {
          this.errors.push('The document has errors, please check the highlighted document fields.');
        }
      });
    });

    this.resultEvent.subscribe(result => {
      this.onSubmitResult(result);

      this.errors = [];
    });

    /* get risk Types */
    this.refreshRiskTyps();
  }

  onListTypeChange() {
    this.refreshFieldHumanNames(this.riskListForm.controls['listType'].value);

  }


  onHumanNameChange(event: MatSelectChange) {
    const selectedData = {
      text: (event.source.selected as MatOption).viewValue,
      value: event.source.value
    };
    this.fieldHuman = selectedData.text;

  }

  refreshRiskTyps(): void {

    this.types = [];

    this.risk.getRiskTypes().subscribe((result: RiskTypes[]) => {

      this.types = result;

    }, () => setTimeout(() => this.refreshRiskTyps(), 5000));
  }


  refreshFieldHumanNames(entity: string): void {
    // if (this.isDestroyed) return;

    this.fields = [];

    this.risk.getFields(entity).subscribe((result: FieldNameMapper[]) => {

      this.fields = result;

    }, () => setTimeout(() => this.refreshFieldHumanNames(entity), 5000));

  }

  ctl(path: string) {
    return this.riskListForm.get(path);
  }

  err(path: string) {
    try {
      const errors: any = this.ctl(path).errors;

      if (errors) {
        if (errors.serverError) {
          return errors.serverError;
        }

        if (errors.required === true) {
          return 'Required';
        }
      }
    } catch (e) {
    }

    return '';
  }

  iserr(path: string) {
    return this.ctl(path).invalid && (this.ctl(path).touched || this.ctl(path).dirty || this.ctl(path).errors.serverError);
  }

  private onSubmitResult(result: any): void {
    if (result.status.code === 200) {
      this.isProcessed = true;
    } else {
      this.isProcessed = false;
    }
  }

  // Store the list
  submitForm() {
    this.markFormGroupTouched(this.riskListForm);

    if (!this.riskListForm.valid) {
      if (this.errors.length === 0) {
        this.errors.push('The document has errors, please check the highlighted document fields.');
      }

      return;
    }

    this.dialog.ask(
      'Do you want to verify and validate this form?',
      'Confirmation')
      .subscribe((decision: boolean) => {
        if (decision) {
          // this.risklisttosend.tmpBeginDate === '';

          this.risklisttosend = {
            fields: this.listItems,
            flag: true,
            listFlag:true,
            listCode: this.riskListForm.controls['listCode'].value,
            listName: this.riskListForm.controls['listName'].value,
            listType: this.riskListForm.controls['listType'].value,
            listField: this.riskListForm.controls['listField'].value,
            listHumanName: this.fieldHuman,
            tmpBeginDate: this.transformDate(this.riskListForm.controls['BeginDate'].value)
          };

          if (this.riskListForm.controls['EndDate'].value !== '') {
            this.risklisttosend.tmpEndDate = this.transformDate(this.riskListForm.controls['EndDate'].value);
          }
          this.risk.saveRiskList(this.risklisttosend, this.processingEvent, this.resultEvent, this.errorsEvent);

        }
      });


    // this.riskListForm.disable();
  }


  ngOnDestroy() {
    this.isDestroyed = true;
  }

  private markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      if (control.controls) {
        this.markFormGroupTouched(control);
      } else {
        control.markAsTouched();
      }
    });
  }


  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  addElement() {

    this.ELEMENT_DATA.push({name: this.value1});
    this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
    this.listItems.push(<string>this.value1);
    this.value1 = '';

  }

  removeAt(index: number) {
    const data = this.dataSource.data;
    data.splice(index, 1);
    for (const en of this.ELEMENT_DATA) {

    }
    this.dataSource.data = data;
    this.listItems.splice(index, 1);

  }
}



