import { NgModule } from '@angular/core';
import { CoreCommonModule } from 'src/app/core/common/core-common.module';
import { TimedTableComponent } from './timed-table/timed-table.component';

@NgModule({
  imports: [
    CoreCommonModule
  ],
  declarations: [
    TimedTableComponent
  ],
  entryComponents: [
    TimedTableComponent
  ]
})
export class TableModule { }
