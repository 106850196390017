import {AfterViewInit, Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {BreakpointObserver} from '@angular/cdk/layout';
import {NavigationService} from 'src/app/core/navigation/services/navigation.service';
import {AuthenticationService} from 'src/app/core/authentication/services/authentication.service';
import {TREE_STARTUP} from 'src/app/tree.structure';
import {environment} from 'src/environments/environment';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'awwDesktopMain',
  templateUrl: './desktop-main.component.html',
  styleUrls: ['./desktop-main.component.css']
})
export class DesktopMainComponent implements OnInit, AfterViewInit {

  isSideVisible = true;
  isShowTabs = false;
  isMaximized = false;

  constructor(
    private router: Router,
    private auth: AuthenticationService,
    private nav: NavigationService,
    private breakpointObserver: BreakpointObserver,
    private title: Title
  ) {
  }

  ngOnInit() {
    this.nav.toggleEvent.subscribe(isSideVisible => {
      this.isSideVisible = isSideVisible;
    });

    this.nav.toggleTabSizeEvent.subscribe(isMaximized => {
      this.isMaximized = isMaximized;
    });

    this.nav.toggleTabScreenEvent.subscribe(show => {
      this.isShowTabs = show;
    });

    this.auth.logOutEvent.subscribe(() => {
      this.title.setTitle(environment.applicationName);

      // This is important to reload the application and to make sure everything from the previous login has been cleared e.g. Navigation Tree
      window.location.reload(true);

      this.router.navigate(['logout']);
    });

    this.nav.closeTabEvent.subscribe(() => {
      if (this.nav.isMaximized === true) {
        this.nav.toggleTabSize();
      }
    });

    this.breakpointObserver.observe([
      '(max-width: 1365px)'
    ]).subscribe(result => {
      this.nav.enabledHideOnNewTab(result.matches);
    });
  }

  ngAfterViewInit() {
    TREE_STARTUP.forEach(navigationKey => {
      this.nav.navigate({id: navigationKey});
    });
  }
}
