import { NgModule } from '@angular/core';
import { ProfileModule } from './profile/profile.module';
import { ReportingModule } from './reporting/reporting.module';
import { AccessesModule } from './accesses/accesses.module';
import { UsersModule } from './users/users.module';
import { RolesModule } from './roles/roles.module';
import { GroupsModule } from './groups/groups.module';
import { ConfigModule } from './config/config.module';

@NgModule({
  imports: [
    ProfileModule,
    ReportingModule,
    GroupsModule,
    UsersModule,
    RolesModule,
    AccessesModule,
    ConfigModule
  ],
  exports: [
    ProfileModule,
    ReportingModule,
    GroupsModule,
    UsersModule,
    RolesModule,
    AccessesModule,
    ConfigModule
  ]
})
export class AdminModule { }
